import { Department, Receipt } from '../types';
import { formatCurrency, createElement } from '../utils/helpers';

export default class ReceiptForm {
    private container: HTMLElement;
    private receipt: Receipt | null;
    private department: Department;
    private onSave: (receipt: Receipt) => void;
    private onCancel: () => void;

    constructor(
        container: HTMLElement,
        receipt: Receipt | null,
        department: Department,
        onSave: (receipt: Receipt) => void,
        onCancel: () => void
    ) {
        this.container = container;
        this.receipt = receipt;
        this.department = department;
        this.onSave = onSave;
        this.onCancel = onCancel;
        this.render();
    }

    private render() {
        this.container.innerHTML = '';
        
        const isNew = !this.receipt?.id;
        
        // Modern card container
        const formCard = createElement('div', 'card shadow-sm');
        formCard.style.borderRadius = '12px';
        formCard.style.border = 'none';
        formCard.style.overflow = 'hidden';
        
        // Gradient card header
        const cardHeader = createElement('div', 'card-header');
        cardHeader.style.background = 'linear-gradient(135deg, #11998e 0%, #38ef7d 100%)';
        cardHeader.style.color = 'white';
        cardHeader.style.padding = '16px 20px';
        
        // Header title with icon
        const headerTitle = createElement('div', 'd-flex align-items-center');
        
        // Icon in circle
        const iconCircle = createElement('div', 'rounded-circle me-3 d-flex align-items-center justify-content-center');
        iconCircle.style.width = '40px';
        iconCircle.style.height = '40px';
        iconCircle.style.background = 'rgba(255, 255, 255, 0.2)';
        
        // Different icon based on new/edit
        const iconName = isNew ? 'receipt' : 'pencil-square';
        iconCircle.innerHTML = `<i class="bi bi-${iconName}" style="font-size: 1.25rem;"></i>`;
        
        const title = createElement('h4', 'm-0');
        title.textContent = isNew ? 'Legg til ny kvittering' : 'Rediger kvittering';
        
        headerTitle.appendChild(iconCircle);
        headerTitle.appendChild(title);
        cardHeader.appendChild(headerTitle);
        
        // Card body
        const cardBody = createElement('div', 'card-body');
        cardBody.style.padding = '30px';
        
        // Form element
        const formElement = createElement('form', 'needs-validation');
        (formElement as HTMLFormElement).noValidate = true;
        
        // Get icon for department
        let deptIcon = 'building';
        const iconMap: {[key: string]: string} = {
            'Salg': 'cash',
            'Marked': 'megaphone',
            'HR': 'people',
            'Utvikling': 'code-slash',
            'IT': 'laptop',
            'Finans': 'cash-coin',
            'Admin': 'gear'
        };
        
        for (const [keyword, icon] of Object.entries(iconMap)) {
            if (this.department.name.toLowerCase().includes(keyword.toLowerCase())) {
                deptIcon = icon;
                break;
            }
        }
        
        // Department info banner with modern styling
        const deptInfoBanner = createElement('div', 'card mb-4');
        deptInfoBanner.style.borderRadius = '10px';
        deptInfoBanner.style.background = 'linear-gradient(135deg, rgba(106, 17, 203, 0.05), rgba(37, 117, 252, 0.05))';
        deptInfoBanner.style.border = '1px solid rgba(37, 117, 252, 0.1)';
        
        const deptInfoBody = createElement('div', 'card-body p-3');
        
        deptInfoBody.innerHTML = `
            <div class="d-flex align-items-center mb-2">
                <div class="rounded-circle me-2 d-flex align-items-center justify-content-center"
                    style="width: 32px; height: 32px; background: linear-gradient(135deg, #6a11cb 0%, #2575fc 100%); color: white;">
                    <i class="bi bi-${deptIcon}"></i>
                </div>
                <h5 class="mb-0">${this.department.name}</h5>
            </div>
            <div class="row mt-2">
                <div class="col-6">
                    <div class="text-muted small">Totalbudsjett</div>
                    <div style="font-weight: 600; color: #6a11cb;">${formatCurrency(this.department.budget)}</div>
                </div>
                <div class="col-6">
                    <div class="text-muted small">Budsjett per bruker</div>
                    <div style="font-weight: 600; color: #2575fc;">${formatCurrency(this.department.budgetPerUser)}</div>
                </div>
            </div>
        `;
        
        deptInfoBanner.appendChild(deptInfoBody);
        
        // Fields in a card with light background
        const fieldsCard = createElement('div', 'card mb-4');
        fieldsCard.style.borderRadius = '10px';
        fieldsCard.style.background = 'rgba(0, 0, 0, 0.02)';
        fieldsCard.style.border = '1px solid rgba(0, 0, 0, 0.05)';
        
        const fieldsCardBody = createElement('div', 'card-body p-4');
        
        // Create 2-column layout for date and invoice ID
        const topRow = createElement('div', 'row g-3 mb-3');
        
        // Date column
        const dateCol = createElement('div', 'col-md-6');
        const dateGroup = createElement('div', 'form-group');
        
        // Date label with icon
        const dateLabel = createElement('label', 'form-label mb-2 d-flex align-items-center');
        dateLabel.setAttribute('for', 'receipt-date');
        dateLabel.innerHTML = '<i class="bi bi-calendar3 me-2 text-success"></i>Dato';
        
        // Date input with better styling
        const dateWrapper = createElement('div', 'input-group');
        dateWrapper.style.borderRadius = '10px';
        dateWrapper.style.overflow = 'hidden';
        
        const datePrefix = createElement('span', 'input-group-text');
        datePrefix.style.background = 'rgba(0, 0, 0, 0.03)';
        datePrefix.style.borderRight = 'none';
        datePrefix.innerHTML = '<i class="bi bi-calendar-date"></i>';
        
        const dateInput = createElement('input', 'form-control');
        dateInput.setAttribute('type', 'date');
        dateInput.setAttribute('id', 'receipt-date');
        dateInput.setAttribute('required', 'true');
        dateInput.style.borderLeft = 'none';
        
        // Use receipt date if available (either from edit or from default)
        if (this.receipt && this.receipt.date) {
            // Convert ISO string to date input format (YYYY-MM-DD)
            const date = new Date(this.receipt.date);
            (dateInput as HTMLInputElement).value = date.toISOString().split('T')[0];
        } else {
            // Default to today
            const today = new Date();
            (dateInput as HTMLInputElement).value = today.toISOString().split('T')[0];
        }
        
        dateWrapper.appendChild(datePrefix);
        dateWrapper.appendChild(dateInput);
        
        dateGroup.appendChild(dateLabel);
        dateGroup.appendChild(dateWrapper);
        dateCol.appendChild(dateGroup);
        
        // Invoice ID column - now system-generated and read-only
        const invoiceIdCol = createElement('div', 'col-md-6');
        const invoiceIdGroup = createElement('div', 'form-group');
        
        // Invoice label with icon
        const invoiceIdLabel = createElement('label', 'form-label mb-2 d-flex align-items-center');
        invoiceIdLabel.innerHTML = '<i class="bi bi-file-text me-2 text-primary"></i>Bilagsnummer <small class="text-muted ms-2">(Settes automatisk)</small>';
        
        // Invoice ID display element
        const invoiceIdWrapper = createElement('div', 'input-group');
        invoiceIdWrapper.style.borderRadius = '10px';
        invoiceIdWrapper.style.overflow = 'hidden';
        
        const invoiceIdPrefix = createElement('span', 'input-group-text');
        invoiceIdPrefix.style.background = 'rgba(0, 0, 0, 0.03)';
        invoiceIdPrefix.style.borderRight = 'none';
        invoiceIdPrefix.innerHTML = '<i class="bi bi-hash"></i>';
        
        // Use a disabled input field to show the value
        const invoiceIdInput = createElement('input', 'form-control bg-light');
        invoiceIdInput.setAttribute('type', 'text');
        invoiceIdInput.setAttribute('id', 'receipt-invoiceId');
        invoiceIdInput.setAttribute('disabled', 'true');
        invoiceIdInput.style.borderLeft = 'none';
        invoiceIdInput.style.color = '#6c757d';
        
        // Show existing ID for edits, or 'Ny' for new receipts
        if (this.receipt && this.receipt.id && this.receipt.invoiceId > 0) {
            (invoiceIdInput as HTMLInputElement).value = this.receipt.invoiceId.toString();
        } else {
            (invoiceIdInput as HTMLInputElement).value = 'Genereres automatisk';
        }
        
        invoiceIdWrapper.appendChild(invoiceIdPrefix);
        invoiceIdWrapper.appendChild(invoiceIdInput);
        
        invoiceIdGroup.appendChild(invoiceIdLabel);
        invoiceIdGroup.appendChild(invoiceIdWrapper);
        invoiceIdCol.appendChild(invoiceIdGroup);
        
        // Add columns to row
        topRow.appendChild(dateCol);
        topRow.appendChild(invoiceIdCol);
        
        // Amount field with larger font
        const amountGroup = createElement('div', 'form-group mb-3');
        
        // Amount label with icon
        const amountLabel = createElement('label', 'form-label mb-2 d-flex align-items-center');
        amountLabel.setAttribute('for', 'receipt-amount');
        amountLabel.innerHTML = '<i class="bi bi-currency-exchange me-2 text-warning"></i>Beløp (NOK)';
        
        // Amount input with better styling
        const amountWrapper = createElement('div', 'input-group');
        amountWrapper.style.borderRadius = '10px';
        amountWrapper.style.overflow = 'hidden';
        
        const amountPrefix = createElement('span', 'input-group-text');
        amountPrefix.style.background = 'rgba(0, 0, 0, 0.03)';
        amountPrefix.style.borderRight = 'none';
        amountPrefix.style.fontWeight = 'bold';
        amountPrefix.innerHTML = 'NOK';
        
        const amountInput = createElement('input', 'form-control form-control-lg');
        amountInput.setAttribute('type', 'number');
        amountInput.setAttribute('id', 'receipt-amount');
        amountInput.setAttribute('min', '0');
        amountInput.setAttribute('step', '1'); // Whole numbers for NOK
        amountInput.setAttribute('required', 'true');
        amountInput.style.borderLeft = 'none';
        amountInput.style.fontSize = '1.25rem';
        amountInput.style.fontWeight = '600';
        
        if (this.receipt) {
            (amountInput as HTMLInputElement).value = Math.round(this.receipt.amount).toString();
        }
        
        amountWrapper.appendChild(amountPrefix);
        amountWrapper.appendChild(amountInput);
        
        amountGroup.appendChild(amountLabel);
        amountGroup.appendChild(amountWrapper);
        
        // Description field
        const descriptionGroup = createElement('div', 'form-group');
        
        // Description label with icon
        const descriptionLabel = createElement('label', 'form-label mb-2 d-flex align-items-center');
        descriptionLabel.setAttribute('for', 'receipt-description');
        descriptionLabel.innerHTML = '<i class="bi bi-card-text me-2 text-info"></i>Beskrivelse (valgfritt)';
        
        // Description input area
        const descriptionInput = createElement('textarea', 'form-control');
        descriptionInput.setAttribute('id', 'receipt-description');
        descriptionInput.setAttribute('rows', '3');
        descriptionInput.setAttribute('placeholder', 'Tilleggsinformasjon om kvitteringen...');
        descriptionInput.style.borderRadius = '10px';
        
        if (this.receipt && this.receipt.description) {
            (descriptionInput as HTMLTextAreaElement).value = this.receipt.description;
        }
        
        descriptionGroup.appendChild(descriptionLabel);
        descriptionGroup.appendChild(descriptionInput);
        
        // Add all form fields to the card body
        fieldsCardBody.appendChild(topRow);
        fieldsCardBody.appendChild(amountGroup);
        fieldsCardBody.appendChild(descriptionGroup);
        fieldsCard.appendChild(fieldsCardBody);
        
        // Button group with improved styling
        const buttonGroup = createElement('div', 'd-flex gap-3');
        
        // Save button with gradient
        const saveButton = createElement('button', 'btn btn-lg px-4');
        saveButton.setAttribute('type', 'submit');
        saveButton.style.background = 'linear-gradient(135deg, #11998e 0%, #38ef7d 100%)';
        saveButton.style.color = 'white';
        saveButton.style.borderRadius = '30px';
        saveButton.style.fontWeight = '500';
        saveButton.style.border = 'none';
        saveButton.style.boxShadow = '0 4px 10px rgba(17, 153, 142, 0.3)';
        saveButton.innerHTML = '<i class="bi bi-check-circle-fill me-2"></i>Lagre';
        
        // Cancel button with subtle styling
        const cancelButton = createElement('button', 'btn btn-lg btn-light px-4');
        cancelButton.setAttribute('type', 'button');
        cancelButton.style.borderRadius = '30px';
        cancelButton.style.fontWeight = '500';
        cancelButton.style.boxShadow = '0 2px 5px rgba(0, 0, 0, 0.08)';
        cancelButton.innerHTML = '<i class="bi bi-x-circle me-2"></i>Avbryt';
        
        cancelButton.addEventListener('click', () => {
            this.onCancel();
        });
        
        buttonGroup.appendChild(saveButton);
        buttonGroup.appendChild(cancelButton);
        
        // Form submission with validation
        formElement.addEventListener('submit', (e) => {
            e.preventDefault();
            
            // Enhanced validation with feedback
            if (!(dateInput as HTMLInputElement).value) {
                (dateInput as HTMLElement).classList.add('is-invalid');
                const feedback = createElement('div', 'invalid-feedback');
                feedback.textContent = 'Dato er påkrevd';
                dateGroup.appendChild(feedback);
                return;
            }
            
            // Bilagsnummer validation no longer needed as it's system-generated
            
            const amount = parseFloat((amountInput as HTMLInputElement).value);
            if (isNaN(amount) || amount < 0) {
                (amountInput as HTMLElement).classList.add('is-invalid');
                const feedback = createElement('div', 'invalid-feedback');
                feedback.textContent = 'Beløp må være et gyldig ikke-negativt tall';
                amountGroup.appendChild(feedback);
                return;
            }
            
            // Create date object from input
            const receiptDate = new Date((dateInput as HTMLInputElement).value);
            
            const updatedReceipt: Receipt = {
                id: this.receipt?.id || crypto.randomUUID(),
                departmentId: this.department.id,
                date: receiptDate.toISOString(),
                month: receiptDate.getMonth() + 1, // 1-12
                year: receiptDate.getFullYear(),
                amount: Math.round(amount), // Round to whole NOK
                invoiceId: this.receipt?.invoiceId || 0, // Keep existing ID or use 0 for new (will be set by backend)
                description: (descriptionInput as HTMLTextAreaElement).value.trim() || undefined
            };
            
            this.onSave(updatedReceipt);
        });
        
        // Assemble form
        formElement.appendChild(deptInfoBanner);
        formElement.appendChild(fieldsCard);
        formElement.appendChild(buttonGroup);
        
        // Add form to card body
        cardBody.appendChild(formElement);
        
        // Assemble card
        formCard.appendChild(cardHeader);
        formCard.appendChild(cardBody);
        
        // Add to container
        this.container.appendChild(formCard);
        
        // Focus on the first field when form is displayed
        setTimeout(() => {
            (dateInput as HTMLElement).focus();
        }, 0);
    }
}