// Configuration helper for the application

// API configuration
export const apiConfig = {
  baseUrl: process.env.API_BASE_URL || 'http://localhost:3000/api',
};

// Application settings
export const appSettings = {
  // Admin password - can be changed here to update throughout the application
  // In a production environment, this would be set via environment variable
  adminPassword: process.env.ADMIN_PASSWORD || 'admin1234',
};

// Utility function to log the current environment
export const logEnvironment = (): void => {
  console.log('Environment Configuration:');
  console.log('- API Base URL:', apiConfig.baseUrl);
  
  // In a real application, we would not log the password
  // This is just for development purposes
  if (process.env.NODE_ENV === 'development') {
    console.log('- Admin Password is configured');
  }
};