import { Department, Receipt } from '../types';
import { createElement } from '../utils/helpers';
import ReceiptList from './ReceiptList';
import ReceiptForm from './ReceiptForm';
import DepartmentForm from './DepartmentForm';
import databaseService from '../services/database';
import { showNotification } from '../utils/helpers';

export default class BudgetView {
    private container: HTMLElement;
    private departments: Department[];
    private selectedDepartmentId: string | null = null;
    private receipts: Receipt[] = [];
    private selectedReceipt: Receipt | null = null;
    private selectedMonth: number;
    private selectedYear: number;
    
    private departmentSelectorContainer: HTMLElement;
    private dateFilterContainer: HTMLElement;
    private mainContentContainer: HTMLElement;
    private receiptListContainer: HTMLElement;
    private receiptFormContainer: HTMLElement;
    private departmentFormContainer: HTMLElement;
    
    private receiptList: ReceiptList | null = null;
    private selectedDepartment: Department | null = null;
    
    // Month names for display
    private monthNames = [
        'Januar', 'Februar', 'Mars', 'April', 'Mai', 'Juni',
        'Juli', 'August', 'September', 'Oktober', 'November', 'Desember'
    ];

    constructor(container: HTMLElement, departments: Department[], selectedMonth?: number, selectedYear?: number) {
        this.container = container;
        this.departments = departments;
        
        // Always use current month and year for dashboard, regardless of what's in localStorage
        const now = new Date();
        this.selectedMonth = now.getMonth() + 1; // 1-12
        this.selectedYear = now.getFullYear();

        // Create structure
        this.departmentSelectorContainer = createElement('div', 'mb-4');
        this.dateFilterContainer = createElement('div', 'mb-4'); // Keep this container for future use
        this.mainContentContainer = createElement('div');
        this.receiptListContainer = createElement('div');
        this.receiptFormContainer = createElement('div', 'mt-4');
        this.departmentFormContainer = createElement('div', 'mt-4 department-form-container');
        this.departmentFormContainer.style.display = 'none'; // Hide by default
        
        this.mainContentContainer.appendChild(this.receiptListContainer);
        this.mainContentContainer.appendChild(this.receiptFormContainer);
        this.mainContentContainer.appendChild(this.departmentFormContainer);
        
        this.container.appendChild(this.departmentSelectorContainer);
        // Skip adding the dateFilterContainer since we're removing that section
        this.container.appendChild(this.mainContentContainer);
        
        // Initialize asynchronously
        this.initialize(departments);
    }
    
    private async initialize(departments: Department[]) {
        await this.render();
        
        // Check if we have a saved department ID in localStorage
        const savedDepartmentId = localStorage.getItem('selectedDepartmentId');
        
        if (departments.length > 0) {
            // Try to use saved department if it exists in the current departments list
            if (savedDepartmentId && departments.some(dept => dept.id === savedDepartmentId)) {
                this.selectedDepartmentId = savedDepartmentId;
            } else {
                // Otherwise use the first department
                this.selectedDepartmentId = departments[0].id;
            }
            
            // Force update the selector to match
            const selector = document.getElementById('department-selector') as HTMLSelectElement;
            if (selector) {
                selector.value = this.selectedDepartmentId!;
                await this.loadDepartmentReceipts(this.selectedDepartmentId!);
            }
        }
    }
    
    async update(departments: Department[]) {
        this.departments = departments;
        
        // Always ensure we're using the current month/year when updating
        const now = new Date();
        this.selectedMonth = now.getMonth() + 1; // 1-12
        this.selectedYear = now.getFullYear();
        
        await this.render();
        
        // If the previously selected department is still available, reload its data
        if (this.selectedDepartmentId) {
            const departmentStillExists = this.departments.some(d => d.id === this.selectedDepartmentId);
            if (departmentStillExists) {
                // Force select the previously selected department
                const selector = document.getElementById('department-selector') as HTMLSelectElement;
                if (selector) {
                    selector.value = this.selectedDepartmentId;
                }
                
                await this.loadDepartmentReceipts(this.selectedDepartmentId);
            } else {
                this.selectedDepartmentId = null;
                this.receipts = [];
                this.receiptListContainer.innerHTML = '';
                this.receiptFormContainer.innerHTML = '';
                this.renderEmptyState();
            }
        } else if (departments.length > 0) {
            // If no department selected but departments exist, select the first one
            this.selectedDepartmentId = departments[0].id;
            
            // Force select the first department in the dropdown
            const selector = document.getElementById('department-selector') as HTMLSelectElement;
            if (selector) {
                selector.value = this.selectedDepartmentId;
                // Manually load the data for this department
                await this.loadDepartmentReceipts(this.selectedDepartmentId);
            }
        }
    }

    private async render() {
        // Render department selector
        this.renderDepartmentSelector();
        
        // Render month and year selectors
        await this.renderDateFilters();
        
        // If no department is selected, show empty state
        if (!this.selectedDepartmentId) {
            this.renderEmptyState();
        }
    }
    
    private async renderDateFilters() {
        this.dateFilterContainer.innerHTML = '';
        
        // We no longer need to display the month/year card since it's redundant
        // The month and year are already shown in the budget status display
        
        // Fetch all receipts for this department (regardless of month/year) to check which months have data
        let allDepartmentReceipts: Receipt[] = [];
        if (this.selectedDepartmentId) {
            try {
                allDepartmentReceipts = await databaseService.getReceiptsByDepartment(this.selectedDepartmentId);
            } catch (error) {
                console.error('Error fetching all receipts for department:', error);
            }
        }
        
        // Get months with data for the current year
        const monthsWithDataMap = new Map<number, Map<number, number>>(); // year -> month -> count
        allDepartmentReceipts.forEach(receipt => {
            const receiptYear = receipt.year;
            const receiptMonth = receipt.month;
            
            if (!monthsWithDataMap.has(receiptYear)) {
                monthsWithDataMap.set(receiptYear, new Map<number, number>());
            }
            
            const yearMap = monthsWithDataMap.get(receiptYear)!;
            yearMap.set(receiptMonth, (yearMap.get(receiptMonth) || 0) + 1);
        });
        
        // The month/year card was removed to streamline the interface
    }

    private renderDepartmentSelector() {
        this.departmentSelectorContainer.innerHTML = '';
        
        // Create a minimal selector container without card styling
        const selectorContainer = createElement('div', 'mb-3 minimalistic-dept-selector');
        
        // Title row with dropdown in a single line
        const headerRow = createElement('div', 'd-flex justify-content-between align-items-center mb-2');
        
        // Simple title instead of fancy header
        const titleElement = createElement('label', 'form-label mb-0 fw-bold');
        titleElement.textContent = 'Avdeling:';
        titleElement.htmlFor = 'department-selector';
        
        headerRow.appendChild(titleElement);
        
        // Sort departments alphabetically for easier selection
        const sortedDepartments = [...this.departments].sort((a, b) => 
            a.name.localeCompare(b.name, 'nb', { sensitivity: 'base' }));
        
        // Create a simple select dropdown that takes minimal space
        const selectGroup = createElement('div', 'd-flex');
        
        // Standard dropdown without bootstrap styling to save space
        const selectElement = createElement('select', 'form-select form-select-sm me-2') as HTMLSelectElement;
        selectElement.id = 'department-selector';
        selectElement.style.minWidth = '150px';
        
        // Handle empty departments state
        if (this.departments.length === 0) {
            // Empty default option
            const defaultOption = document.createElement('option');
            defaultOption.text = 'Ingen avdelinger';
            defaultOption.value = '';
            defaultOption.disabled = true;
            defaultOption.selected = true;
            selectElement.add(defaultOption);
            
            // Add a placeholder message
            const message = createElement('small', 'text-muted ms-2 align-self-center');
            message.textContent = 'Opprettes i budsjett-fanen';
            selectGroup.appendChild(message);
        } else {
            // Add options for each department
            sortedDepartments.forEach(dept => {
                const option = document.createElement('option');
                option.text = dept.name;
                option.value = dept.id;
                option.selected = dept.id === this.selectedDepartmentId;
                selectElement.add(option);
            });
            
            // Add a refresh button
            const refreshButton = createElement('button', 'btn btn-sm btn-outline-secondary');
            refreshButton.innerHTML = '<i class="bi bi-arrow-clockwise"></i>';
            refreshButton.title = 'Oppdater avdelingsdata';
            refreshButton.addEventListener('click', () => {
                if (this.selectedDepartmentId) {
                    this.loadDepartmentReceipts(this.selectedDepartmentId);
                }
            });
            
            // Handle selection change
            selectElement.addEventListener('change', () => {
                const selectedId = selectElement.value;
                if (selectedId) {
                    this.selectedDepartmentId = selectedId;
                    
                    // Save selected department to localStorage
                    localStorage.setItem('selectedDepartmentId', selectedId);
                    
                    // Clear any previous content
                    this.receiptListContainer.innerHTML = '';
                    
                    // Load the department's receipts
                    this.loadDepartmentReceipts(selectedId);
                }
            });
            
            selectGroup.appendChild(refreshButton);
        }
        
        // Add select to the select group
        selectGroup.insertBefore(selectElement, selectGroup.firstChild);
        
        // Add the dropdown row to header
        headerRow.appendChild(selectGroup);
        
        // Simple message about department management
        let helpText = '';
        if (this.departments.length === 0) {
            helpText = '<small class="text-muted">Ingen avdelinger. Opprett i budsjett-fanen.</small>';
        } else if (this.selectedDepartmentId) {
            const dept = this.departments.find(d => d.id === this.selectedDepartmentId);
            if (dept) {
                helpText = `<small class="text-muted">Budsjett: ${new Intl.NumberFormat('nb-NO', {style: 'currency', currency: 'NOK'}).format(dept.budget)}</small>`;
            }
        }
        
        // Create the help container only if there's text
        if (helpText) {
            const helpContainer = createElement('small', 'ms-2 text-muted');
            helpContainer.innerHTML = helpText;
            selectGroup.appendChild(helpContainer);
        }
        
        // Add container to selector
        selectorContainer.appendChild(headerRow);
        
        // Add a divider
        const divider = createElement('hr', 'mt-2 mb-3');
        divider.style.margin = '0.5rem 0';
        divider.style.opacity = '0.1';
        selectorContainer.appendChild(divider);
        
        // Add to the main container
        this.departmentSelectorContainer.appendChild(selectorContainer);
    }
    
    private renderEmptyState() {
        this.receiptListContainer.innerHTML = '';
        this.receiptFormContainer.innerHTML = '';
        
        if (this.departments.length === 0) {
            // Create a simple notice for no departments
            const notice = createElement('div', 'alert alert-info text-center');
            notice.innerHTML = `
                <i class="bi bi-info-circle me-2"></i>
                Ingen avdelinger tilgjengelig. Opprett avdelinger i budsjett-fanen.
            `;
            this.receiptListContainer.appendChild(notice);
        } else {
            // For the case where departments exist but none selected
            const selectNotice = createElement('div', 'alert alert-info text-center');
            selectNotice.innerHTML = `
                <i class="bi bi-arrow-up-circle me-2"></i>
                Velg en avdeling fra nedtrekkslisten ovenfor.
            `;
            this.receiptListContainer.appendChild(selectNotice);
        }
    }
    
    /**
     * Gets the user count for a specific month and year
     * Uses the month-specific count if available, otherwise falls back to the department's default
     */
    private getDepartmentUserCountForMonth(department: Department, month: number, year: number): number {
        if (department.monthlyUserCounts && department.monthlyUserCounts.length > 0) {
            // Look for a month-specific count
            const monthlyCount = department.monthlyUserCounts.find(
                mc => mc.month === month && mc.year === year
            );
            
            if (monthlyCount) {
                return monthlyCount.userCount;
            }
        }
        
        // Fall back to default user count
        return department.userCount;
    }
    
    /**
     * Updates the monthly user count for a department
     */
    private async updateMonthlyUserCount(
        department: Department, 
        month: number, 
        year: number, 
        userCount: number
    ): Promise<Department> {
        // Create a deep copy of the department
        const updatedDepartment = {...department};
        
        // Initialize monthlyUserCounts array if it doesn't exist
        if (!updatedDepartment.monthlyUserCounts) {
            updatedDepartment.monthlyUserCounts = [];
        } else {
            // Make a copy of the array
            updatedDepartment.monthlyUserCounts = [...updatedDepartment.monthlyUserCounts];
        }
        
        // Check if we already have an entry for this month
        const existingIndex = updatedDepartment.monthlyUserCounts.findIndex(
            mc => mc.month === month && mc.year === year
        );
        
        if (existingIndex >= 0) {
            // Update existing entry
            updatedDepartment.monthlyUserCounts[existingIndex] = {
                ...updatedDepartment.monthlyUserCounts[existingIndex],
                userCount
            };
        } else {
            // Add new entry
            updatedDepartment.monthlyUserCounts.push({
                month,
                year,
                userCount
            });
        }
        
        // Save updated department
        await databaseService.updateDepartment(updatedDepartment);
        
        // Update our local department data
        const departmentIndex = this.departments.findIndex(d => d.id === department.id);
        if (departmentIndex >= 0) {
            this.departments[departmentIndex] = updatedDepartment;
        }
        
        return updatedDepartment;
    }
    
    /**
     * Renders the user count adjustment control for the current month
     * NOTE: This functionality has been moved to the Budget tab
     * This method is kept for backward compatibility but no longer used
     */
    private renderMonthlyUserCount(department: Department) {
        // This functionality is now in the Budget tab (index.ts)
        // Method is kept for backward compatibility
        console.log('User count adjustment is now handled in the Budget tab (summary section)');
    }

    private async loadDepartmentReceipts(departmentId: string): Promise<void> {
        try {
            // Clear form area
            this.receiptFormContainer.innerHTML = '';
            
            // Get department
            const department = this.departments.find(d => d.id === departmentId);
            if (!department) {
                throw new Error(`Department not found: ${departmentId}`);
            }
            
            // Save the selected department to localStorage
            localStorage.setItem('selectedDepartmentId', departmentId);
            
            // Get all receipts for the department
            const allReceipts = await databaseService.getReceiptsByDepartment(departmentId);
            
            // Filter receipts by selected month and year
            this.receipts = allReceipts.filter(receipt => {
                const receiptDate = new Date(receipt.date);
                const receiptMonth = receiptDate.getMonth() + 1; // 1-12
                const receiptYear = receiptDate.getFullYear();
                
                return (
                    (receipt.month === this.selectedMonth || receiptMonth === this.selectedMonth) && 
                    (receipt.year === this.selectedYear || receiptYear === this.selectedYear)
                );
            });
            
            // Show filter details in page title with help toggle
            const monthName = this.monthNames[this.selectedMonth - 1];
            
            // Create help toggle as a cleaner button
            const helpToggle = createElement('button', 'btn btn-sm btn-outline-info mb-3');
            helpToggle.innerHTML = '<i class="bi bi-info-circle"></i> Vis info om budsjett';
            helpToggle.addEventListener('click', function() {
                const helpContent = document.getElementById('budget-help-content');
                if (helpContent) {
                    helpContent.classList.toggle('visible');
                    this.innerHTML = helpContent.classList.contains('visible') ? 
                        '<i class="bi bi-info-circle-fill"></i> Skjul info' : 
                        '<i class="bi bi-info-circle"></i> Vis info om budsjett';
                }
            });
            
            // Create a cleaner help panel with card styling
            const helpContent = createElement('div', 'card mb-3');
            helpContent.id = 'budget-help-content';
            helpContent.style.display = 'none';
            helpContent.classList.add('budget-help-card');
            
            const helpHeader = createElement('div', 'card-header bg-info text-white');
            helpHeader.innerHTML = '<h5 class="mb-0">Slik fungerer budsjettstyringen</h5>';
            
            const helpBody = createElement('div', 'card-body');
            helpBody.innerHTML = `
                <div class="row">
                    <div class="col-md-6">
                        <h6>Beregning</h6>
                        <p>Budsjettet beregnes basert på:</p>
                        <ul>
                            <li>Antall brukere × budsjett per bruker</li>
                            <li><strong>4-dagers periode:</strong> Mandag til torsdag</li>
                            <li><strong>3-dagers periode:</strong> Fredag til søndag</li>
                        </ul>
                    </div>
                    <div class="col-md-6">
                        <h6>Fargekoding</h6>
                        <div class="d-flex align-items-center mb-2">
                            <span class="badge bg-success me-2">■</span> 
                            <span>Innenfor budsjett</span>
                        </div>
                        <div class="d-flex align-items-center mb-2">
                            <span class="badge bg-warning me-2">■</span> 
                            <span>Nærmer seg grense (>80%)</span>
                        </div>
                        <div class="d-flex align-items-center mb-2">
                            <span class="badge bg-danger me-2">■</span> 
                            <span>Over budsjett</span>
                        </div>
                    </div>
                </div>
            `;
            
            helpContent.appendChild(helpHeader);
            helpContent.appendChild(helpBody);
            
            // Update the toggle functionality
            helpToggle.addEventListener('click', function() {
                if (helpContent.style.display === 'none') {
                    helpContent.style.display = 'block';
                    this.innerHTML = '<i class="bi bi-info-circle-fill"></i> Skjul info';
                } else {
                    helpContent.style.display = 'none';
                    this.innerHTML = '<i class="bi bi-info-circle"></i> Vis info om budsjett';
                }
            });
            
            // Create filter info container with card styling for cleaner look
            const filterInfo = createElement('div', 'card mb-4');
            
            // Create card header with period information - using a more subtle styling
            const filterHeader = createElement('div', 'card-header bg-light d-flex justify-content-between align-items-center');
            
            // Left side with period info
            const periodInfo = createElement('div', '');
            periodInfo.innerHTML = `
                <h5 class="mb-0 d-flex align-items-center">
                    <i class="bi bi-calendar3 me-2"></i>
                    ${monthName} ${this.selectedYear}
                </h5>
            `;
            
            // Right side with receipt count badge
            const receiptCount = createElement('div', '');
            const badgeClass = 'bg-secondary';
            receiptCount.innerHTML = `
                <span class="badge ${badgeClass} rounded-pill">
                    ${this.receipts.length} kvittering${this.receipts.length !== 1 ? 'er' : ''}
                </span>
            `;
            
            // Add to header
            filterHeader.appendChild(periodInfo);
            filterHeader.appendChild(receiptCount);
            
            // Create card body with report button and other info
            const filterBody = createElement('div', 'card-body d-flex justify-content-between align-items-center');
            
            // Department info
            const departmentInfo = createElement('div', '');
            const selectedDept = this.departments.find(d => d.id === this.selectedDepartmentId);
            
            if (selectedDept) {
                const effectiveUserCount = this.getDepartmentUserCountForMonth(
                    selectedDept,
                    this.selectedMonth,
                    this.selectedYear
                );
                
                departmentInfo.innerHTML = `
                    <p class="mb-0">
                        <span class="text-muted">Avdeling:</span> <strong>${selectedDept.name}</strong><br>
                        <span class="text-muted">Personer:</span> <strong>${effectiveUserCount}</strong>
                    </p>
                `;
            }
            
            // Monthly report button with icon
            const reportBtnContainer = createElement('div', '');
            const reportBtn = createElement('button', 'btn btn-primary');
            reportBtn.innerHTML = '<i class="bi bi-bar-chart-fill me-2"></i> Månedsrapport';
            reportBtn.title = 'Se månedlig budsjettsammendrag';
            reportBtnContainer.appendChild(reportBtn);
            
            // Add to body
            filterBody.appendChild(departmentInfo);
            filterBody.appendChild(reportBtnContainer);
            
            // Assemble card
            filterInfo.appendChild(filterHeader);
            filterInfo.appendChild(filterBody);
            
            // Add click handler for monthly report button
            setTimeout(() => {
                if (reportBtn) {
                    reportBtn.addEventListener('click', () => {
                        // Find the monthly summary button in the current receipt list
                        const budgetGraphElement = this.receiptListContainer.querySelector('.budget-graph');
                        if (budgetGraphElement) {
                            // Find the monthly summary button within that element
                            const monthlySummaryBtn = budgetGraphElement.querySelector('button');
                            if (monthlySummaryBtn) {
                                monthlySummaryBtn.click();
                            }
                        }
                    });
                }
            }, 0);
            
            this.receiptListContainer.innerHTML = '';
            this.receiptListContainer.appendChild(helpToggle);
            this.receiptListContainer.appendChild(helpContent);
            this.receiptListContainer.appendChild(filterInfo);
            
            // Get current department
            const currentDept = this.departments.find(d => d.id === this.selectedDepartmentId);
            
            if (!currentDept) {
                throw new Error(`Department not found: ${this.selectedDepartmentId}`);
            }
            
            // User count adjustment is now moved to the Budget tab
            // So we don't need to render it here anymore
            
            // Get effective user count for the selected month
            const effectiveUserCount = this.getDepartmentUserCountForMonth(
                currentDept,
                this.selectedMonth,
                this.selectedYear
            );
            
            // Create a modified department object with the month-specific user count
            const effectiveDepartment = {
                ...currentDept,
                userCount: effectiveUserCount,
                budget: effectiveUserCount * currentDept.budgetPerUser  // Recalculate budget with correct user count
            };
            
            // Define secure versions of handlers that don't allow modifications in dashboard view
            const secureSelectHandler = (receiptId: string) => {
                // Prevent editing in dashboard view
                showNotification('Redigering av kvitteringer er bare tilgjengelig i budsjett-visningen', 'info');
                return false; // Indicate operation not allowed
            };
            
            const secureDeleteHandler = (receiptId: string) => {
                // Prevent deletion in dashboard view
                showNotification('Sletting av kvitteringer er bare tilgjengelig i budsjett-visningen', 'info');
                return false; // Indicate operation not allowed
            };
            
            // Render receipt list with the effective department data and secure handlers
            this.receiptList = new ReceiptList(
                this.receiptListContainer,
                effectiveDepartment,
                this.receipts,
                secureSelectHandler,  // Use secure handler that prevents editing
                secureDeleteHandler,  // Use secure handler that prevents deletion
                this.handleAddReceipt.bind(this),  // Keep add functionality
                this.selectedMonth,
                this.selectedYear,
                true  // hideActions = true to hide edit/delete buttons
            );
            
        } catch (error) {
            console.error('Error loading department receipts:', error);
            showNotification('Feil ved lasting av avdelingsbudsjettdata', 'danger');
            
            const errorMessage = createElement('div', 'alert alert-danger');
            errorMessage.textContent = 'Kunne ikke laste avdelingsbudsjettdata. Vennligst prøv igjen.';
            this.receiptListContainer.innerHTML = '';
            this.receiptListContainer.appendChild(errorMessage);
        }
    }
    
    private async handleReceiptSelect(receiptId: string) {
        try {
            const receipt = await databaseService.getReceipt(receiptId);
            if (!receipt || !this.selectedDepartmentId) {
                throw new Error('Receipt or department not found');
            }
            
            this.selectedReceipt = receipt;
            const selectedDepartment = this.departments.find(d => d.id === this.selectedDepartmentId);
            
            if (!selectedDepartment) {
                throw new Error('Department not found');
            }
            
            // Get effective user count for the receipt's month and year
            const effectiveUserCount = this.getDepartmentUserCountForMonth(
                selectedDepartment,
                receipt.month,
                receipt.year
            );
            
            // Create a modified department object with the month-specific user count
            const effectiveDepartment = {
                ...selectedDepartment,
                userCount: effectiveUserCount,
                budget: effectiveUserCount * selectedDepartment.budgetPerUser
            };
            
            // Create receipt form with the effective department data
            const receiptForm = new ReceiptForm(
                this.receiptFormContainer,
                receipt,
                effectiveDepartment,
                this.handleReceiptSave.bind(this),
                this.handleFormCancel.bind(this)
            );
            
            // Scroll to form
            this.receiptFormContainer.scrollIntoView({ behavior: 'smooth' });
            
        } catch (error) {
            console.error('Error selecting receipt:', error);
            showNotification('Feil ved valg av kvittering', 'danger');
        }
    }
    
    private async handleReceiptDelete(receiptId: string) {
        try {
            await databaseService.deleteReceipt(receiptId);
            showNotification('Kvittering slettet', 'success');
            
            // Reload receipts
            if (this.selectedDepartmentId) {
                this.loadDepartmentReceipts(this.selectedDepartmentId);
            }
        } catch (error) {
            console.error('Error deleting receipt:', error);
            showNotification('Feil ved sletting av kvittering', 'danger');
        }
    }
    
    private handleAddReceipt() {
        if (!this.selectedDepartmentId) {
            showNotification('Vennligst velg en avdeling først', 'warning');
            return;
        }
        
        const selectedDepartment = this.departments.find(d => d.id === this.selectedDepartmentId);
        
        if (!selectedDepartment) {
            showNotification('Avdeling ikke funnet', 'danger');
            return;
        }
        
        // Get effective user count for the selected month
        const effectiveUserCount = this.getDepartmentUserCountForMonth(
            selectedDepartment,
            this.selectedMonth,
            this.selectedYear
        );
        
        // Create a modified department object with the month-specific user count
        const effectiveDepartment = {
            ...selectedDepartment,
            userCount: effectiveUserCount,
            budget: effectiveUserCount * selectedDepartment.budgetPerUser
        };
        
        // Create a default receipt for the currently selected month and year
        const defaultDate = new Date();
        defaultDate.setFullYear(this.selectedYear);
        defaultDate.setMonth(this.selectedMonth - 1); // JavaScript months are 0-11
        
        // Set to the middle of the selected month if possible, or current day
        const daysInMonth = new Date(this.selectedYear, this.selectedMonth, 0).getDate();
        const currentDay = defaultDate.getDate();
        let defaultDay = 15; // Middle of month
        
        // If current month and year, use current day
        const today = new Date();
        if (this.selectedMonth === today.getMonth() + 1 && this.selectedYear === today.getFullYear()) {
            defaultDay = currentDay;
        }
        // Make sure day is valid for month
        defaultDay = Math.min(defaultDay, daysInMonth);
        defaultDate.setDate(defaultDay);
        
        const defaultReceipt: Receipt = {
            id: '',
            departmentId: this.selectedDepartmentId,
            date: defaultDate.toISOString(),
            month: this.selectedMonth,
            year: this.selectedYear,
            amount: 0,
            invoiceId: 0 // Auto-generated number, default to 0
        };
        
        // Create new receipt form
        this.selectedReceipt = null;
        const receiptForm = new ReceiptForm(
            this.receiptFormContainer,
            defaultReceipt, // Default receipt for selected month/year
            effectiveDepartment, // Use the effectiveDepartment with correct monthly user count
            this.handleReceiptSave.bind(this),
            this.handleFormCancel.bind(this)
        );
        
        // Scroll to form
        this.receiptFormContainer.scrollIntoView({ behavior: 'smooth' });
    }
    
    private async handleReceiptSave(receipt: Receipt) {
        try {
            const isNew = !this.receipts.find(r => r.id === receipt.id);
            
            if (isNew) {
                // For new receipts, the server will auto-generate a bilagsnummer
                const savedReceipt = await databaseService.addReceipt(receipt);
                showNotification(`Kvittering lagt til med bilagsnummer ${savedReceipt.invoiceId}`, 'success');
            } else {
                await databaseService.updateReceipt(receipt);
                showNotification('Kvittering oppdatert', 'success');
            }
            
            // Clear the form
            this.selectedReceipt = null;
            this.receiptFormContainer.innerHTML = '';
            
            // Reload receipts
            if (this.selectedDepartmentId) {
                this.loadDepartmentReceipts(this.selectedDepartmentId);
            }
        } catch (error) {
            console.error('Error saving receipt:', error);
            showNotification('Feil ved lagring av kvittering', 'danger');
        }
    }
    
    private handleFormCancel() {
        this.selectedReceipt = null;
        this.receiptFormContainer.innerHTML = '';
    }
    
    // Department management methods
    
    private showAddDepartmentForm() {
        // Hide receipt form if open
        this.receiptFormContainer.innerHTML = '';
        
        // Clear any previous department data
        this.selectedDepartment = null;
        
        // Initialize empty department object with default values
        const newDepartment: Department = {
            id: '',
            name: '',
            budget: 0,
            userCount: 1,
            budgetPerUser: 4000  // Default 4,000 NOK per user
        };
        
        // Show department form container
        this.departmentFormContainer.style.display = 'block';
        
        // Create form
        new DepartmentForm(
            this.departmentFormContainer,
            newDepartment,
            this.handleDepartmentSave.bind(this),
            this.handleDepartmentFormCancel.bind(this)
        );
        
        // Scroll to form
        this.departmentFormContainer.scrollIntoView({ behavior: 'smooth' });
    }
    
    private async showEditDepartmentForm(departmentId: string) {
        try {
            // Hide receipt form if open
            this.receiptFormContainer.innerHTML = '';
            
            // Get the department to edit
            const department = this.departments.find(d => d.id === departmentId);
            if (!department) {
                throw new Error('Department not found');
            }
            
            // Store for later use
            this.selectedDepartment = department;
            
            // Show department form container
            this.departmentFormContainer.style.display = 'block';
            
            // Create form
            new DepartmentForm(
                this.departmentFormContainer,
                department,
                this.handleDepartmentSave.bind(this),
                this.handleDepartmentFormCancel.bind(this)
            );
            
            // Scroll to form
            this.departmentFormContainer.scrollIntoView({ behavior: 'smooth' });
            
        } catch (error) {
            console.error('Error editing department:', error);
            showNotification('Kunne ikke laste avdelingsinformasjon', 'danger');
        }
    }
    
    private async handleDepartmentSave(department: Department) {
        try {
            const isNew = !department.id || department.id === '';
            
            if (isNew) {
                // Generate a new ID if needed
                department.id = department.id || crypto.randomUUID();
                
                // Add to database
                await databaseService.addDepartment(department);
                
                // Add to local array
                this.departments.push(department);
                
                showNotification('Avdeling opprettet', 'success');
                
                // Select the new department
                this.selectedDepartmentId = department.id;
                localStorage.setItem('selectedDepartmentId', department.id);
            } else {
                // Update in database
                await databaseService.updateDepartment(department);
                
                // Update in local array
                const index = this.departments.findIndex(d => d.id === department.id);
                if (index !== -1) {
                    this.departments[index] = department;
                }
                
                showNotification('Avdeling oppdatert', 'success');
            }
            
            // Close the form
            this.handleDepartmentFormCancel();
            
            // Refresh the department selector
            this.renderDepartmentSelector();
            
            // If we just added or updated the currently selected department, reload its data
            if (this.selectedDepartmentId === department.id) {
                await this.loadDepartmentReceipts(department.id);
            }
            
            // If this was the first department and none was selected before, select it
            if (this.departments.length === 1 && !this.selectedDepartmentId) {
                this.selectedDepartmentId = department.id;
                localStorage.setItem('selectedDepartmentId', department.id);
                await this.loadDepartmentReceipts(department.id);
            }
            
        } catch (error) {
            console.error('Error saving department:', error);
            showNotification('Feil ved lagring av avdeling', 'danger');
        }
    }
    
    private async handleDepartmentDelete(departmentId: string) {
        try {
            // Check if department has receipts
            const receipts = await databaseService.getReceiptsByDepartment(departmentId);
            
            if (receipts.length > 0) {
                const confirmed = confirm(`Denne avdelingen har ${receipts.length} kvitteringer. Sletting vil også fjerne alle tilknyttede kvitteringer. Vil du fortsette?`);
                if (!confirmed) return;
            } else {
                const confirmed = confirm('Er du sikker på at du vil slette denne avdelingen?');
                if (!confirmed) return;
            }
            
            // Delete from database
            await databaseService.deleteDepartment(departmentId);
            
            // Remove from local array
            this.departments = this.departments.filter(d => d.id !== departmentId);
            
            // If this was the selected department, clear selection
            if (this.selectedDepartmentId === departmentId) {
                this.selectedDepartmentId = null;
                localStorage.removeItem('selectedDepartmentId');
                
                // If there are other departments, select the first one
                if (this.departments.length > 0) {
                    this.selectedDepartmentId = this.departments[0].id;
                    localStorage.setItem('selectedDepartmentId', this.departments[0].id);
                    await this.loadDepartmentReceipts(this.departments[0].id);
                } else {
                    // Clear content if no departments left
                    this.receiptListContainer.innerHTML = '';
                    this.receiptFormContainer.innerHTML = '';
                    this.renderEmptyState();
                }
            }
            
            showNotification('Avdeling slettet', 'success');
            
            // Refresh the department selector
            this.renderDepartmentSelector();
            
        } catch (error) {
            console.error('Error deleting department:', error);
            showNotification('Feil ved sletting av avdeling', 'danger');
        }
    }
    
    private handleDepartmentFormCancel() {
        // Hide the form
        this.departmentFormContainer.style.display = 'none';
        this.departmentFormContainer.innerHTML = '';
        this.selectedDepartment = null;
    }
}