import { Department, Receipt } from '../types';
import { apiConfig, logEnvironment } from '../utils/config';

// Get the API base URL from config
const API_BASE_URL = apiConfig.baseUrl;

class DatabaseService {
    // Departments API calls
    async initDatabase(): Promise<boolean> {
        try {
            // Log environment configuration
            logEnvironment();
            
            console.log('Connecting to API at:', API_BASE_URL);
            
            // Test connection to the backend API
            try {
                const response = await fetch(`${API_BASE_URL}/health`, {
                    // Add a timeout to avoid long waits
                    signal: AbortSignal.timeout(3000) // 3 second timeout
                });
                
                if (response.ok) {
                    console.log('API connection test successful');
                    return true;
                } else {
                    console.warn(`API returned status: ${response.status}`);
                    return false;
                }
            } catch (fetchError) {
                console.error('API connection test failed:', fetchError);
                return false;
            }
            
            // The code below is commented out to prevent API connectivity from blocking startup
            /*
            // Make a simple test call to the API to confirm connectivity
            console.log('Testing API connection to:', `${API_BASE_URL}/health`);
            try {
                const response = await fetch(`${API_BASE_URL}/health`);
                console.log('API response status:', response.status);
                if (response.ok) {
                    const data = await response.json();
                    console.log('Connected to API successfully:', data);
                    return true;
                } else {
                    console.error(`API returned status ${response.status}`);
                    return false;
                }
            } catch (fetchError) {
                console.error('Fetch error connecting to API:', fetchError);
                return false;
            }
            */
        } catch (error) {
            // Log the error but silently continue - no need to alarm the user
            console.log('Could not connect to API, but continuing anyway');
            return true; // Return true to allow application to start
        }
    }

    async getAllDepartments(): Promise<Department[]> {
        try {
            const response = await fetch(`${API_BASE_URL}/departments`);
            if (!response.ok) {
                throw new Error(`API error: ${response.status}`);
            }
            return await response.json();
        } catch (error) {
            console.error('Error fetching departments:', error);
            return [];
        }
    }

    async getDepartment(id: string): Promise<Department | null> {
        try {
            const response = await fetch(`${API_BASE_URL}/departments/${id}`);
            if (!response.ok) {
                if (response.status === 404) {
                    return null;
                }
                throw new Error(`API error: ${response.status}`);
            }
            return await response.json();
        } catch (error) {
            console.error(`Error fetching department ${id}:`, error);
            return null;
        }
    }

    async addDepartment(department: Department): Promise<string> {
        try {
            const response = await fetch(`${API_BASE_URL}/departments`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(department),
            });
            
            if (!response.ok) {
                throw new Error(`API error: ${response.status}`);
            }
            
            const result = await response.json();
            return result.id;
        } catch (error) {
            console.error('Error adding department:', error);
            throw new Error('Failed to add department');
        }
    }

    async updateDepartment(department: Department): Promise<boolean> {
        try {
            const response = await fetch(`${API_BASE_URL}/departments/${department.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(department),
            });
            
            return response.ok;
        } catch (error) {
            console.error(`Error updating department ${department.id}:`, error);
            return false;
        }
    }

    async deleteDepartment(id: string): Promise<boolean> {
        try {
            const response = await fetch(`${API_BASE_URL}/departments/${id}`, {
                method: 'DELETE',
            });
            
            return response.ok;
        } catch (error) {
            console.error(`Error deleting department ${id}:`, error);
            return false;
        }
    }

    // Receipts API calls
    async getAllReceipts(): Promise<Receipt[]> {
        try {
            const response = await fetch(`${API_BASE_URL}/receipts`);
            if (!response.ok) {
                throw new Error(`API error: ${response.status}`);
            }
            return await response.json();
        } catch (error) {
            console.error('Error fetching receipts:', error);
            return [];
        }
    }
    
    async getReceiptsByDepartment(departmentId: string): Promise<Receipt[]> {
        try {
            const response = await fetch(`${API_BASE_URL}/departments/${departmentId}/receipts`);
            if (!response.ok) {
                throw new Error(`API error: ${response.status}`);
            }
            return await response.json();
        } catch (error) {
            console.error(`Error fetching receipts for department ${departmentId}:`, error);
            return [];
        }
    }
    
    async getReceipt(id: string): Promise<Receipt | null> {
        try {
            const response = await fetch(`${API_BASE_URL}/receipts/${id}`);
            if (!response.ok) {
                if (response.status === 404) {
                    return null;
                }
                throw new Error(`API error: ${response.status}`);
            }
            return await response.json();
        } catch (error) {
            console.error(`Error fetching receipt ${id}:`, error);
            return null;
        }
    }
    
    async addReceipt(receipt: Receipt): Promise<Receipt> {
        try {
            const response = await fetch(`${API_BASE_URL}/receipts`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(receipt),
            });
            
            if (!response.ok) {
                throw new Error(`API error: ${response.status}`);
            }
            
            const result = await response.json();
            // Since the server might have assigned values like invoiceId (bilagsnummer),
            // update and return the receipt with these server-assigned values
            return {
                ...receipt,
                id: result.id,
                invoiceId: result.invoiceId || receipt.invoiceId
            };
        } catch (error) {
            console.error('Error adding receipt:', error);
            throw new Error('Failed to add receipt');
        }
    }
    
    async updateReceipt(receipt: Receipt): Promise<boolean> {
        try {
            const response = await fetch(`${API_BASE_URL}/receipts/${receipt.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(receipt),
            });
            
            return response.ok;
        } catch (error) {
            console.error(`Error updating receipt ${receipt.id}:`, error);
            return false;
        }
    }
    
    async deleteReceipt(id: string): Promise<boolean> {
        try {
            const response = await fetch(`${API_BASE_URL}/receipts/${id}`, {
                method: 'DELETE',
            });
            
            return response.ok;
        } catch (error) {
            console.error(`Error deleting receipt ${id}:`, error);
            return false;
        }
    }
    
    async deleteReceiptsByDepartment(departmentId: string): Promise<boolean> {
        try {
            const response = await fetch(`${API_BASE_URL}/departments/${departmentId}/receipts`, {
                method: 'DELETE',
            });
            
            return response.ok;
        } catch (error) {
            console.error(`Error deleting receipts for department ${departmentId}:`, error);
            return false;
        }
    }
}

// Create a singleton instance
const databaseService = new DatabaseService();
export default databaseService;