import { Department } from '../types';
import { formatCurrency, createElement } from '../utils/helpers';

export default class DepartmentList {
    private container: HTMLElement;
    private departments: Department[];
    private onDepartmentSelect: (id: string) => void;
    private onDepartmentDelete: (id: string) => void;
    
    // Mapping for department type icons
    private iconMap: {[key: string]: string} = {
        'Salg': 'cash',
        'Marked': 'megaphone',
        'HR': 'people',
        'Utvikling': 'code-slash',
        'IT': 'laptop',
        'Finans': 'cash-coin',
        'Admin': 'gear'
    };
    
    // Custom event for adding a department
    private onAddButtonClick: () => void = () => {
        // Create and dispatch a custom event
        const event = new CustomEvent('add-department');
        document.dispatchEvent(event);
    };

    constructor(
        container: HTMLElement,
        departments: Department[],
        onDepartmentSelect: (id: string) => void,
        onDepartmentDelete: (id: string) => void
    ) {
        this.container = container;
        this.departments = departments;
        this.onDepartmentSelect = onDepartmentSelect;
        this.onDepartmentDelete = onDepartmentDelete;
        this.render(false); // Initialize with no DB error
    }

    private getDepartmentIcon(name: string): string {
        // Determine which icon to use based on department name
        let icon = 'building';
        for (const [keyword, iconName] of Object.entries(this.iconMap)) {
            if (name.toLowerCase().includes(keyword.toLowerCase())) {
                icon = iconName;
                break;
            }
        }
        return icon;
    }

    update(departments: Department[], dbConnectionFailed: boolean = false) {
        this.departments = departments;
        this.render(dbConnectionFailed);
    }

    private render(dbConnectionFailed: boolean = false) {
        this.container.innerHTML = '';

        // Minimalistic page header with title only
        const header = createElement('div', 'mb-3');
        
        const headerTitle = createElement('h3', 'mb-0');
        headerTitle.style.fontWeight = '500';
        headerTitle.textContent = 'Avdelinger';
        header.appendChild(headerTitle);
                
        this.container.appendChild(header);

        // Minimalistic container without card styling
        const cardContainer = createElement('div', 'mb-4');
        
        // Simple header with count and add button
        const cardHeader = createElement('div', 'd-flex justify-content-between align-items-center mb-3');
        
        // Simple count text
        const countText = createElement('div', 'text-secondary');
        countText.innerHTML = `${this.departments.length} avdeling${this.departments.length !== 1 ? 'er' : ''}`;
        cardHeader.appendChild(countText);
        
        cardContainer.appendChild(cardHeader);
        
        // Add a simple add department button for the header
        const addButton = createElement('button', 'btn btn-sm btn-primary add-department-btn');
        addButton.style.borderRadius = '4px';
        addButton.style.padding = '6px 12px';
        addButton.innerHTML = '<i class="bi bi-plus"></i> Legg til avdeling';
        addButton.addEventListener('click', () => this.onAddButtonClick());
        
        // Don't disable the add button even if DB connection failed
        // The user should be able to add departments even if the DB is currently unavailable
        
        cardHeader.appendChild(addButton);
        
        // Card body
        const cardBody = createElement('div', '');
        
        if (this.departments.length === 0) {
            // Minimalistic empty state
            const emptyState = createElement('div', 'text-center py-4');
            
            // Always show normal empty state message, even if database connection failed
            // The database connection error will be shown in notifications instead
            emptyState.innerHTML = `
                <p class="text-muted">Ingen avdelinger. Legg til en avdeling for å komme i gang.</p>
            `;
            
            cardBody.appendChild(emptyState);
        } else {
            // Minimalistic list view
            const departmentsTable = createElement('table', 'table table-hover');
            departmentsTable.style.fontSize = '14px';
            
            // Sort departments alphabetically
            const sortedDepartments = [...this.departments].sort((a, b) => 
                a.name.localeCompare(b.name, 'nb', { sensitivity: 'base' }));
            
            // Create minimalistic table header
            const tableHeader = createElement('thead');
            tableHeader.innerHTML = `
                <tr>
                    <th>Avdeling</th>
                    <th class="text-center">Brukere</th>
                    <th class="text-end">Budsjett</th>
                    <th></th>
                </tr>
            `;
            departmentsTable.appendChild(tableHeader);
            
            // Create table body
            const tableBody = createElement('tbody');
            
            sortedDepartments.forEach(department => {
                const row = createElement('tr');
                row.style.cursor = 'pointer';
                row.dataset.id = department.id;
                
                // Department name cell
                const nameCell = createElement('td');
                nameCell.style.fontWeight = '500';
                nameCell.textContent = department.name;
                
                // User count cell
                const userCell = createElement('td', 'text-center');
                userCell.textContent = department.userCount.toString();
                
                // Budget cell
                const budgetCell = createElement('td', 'text-end');
                budgetCell.textContent = formatCurrency(department.budget);
                
                // Actions cell
                const actionsCell = createElement('td', 'text-end');
                
                // Simple action buttons
                const actionBtns = createElement('div', 'd-flex justify-content-end gap-2');
                
                // Edit button
                const editBtn = createElement('button', 'btn btn-sm btn-light');
                editBtn.style.padding = '2px 8px';
                editBtn.innerHTML = '<i class="bi bi-pencil"></i>';
                
                editBtn.addEventListener('click', (e) => {
                    e.stopPropagation();
                    this.onDepartmentSelect(department.id);
                });
                
                // Delete button
                const deleteBtn = createElement('button', 'btn btn-sm btn-light');
                deleteBtn.style.padding = '2px 8px';
                deleteBtn.innerHTML = '<i class="bi bi-trash"></i>';
                
                deleteBtn.addEventListener('click', (e) => {
                    e.stopPropagation();
                    if (confirm(`Er du sikker på at du vil slette ${department.name}?`)) {
                        this.onDepartmentDelete(department.id);
                    }
                });
                
                actionBtns.appendChild(editBtn);
                actionBtns.appendChild(deleteBtn);
                actionsCell.appendChild(actionBtns);
                
                // Add cells to row
                row.appendChild(nameCell);
                row.appendChild(userCell);
                row.appendChild(budgetCell);
                row.appendChild(actionsCell);
                
                // Make row clickable
                row.addEventListener('click', () => {
                    this.onDepartmentSelect(department.id);
                });
                
                tableBody.appendChild(row);
            });
            
            departmentsTable.appendChild(tableBody);
            cardBody.appendChild(departmentsTable);
        }
        
        cardContainer.appendChild(cardBody);
        this.container.appendChild(cardContainer);
    }
}