import { Department, Receipt } from '../types';
import { formatCurrency, createElement } from '../utils/helpers';

export default class ReceiptList {
    private container: HTMLElement;
    private department: Department;
    private receipts: Receipt[];
    private totalSpent: number = 0;
    private onReceiptSelect: (id: string) => void;
    private onReceiptDelete: (id: string) => void;
    private onAddReceipt: () => void;
    private month: number;
    private year: number;
    private weekdaySpent: number = 0;
    private weekendSpent: number = 0;
    private daysInMonth: number = 30; // Default value, will be calculated
    private hideActions: boolean = false; // Whether to hide edit/delete buttons

    constructor(
        container: HTMLElement,
        department: Department,
        receipts: Receipt[],
        onReceiptSelect: (id: string) => void,
        onReceiptDelete: (id: string) => void,
        onAddReceipt: () => void,
        month?: number,
        year?: number,
        hideActions: boolean = false
    ) {
        this.container = container;
        this.department = department;
        this.receipts = receipts;
        
        // Default to current month/year if not provided
        const now = new Date();
        this.month = month || now.getMonth() + 1;
        this.year = year || now.getFullYear();
        
        this.calculateSpending();
        this.onReceiptSelect = onReceiptSelect;
        this.onReceiptDelete = onReceiptDelete;
        this.onAddReceipt = onAddReceipt;
        this.hideActions = hideActions;
        this.render();
    }

    update(department: Department, receipts: Receipt[], month?: number, year?: number, hideActions?: boolean) {
        this.department = department;
        this.receipts = receipts;
        
        // Update month and year if provided
        if (month) this.month = month;
        if (year) this.year = year;
        
        // Update hideActions if provided
        if (hideActions !== undefined) this.hideActions = hideActions;
        
        this.calculateSpending();
        this.render();
    }

    private weekdayPeriodSpending: Map<number, number> = new Map(); // Week number -> spending
    private weekendPeriodSpending: Map<number, number> = new Map(); // Week number -> spending
    private monthNames = [
        'Januar', 'Februar', 'Mars', 'April', 'Mai', 'Juni',
        'Juli', 'August', 'September', 'Oktober', 'November', 'Desember'
    ];

    private calculateSpending(): void {
        // Calculate total spending
        this.totalSpent = this.receipts.reduce((total, receipt) => total + receipt.amount, 0);
        
        // Calculate weekday/weekend spending
        this.weekdaySpent = 0;
        this.weekendSpent = 0;
        
        // Reset period spending maps
        this.weekdayPeriodSpending = new Map();
        this.weekendPeriodSpending = new Map();
        
        // Calculate days in the month for budget per day calculations
        this.daysInMonth = new Date(this.year, this.month, 0).getDate();
        
        // Group receipts by week 
        for (const receipt of this.receipts) {
            const date = new Date(receipt.date);
            const dayOfWeek = date.getDay(); // 0 = Sunday, 1-5 = Monday-Friday, 6 = Saturday
            const dayOfMonth = date.getDate();
            
            // Calculate which week of the month this date falls in (1-indexed)
            // First day of month might not start on Monday, so we need to adjust
            const firstDayOfMonth = new Date(this.year, this.month - 1, 1);
            const firstDayOfWeek = firstDayOfMonth.getDay(); // 0 = Sunday, 1-5 = Monday-Friday, 6 = Saturday
            const weekNumber = Math.ceil((dayOfMonth + (firstDayOfWeek === 0 ? 6 : firstDayOfWeek - 1)) / 7);
            
            // Mon-Thu = Weekday, Fri-Sun = Weekend per requirements
            if (dayOfWeek >= 5 || dayOfWeek === 0) { // Weekend (Friday, Saturday or Sunday)
                this.weekendSpent += receipt.amount;
                
                // Add to specific weekend period
                const currentSpending = this.weekendPeriodSpending.get(weekNumber) || 0;
                this.weekendPeriodSpending.set(weekNumber, currentSpending + receipt.amount);
                
            } else { // Weekday (Monday through Thursday)
                this.weekdaySpent += receipt.amount;
                
                // Add to specific weekday period
                const currentSpending = this.weekdayPeriodSpending.get(weekNumber) || 0;
                this.weekdayPeriodSpending.set(weekNumber, currentSpending + receipt.amount);
            }
        }
        
        // Make sure we have at least empty entries for all weeks in the month
        const firstDayOfMonth = this.getFirstDayOfMonth();
        const weeksInMonth = Math.ceil((this.daysInMonth + (firstDayOfMonth.getDay() === 0 ? 6 : firstDayOfMonth.getDay() - 1)) / 7);
        for (let i = 1; i <= weeksInMonth; i++) {
            if (!this.weekdayPeriodSpending.has(i)) {
                this.weekdayPeriodSpending.set(i, 0);
            }
            if (!this.weekendPeriodSpending.has(i)) {
                this.weekendPeriodSpending.set(i, 0);
            }
        }
    }
    
    private getFirstDayOfMonth(): Date {
        return new Date(this.year, this.month - 1, 1);
    }

    private formatDate(dateStr: string): string {
        const date = new Date(dateStr);
        return date.toLocaleDateString('nb-NO');
    }
    
    // Function to get ISO week number
    private getISOWeek(date: Date): number {
        // Create a copy of the date to avoid modifying the original
        const d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
        // Set to nearest Thursday: current date + 4 - current day number
        // Make Sunday's day number 7
        d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
        // Get first day of year
        const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
        // Calculate full weeks to nearest Thursday
        const weekNum = Math.ceil(((d.getTime() - yearStart.getTime()) / 86400000 + 1) / 7);
        return weekNum;
    }

    private renderBudgetSplitGraph(): HTMLElement {
        // Get screen width to adjust for mobile
        const isMobile = window.innerWidth < 768;
        
        // Create a modern card with shadow and rounded corners - smaller on mobile
        const graphContainer = createElement('div', 'card mt-3 shadow-sm');
        graphContainer.style.borderRadius = isMobile ? '8px' : '12px';
        graphContainer.style.border = 'none';
        graphContainer.style.overflow = 'hidden';
        graphContainer.classList.add('budget-graph-container');
        if (isMobile) {
            graphContainer.classList.add('compact-mobile');
        }
        // Fix overflow issues
        graphContainer.style.maxWidth = '100%';
        
        // Create a gradient header with reduced padding on mobile
        const graphHeader = createElement('div', 'card-header d-flex justify-content-between align-items-center');
        graphHeader.style.background = 'linear-gradient(135deg, #6a11cb 0%, #2575fc 100%)';
        graphHeader.style.color = 'white';
        graphHeader.style.padding = isMobile ? '12px 15px' : '16px 20px';
        
        // Header with icon - smaller on mobile
        const headerTitle = createElement('h4', 'm-0 d-flex align-items-center');
        headerTitle.style.fontSize = isMobile ? '1.2rem' : '1.5rem';
        headerTitle.innerHTML = '<i class="bi bi-graph-up me-2"></i>Ukentlig budsjettstatus';
        graphHeader.appendChild(headerTitle);
        
        // Add view summary button - icon only on mobile
        const viewSummaryBtn = createElement('button', 'btn btn-sm btn-light');
        viewSummaryBtn.style.borderRadius = '20px';
        viewSummaryBtn.style.boxShadow = '0 2px 5px rgba(0,0,0,0.1)';
        viewSummaryBtn.style.padding = isMobile ? '5px 10px' : '6px 12px';
        viewSummaryBtn.innerHTML = isMobile ? 
            '<i class="bi bi-bar-chart-fill"></i>' : 
            '<i class="bi bi-bar-chart-fill me-1"></i> Se budsjettsammendrag';
        viewSummaryBtn.title = 'Se budsjettsammendrag';
        viewSummaryBtn.addEventListener('click', () => {
            // Switch to summary tab programmatically
            const summaryTab = document.querySelector('.side-menu-link[data-section="summary"]');
            if (summaryTab && summaryTab instanceof HTMLElement) {
                summaryTab.click();
            }
        });
        graphHeader.appendChild(viewSummaryBtn);
        
        // Enhanced card body with reduced padding on mobile
        const graphBody = createElement('div', 'card-body');
        graphBody.style.padding = isMobile ? '12px' : '20px';
        
        // Calculate budgets per day and per period
        const totalBudget = this.department.budget;
        const budgetPerUser = this.department.budgetPerUser;
        const numUsers = this.department.userCount;
        
        // Get first day of month
        const firstDayOfMonth = this.getFirstDayOfMonth();
        const firstDayOfWeek = firstDayOfMonth.getDay(); // 0 = Sunday, 1-6 = Monday-Saturday
        
        // Calculate number of weeks in this month
        const weeksInMonth = Math.ceil((this.daysInMonth + (firstDayOfWeek === 0 ? 6 : firstDayOfWeek - 1)) / 7);
        
        // Daily budget calculation
        const dailyBudgetPerUser = budgetPerUser / this.daysInMonth;
        
        // Budget for each work interval (4-day and 3-day periods)
        const weekdayBudgetPerUser = dailyBudgetPerUser * 4; // Budget for one 4-day period per user
        const weekendBudgetPerUser = dailyBudgetPerUser * 3; // Budget for one 3-day period per user
        
        // Budget per work period for all users
        const weekdayBudgetPerPeriod = weekdayBudgetPerUser * numUsers;
        const weekendBudgetPerPeriod = weekendBudgetPerUser * numUsers;
        
        // Create graph container with improved styling
        const graph = createElement('div', 'budget-graph');
        
        // Determine current week (ISO week number)
        const todayDate = new Date();
        let currentWeekNumber = -1;
        let currentISOWeek = -1;
        
        // Month-based week number (for matching with periods)
        if (todayDate.getMonth() + 1 === this.month && todayDate.getFullYear() === this.year) {
            const dayOfMonth = todayDate.getDate();
            const firstDayOfMonth = new Date(this.year, this.month - 1, 1);
            const firstDayOfWeek = firstDayOfMonth.getDay(); // 0 = Sunday, 1-5 = Monday-Friday, 6 = Saturday
            currentWeekNumber = Math.ceil((dayOfMonth + (firstDayOfWeek === 0 ? 6 : firstDayOfWeek - 1)) / 7);
            currentISOWeek = this.getISOWeek(todayDate);
        }
        
        // Add current week indicator - smaller on mobile
        const weekIndicator = createElement('div', 'd-flex align-items-center mb-3');
        
        const weekIcon = createElement('div', 'me-2 rounded-circle d-flex align-items-center justify-content-center');
        weekIcon.style.width = isMobile ? '30px' : '36px';
        weekIcon.style.height = isMobile ? '30px' : '36px';
        weekIcon.style.background = '#f8f9fa';
        weekIcon.style.border = '1px solid rgba(0,0,0,0.1)';
        weekIcon.innerHTML = `<i class="bi bi-calendar-week text-primary" style="font-size: ${isMobile ? '1rem' : '1.25rem'};"></i>`;
        
        const weekText = createElement('div', '');
        weekText.innerHTML = `
            <h5 class="mb-0" style="font-size: ${isMobile ? '1rem' : '1.25rem'};">Uke ${currentISOWeek > 0 ? currentISOWeek : '?'}</h5>
            <small class="text-muted" style="font-size: ${isMobile ? '0.75rem' : '0.875rem'};">Gjeldende uke</small>
        `;
        
        weekIndicator.appendChild(weekIcon);
        weekIndicator.appendChild(weekText);
        graph.appendChild(weekIndicator);
        
        if (currentWeekNumber > 0) {
            const week = currentWeekNumber;
            
            // Weekday spending data
            const weekdaySpending = this.weekdayPeriodSpending.get(week) || 0;
            const isExceededWeekday = weekdaySpending > weekdayBudgetPerPeriod;
            const percentageWeekday = Math.min((weekdaySpending / weekdayBudgetPerPeriod) * 100, 100);
            // Updated color classes with gradient backgrounds
            const weekdayColorGradient = isExceededWeekday ? 
                'linear-gradient(135deg, #CB356B, #BD3F32)' : 
                percentageWeekday > 80 ? 'linear-gradient(135deg, #F2994A, #F2C94C)' : 
                'linear-gradient(135deg, #11998e, #38ef7d)';
            const weekdayTextColor = isExceededWeekday ? '#CB356B' : percentageWeekday > 80 ? '#F2994A' : '#11998e';
            const weekdayDates = this.getWeekDatesString(week, 'weekday');
            
            // Weekend spending data
            const weekendSpending = this.weekendPeriodSpending.get(week) || 0;
            const isExceededWeekend = weekendSpending > weekendBudgetPerPeriod;
            const percentageWeekend = Math.min((weekendSpending / weekendBudgetPerPeriod) * 100, 100);
            // Updated color classes with gradient backgrounds
            const weekendColorGradient = isExceededWeekend ? 
                'linear-gradient(135deg, #CB356B, #BD3F32)' : 
                percentageWeekend > 80 ? 'linear-gradient(135deg, #F2994A, #F2C94C)' : 
                'linear-gradient(135deg, #11998e, #38ef7d)';
            const weekendTextColor = isExceededWeekend ? '#CB356B' : percentageWeekend > 80 ? '#F2994A' : '#11998e';
            const weekendDates = this.getWeekDatesString(week, 'weekend');
            
            if (isMobile) {
                // Mobile version: Simpler layout with stacked cards
                
                // Weekdays card - more compact for mobile
                const weekdayCard = createElement('div', 'card mb-3');
                weekdayCard.style.borderRadius = '8px';
                weekdayCard.style.overflow = 'hidden';
                weekdayCard.style.border = '1px solid rgba(0,0,0,0.1)';
                
                // Weekday header with icon and status indicator
                const weekdayHeader = createElement('div', 'card-header d-flex justify-content-between align-items-center');
                weekdayHeader.style.background = '#f8f9fa';
                weekdayHeader.style.padding = '10px 12px';
                
                const weekdayHeaderLeft = createElement('div', 'd-flex align-items-center');
                
                // Icon with background
                const weekdayIcon = createElement('div', 'me-2 rounded d-flex align-items-center justify-content-center');
                weekdayIcon.style.width = '24px';
                weekdayIcon.style.height = '24px';
                weekdayIcon.style.background = 'linear-gradient(135deg, #4b6cb7, #182848)';
                weekdayIcon.style.color = 'white';
                weekdayIcon.innerHTML = '<i class="bi bi-briefcase" style="font-size: 0.8rem;"></i>';
                
                const weekdayTitle = createElement('h6', 'mb-0');
                weekdayTitle.style.fontSize = '0.9rem';
                weekdayTitle.textContent = `Ukedager (${weekdayDates})`;
                
                weekdayHeaderLeft.appendChild(weekdayIcon);
                weekdayHeaderLeft.appendChild(weekdayTitle);
                
                // Status badge - smaller on mobile
                const weekdayStatus = createElement('span', 'badge rounded-pill py-1 px-2');
                weekdayStatus.style.background = weekdayColorGradient;
                weekdayStatus.style.color = 'white';
                weekdayStatus.style.fontWeight = '500';
                weekdayStatus.style.fontSize = '10px';
                weekdayStatus.innerHTML = isExceededWeekday ? 
                    '<i class="bi bi-exclamation-triangle-fill"></i>' : 
                    '<i class="bi bi-check-circle-fill"></i>';
                weekdayStatus.title = isExceededWeekday ? 'OVERSKREDET' : 'OK';
                
                weekdayHeader.appendChild(weekdayHeaderLeft);
                weekdayHeader.appendChild(weekdayStatus);
                
                // Weekday body with simpler layout for mobile
                const weekdayBody = createElement('div', 'card-body');
                weekdayBody.style.padding = '10px';
                
                // Budget indicators in single row for mobile
                const weekdayBudgetInfo = createElement('div', 'd-flex justify-content-between mb-2');
                weekdayBudgetInfo.innerHTML = `
                    <div>
                        <small class="text-muted d-block">Budsjett</small>
                        <span style="font-weight: 600;">${formatCurrency(weekdayBudgetPerPeriod)}</span>
                    </div>
                    <div>
                        <small class="text-muted d-block">Brukt</small>
                        <span style="font-weight: 600; color: ${weekdayTextColor};">${formatCurrency(weekdaySpending)}</span>
                    </div>
                    <div>
                        <small class="text-muted d-block">${isExceededWeekday ? 'Overskredet' : 'Gjenstående'}</small>
                        <span style="font-weight: 600; color: ${weekdayTextColor};">
                            ${formatCurrency(isExceededWeekday ? 
                                weekdaySpending - weekdayBudgetPerPeriod : 
                                weekdayBudgetPerPeriod - weekdaySpending)}
                        </span>
                    </div>
                `;
                
                // Compact progress bar for mobile - fixed symmetry and clipping
                const weekdayProgressWrapper = createElement('div', 'progress-wrapper');
                weekdayProgressWrapper.style.position = 'relative';
                weekdayProgressWrapper.style.height = '22px';
                weekdayProgressWrapper.style.maxWidth = '100%';
                weekdayProgressWrapper.style.overflow = 'hidden';
                
                const weekdayProgressContainer = createElement('div', 'progress');
                weekdayProgressContainer.style.height = '100%';
                weekdayProgressContainer.style.borderRadius = '20px';
                weekdayProgressContainer.style.backgroundColor = '#f0f0f0';
                weekdayProgressContainer.style.overflow = 'hidden';
                
                const weekdayProgressBar = createElement('div', 'progress-bar');
                weekdayProgressBar.style.width = `${percentageWeekday}%`;
                weekdayProgressBar.style.background = weekdayColorGradient;
                if (isExceededWeekday) {
                    weekdayProgressBar.classList.add('progress-bar-striped', 'progress-bar-animated');
                }
                
                // Progress percentage label
                const weekdayProgressLabel = createElement('div', 'progress-label');
                weekdayProgressLabel.style.position = 'absolute';
                weekdayProgressLabel.style.top = '0';
                weekdayProgressLabel.style.left = '0';
                weekdayProgressLabel.style.right = '0';
                weekdayProgressLabel.style.bottom = '0';
                weekdayProgressLabel.style.display = 'flex';
                weekdayProgressLabel.style.alignItems = 'center';
                weekdayProgressLabel.style.justifyContent = 'center';
                weekdayProgressLabel.style.color = 'white';
                weekdayProgressLabel.style.fontWeight = '600';
                weekdayProgressLabel.style.fontSize = '0.75rem';
                weekdayProgressLabel.style.textShadow = '0 1px 2px rgba(0,0,0,0.2)';
                weekdayProgressLabel.innerHTML = `${Math.round(percentageWeekday)}% brukt`;
                
                weekdayProgressContainer.appendChild(weekdayProgressBar);
                weekdayProgressWrapper.appendChild(weekdayProgressContainer);
                weekdayProgressWrapper.appendChild(weekdayProgressLabel);
                
                weekdayBody.appendChild(weekdayBudgetInfo);
                weekdayBody.appendChild(weekdayProgressWrapper);
                
                weekdayCard.appendChild(weekdayHeader);
                weekdayCard.appendChild(weekdayBody);
                
                // Weekend card - more compact for mobile
                const weekendCard = createElement('div', 'card mb-3');
                weekendCard.style.borderRadius = '8px';
                weekendCard.style.overflow = 'hidden';
                weekendCard.style.border = '1px solid rgba(0,0,0,0.1)';
                
                // Weekend header
                const weekendHeader = createElement('div', 'card-header d-flex justify-content-between align-items-center');
                weekendHeader.style.background = '#f8f9fa';
                weekendHeader.style.padding = '10px 12px';
                
                const weekendHeaderLeft = createElement('div', 'd-flex align-items-center');
                
                // Icon with background
                const weekendIcon = createElement('div', 'me-2 rounded d-flex align-items-center justify-content-center');
                weekendIcon.style.width = '24px';
                weekendIcon.style.height = '24px';
                weekendIcon.style.background = 'linear-gradient(135deg, #0052D4, #4364F7, #6FB1FC)';
                weekendIcon.style.color = 'white';
                weekendIcon.innerHTML = '<i class="bi bi-calendar-weekend" style="font-size: 0.8rem;"></i>';
                
                const weekendTitle = createElement('h6', 'mb-0');
                weekendTitle.style.fontSize = '0.9rem';
                weekendTitle.textContent = `Helg (${weekendDates})`;
                
                weekendHeaderLeft.appendChild(weekendIcon);
                weekendHeaderLeft.appendChild(weekendTitle);
                
                // Status badge - smaller on mobile
                const weekendStatus = createElement('span', 'badge rounded-pill py-1 px-2');
                weekendStatus.style.background = weekendColorGradient;
                weekendStatus.style.color = 'white';
                weekendStatus.style.fontWeight = '500';
                weekendStatus.style.fontSize = '10px';
                weekendStatus.innerHTML = isExceededWeekend ? 
                    '<i class="bi bi-exclamation-triangle-fill"></i>' : 
                    '<i class="bi bi-check-circle-fill"></i>';
                weekendStatus.title = isExceededWeekend ? 'OVERSKREDET' : 'OK';
                
                weekendHeader.appendChild(weekendHeaderLeft);
                weekendHeader.appendChild(weekendStatus);
                
                // Weekend body with simpler layout for mobile
                const weekendBody = createElement('div', 'card-body');
                weekendBody.style.padding = '10px';
                
                // Budget indicators in single row for mobile
                const weekendBudgetInfo = createElement('div', 'd-flex justify-content-between mb-2');
                weekendBudgetInfo.innerHTML = `
                    <div>
                        <small class="text-muted d-block">Budsjett</small>
                        <span style="font-weight: 600;">${formatCurrency(weekendBudgetPerPeriod)}</span>
                    </div>
                    <div>
                        <small class="text-muted d-block">Brukt</small>
                        <span style="font-weight: 600; color: ${weekendTextColor};">${formatCurrency(weekendSpending)}</span>
                    </div>
                    <div>
                        <small class="text-muted d-block">${isExceededWeekend ? 'Overskredet' : 'Gjenstående'}</small>
                        <span style="font-weight: 600; color: ${weekendTextColor};">
                            ${formatCurrency(isExceededWeekend ? 
                                weekendSpending - weekendBudgetPerPeriod : 
                                weekendBudgetPerPeriod - weekendSpending)}
                        </span>
                    </div>
                `;
                
                // Compact progress bar for mobile - fixed symmetry and clipping
                const weekendProgressWrapper = createElement('div', 'progress-wrapper');
                weekendProgressWrapper.style.position = 'relative';
                weekendProgressWrapper.style.height = '22px';
                weekendProgressWrapper.style.maxWidth = '100%';
                weekendProgressWrapper.style.overflow = 'hidden';
                
                const weekendProgressContainer = createElement('div', 'progress');
                weekendProgressContainer.style.height = '100%';
                weekendProgressContainer.style.borderRadius = '20px';
                weekendProgressContainer.style.backgroundColor = '#f0f0f0';
                weekendProgressContainer.style.overflow = 'hidden';
                
                const weekendProgressBar = createElement('div', 'progress-bar');
                weekendProgressBar.style.width = `${percentageWeekend}%`;
                weekendProgressBar.style.background = weekendColorGradient;
                if (isExceededWeekend) {
                    weekendProgressBar.classList.add('progress-bar-striped', 'progress-bar-animated');
                }
                
                // Progress percentage label
                const weekendProgressLabel = createElement('div', 'progress-label');
                weekendProgressLabel.style.position = 'absolute';
                weekendProgressLabel.style.top = '0';
                weekendProgressLabel.style.left = '0';
                weekendProgressLabel.style.right = '0';
                weekendProgressLabel.style.bottom = '0';
                weekendProgressLabel.style.display = 'flex';
                weekendProgressLabel.style.alignItems = 'center';
                weekendProgressLabel.style.justifyContent = 'center';
                weekendProgressLabel.style.color = 'white';
                weekendProgressLabel.style.fontWeight = '600';
                weekendProgressLabel.style.fontSize = '0.75rem';
                weekendProgressLabel.style.textShadow = '0 1px 2px rgba(0,0,0,0.2)';
                weekendProgressLabel.innerHTML = `${Math.round(percentageWeekend)}% brukt`;
                
                weekendProgressContainer.appendChild(weekendProgressBar);
                weekendProgressWrapper.appendChild(weekendProgressContainer);
                weekendProgressWrapper.appendChild(weekendProgressLabel);
                
                weekendBody.appendChild(weekendBudgetInfo);
                weekendBody.appendChild(weekendProgressWrapper);
                
                weekendCard.appendChild(weekendHeader);
                weekendCard.appendChild(weekendBody);
                
                // Add both cards to the container
                graph.appendChild(weekdayCard);
                graph.appendChild(weekendCard);
                
            } else {
                // Desktop version: Original layout with larger cards
                
                // Create modern card for current week stats
                const weekContainer = createElement('div', 'current-week p-0');
                weekContainer.style.borderRadius = '12px';
                weekContainer.style.overflow = 'hidden';
                weekContainer.style.boxShadow = '0 4px 12px rgba(0,0,0,0.08)';
                
                // Weekdays card
                const weekdayCard = createElement('div', 'card mb-4');
                weekdayCard.style.borderRadius = '12px';
                weekdayCard.style.overflow = 'hidden';
                weekdayCard.style.border = 'none';
                
                // Weekday header with icon and status indicator
                const weekdayHeader = createElement('div', 'card-header d-flex justify-content-between align-items-center');
                weekdayHeader.style.background = '#f8f9fa';
                weekdayHeader.style.padding = '12px 15px';
                
                const weekdayHeaderLeft = createElement('div', 'd-flex align-items-center');
                
                // Icon with background
                const weekdayIcon = createElement('div', 'me-2 rounded d-flex align-items-center justify-content-center');
                weekdayIcon.style.width = '32px';
                weekdayIcon.style.height = '32px';
                weekdayIcon.style.background = 'linear-gradient(135deg, #4b6cb7, #182848)';
                weekdayIcon.style.color = 'white';
                weekdayIcon.innerHTML = '<i class="bi bi-briefcase"></i>';
                
                const weekdayTitle = createElement('h6', 'mb-0');
                weekdayTitle.textContent = `Ukedager (${weekdayDates})`;
                
                weekdayHeaderLeft.appendChild(weekdayIcon);
                weekdayHeaderLeft.appendChild(weekdayTitle);
                
                // Status badge
                const weekdayStatus = createElement('span', 'badge rounded-pill py-2 px-3');
                weekdayStatus.style.background = weekdayColorGradient;
                weekdayStatus.style.color = 'white';
                weekdayStatus.style.fontWeight = '500';
                weekdayStatus.style.fontSize = '12px';
                weekdayStatus.innerHTML = isExceededWeekday ? 
                    '<i class="bi bi-exclamation-triangle-fill me-1"></i>OVERSKREDET' : 
                    '<i class="bi bi-check-circle-fill me-1"></i>OK';
                
                weekdayHeader.appendChild(weekdayHeaderLeft);
                weekdayHeader.appendChild(weekdayStatus);
                
                // Weekday body with better formatted info
                const weekdayBody = createElement('div', 'card-body');
                weekdayBody.style.padding = '15px';
                
                // Budget indicators with improved visual hierarchy
                const weekdayBudgetRow = createElement('div', 'row align-items-center mb-3');
                
                // Budget column
                const weekdayBudgetCol = createElement('div', 'col-4');
                weekdayBudgetCol.innerHTML = `
                    <div class="text-center">
                        <div class="text-muted small">Budsjett</div>
                        <div style="font-weight: 600; font-size: 1.1rem;">${formatCurrency(weekdayBudgetPerPeriod)}</div>
                    </div>
                `;
                
                // Spent column
                const weekdaySpentCol = createElement('div', 'col-4');
                weekdaySpentCol.innerHTML = `
                    <div class="text-center">
                        <div class="text-muted small">Brukt</div>
                        <div style="font-weight: 600; font-size: 1.1rem; color: ${weekdayTextColor};">${formatCurrency(weekdaySpending)}</div>
                    </div>
                `;
                
                // Remaining/exceeded column
                const weekdayRemainingCol = createElement('div', 'col-4');
                weekdayRemainingCol.innerHTML = `
                    <div class="text-center">
                        <div class="text-muted small">${isExceededWeekday ? 'Overskredet' : 'Gjenstående'}</div>
                        <div style="font-weight: 600; font-size: 1.1rem; color: ${weekdayTextColor};">
                            ${formatCurrency(isExceededWeekday ? 
                                weekdaySpending - weekdayBudgetPerPeriod : 
                                weekdayBudgetPerPeriod - weekdaySpending)}
                        </div>
                    </div>
                `;
                
                weekdayBudgetRow.appendChild(weekdayBudgetCol);
                weekdayBudgetRow.appendChild(weekdaySpentCol);
                weekdayBudgetRow.appendChild(weekdayRemainingCol);
                
                // Enhanced progress bar - fixed symmetry and clipping
                const weekdayProgressWrapper = createElement('div', 'progress-wrapper mt-2');
                weekdayProgressWrapper.style.position = 'relative';
                weekdayProgressWrapper.style.height = '26px';
                weekdayProgressWrapper.style.maxWidth = '100%';
                weekdayProgressWrapper.style.overflow = 'hidden';
                
                const weekdayProgressContainer = createElement('div', 'progress');
                weekdayProgressContainer.style.height = '100%';
                weekdayProgressContainer.style.borderRadius = '30px';
                weekdayProgressContainer.style.backgroundColor = '#f0f0f0';
                weekdayProgressContainer.style.overflow = 'hidden';
                
                const weekdayProgressBar = createElement('div', 'progress-bar');
                weekdayProgressBar.style.width = `${percentageWeekday}%`;
                weekdayProgressBar.style.background = weekdayColorGradient;
                if (isExceededWeekday) {
                    weekdayProgressBar.classList.add('progress-bar-striped', 'progress-bar-animated');
                }
                
                // Progress percentage label
                const weekdayProgressLabel = createElement('div', 'progress-label');
                weekdayProgressLabel.style.position = 'absolute';
                weekdayProgressLabel.style.top = '0';
                weekdayProgressLabel.style.left = '0';
                weekdayProgressLabel.style.right = '0';
                weekdayProgressLabel.style.bottom = '0';
                weekdayProgressLabel.style.display = 'flex';
                weekdayProgressLabel.style.alignItems = 'center';
                weekdayProgressLabel.style.justifyContent = 'center';
                weekdayProgressLabel.style.color = 'white';
                weekdayProgressLabel.style.fontWeight = '600';
                weekdayProgressLabel.style.textShadow = '0 1px 2px rgba(0,0,0,0.2)';
                weekdayProgressLabel.innerHTML = `${Math.round(percentageWeekday)}% brukt`;
                
                weekdayProgressContainer.appendChild(weekdayProgressBar);
                weekdayProgressWrapper.appendChild(weekdayProgressContainer);
                weekdayProgressWrapper.appendChild(weekdayProgressLabel);
                
                weekdayBody.appendChild(weekdayBudgetRow);
                weekdayBody.appendChild(weekdayProgressWrapper);
                
                weekdayCard.appendChild(weekdayHeader);
                weekdayCard.appendChild(weekdayBody);
                
                // Weekend card
                const weekendCard = createElement('div', 'card');
                weekendCard.style.borderRadius = '12px';
                weekendCard.style.overflow = 'hidden';
                weekendCard.style.border = 'none';
                
                // Weekend header with icon and status indicator
                const weekendHeader = createElement('div', 'card-header d-flex justify-content-between align-items-center');
                weekendHeader.style.background = '#f8f9fa';
                weekendHeader.style.padding = '12px 15px';
                
                const weekendHeaderLeft = createElement('div', 'd-flex align-items-center');
                
                // Icon with background
                const weekendIcon = createElement('div', 'me-2 rounded d-flex align-items-center justify-content-center');
                weekendIcon.style.width = '32px';
                weekendIcon.style.height = '32px';
                weekendIcon.style.background = 'linear-gradient(135deg, #0052D4, #4364F7, #6FB1FC)';
                weekendIcon.style.color = 'white';
                weekendIcon.innerHTML = '<i class="bi bi-calendar-weekend"></i>';
                
                const weekendTitle = createElement('h6', 'mb-0');
                weekendTitle.textContent = `Helg (${weekendDates})`;
                
                weekendHeaderLeft.appendChild(weekendIcon);
                weekendHeaderLeft.appendChild(weekendTitle);
                
                // Status badge
                const weekendStatus = createElement('span', 'badge rounded-pill py-2 px-3');
                weekendStatus.style.background = weekendColorGradient;
                weekendStatus.style.color = 'white';
                weekendStatus.style.fontWeight = '500';
                weekendStatus.style.fontSize = '12px';
                weekendStatus.innerHTML = isExceededWeekend ? 
                    '<i class="bi bi-exclamation-triangle-fill me-1"></i>OVERSKREDET' : 
                    '<i class="bi bi-check-circle-fill me-1"></i>OK';
                
                weekendHeader.appendChild(weekendHeaderLeft);
                weekendHeader.appendChild(weekendStatus);
                
                // Weekend body with better formatted info
                const weekendBody = createElement('div', 'card-body');
                weekendBody.style.padding = '15px';
                
                // Budget indicators with improved visual hierarchy
                const weekendBudgetRow = createElement('div', 'row align-items-center mb-3');
                
                // Budget column
                const weekendBudgetCol = createElement('div', 'col-4');
                weekendBudgetCol.innerHTML = `
                    <div class="text-center">
                        <div class="text-muted small">Budsjett</div>
                        <div style="font-weight: 600; font-size: 1.1rem;">${formatCurrency(weekendBudgetPerPeriod)}</div>
                    </div>
                `;
                
                // Spent column
                const weekendSpentCol = createElement('div', 'col-4');
                weekendSpentCol.innerHTML = `
                    <div class="text-center">
                        <div class="text-muted small">Brukt</div>
                        <div style="font-weight: 600; font-size: 1.1rem; color: ${weekendTextColor};">${formatCurrency(weekendSpending)}</div>
                    </div>
                `;
                
                // Remaining/exceeded column
                const weekendRemainingCol = createElement('div', 'col-4');
                weekendRemainingCol.innerHTML = `
                    <div class="text-center">
                        <div class="text-muted small">${isExceededWeekend ? 'Overskredet' : 'Gjenstående'}</div>
                        <div style="font-weight: 600; font-size: 1.1rem; color: ${weekendTextColor};">
                            ${formatCurrency(isExceededWeekend ? 
                                weekendSpending - weekendBudgetPerPeriod : 
                                weekendBudgetPerPeriod - weekendSpending)}
                        </div>
                    </div>
                `;
                
                weekendBudgetRow.appendChild(weekendBudgetCol);
                weekendBudgetRow.appendChild(weekendSpentCol);
                weekendBudgetRow.appendChild(weekendRemainingCol);
                
                // Enhanced progress bar - fixed symmetry and clipping
                const weekendProgressWrapper = createElement('div', 'progress-wrapper mt-2');
                weekendProgressWrapper.style.position = 'relative';
                weekendProgressWrapper.style.height = '26px';
                weekendProgressWrapper.style.maxWidth = '100%';
                weekendProgressWrapper.style.overflow = 'hidden';
                
                const weekendProgressContainer = createElement('div', 'progress');
                weekendProgressContainer.style.height = '100%';
                weekendProgressContainer.style.borderRadius = '30px';
                weekendProgressContainer.style.backgroundColor = '#f0f0f0';
                weekendProgressContainer.style.overflow = 'hidden';
                
                const weekendProgressBar = createElement('div', 'progress-bar');
                weekendProgressBar.style.width = `${percentageWeekend}%`;
                weekendProgressBar.style.background = weekendColorGradient;
                if (isExceededWeekend) {
                    weekendProgressBar.classList.add('progress-bar-striped', 'progress-bar-animated');
                }
                
                // Progress percentage label
                const weekendProgressLabel = createElement('div', 'progress-label');
                weekendProgressLabel.style.position = 'absolute';
                weekendProgressLabel.style.top = '0';
                weekendProgressLabel.style.left = '0';
                weekendProgressLabel.style.right = '0';
                weekendProgressLabel.style.bottom = '0';
                weekendProgressLabel.style.display = 'flex';
                weekendProgressLabel.style.alignItems = 'center';
                weekendProgressLabel.style.justifyContent = 'center';
                weekendProgressLabel.style.color = 'white';
                weekendProgressLabel.style.fontWeight = '600';
                weekendProgressLabel.style.textShadow = '0 1px 2px rgba(0,0,0,0.2)';
                weekendProgressLabel.innerHTML = `${Math.round(percentageWeekend)}% brukt`;
                
                weekendProgressContainer.appendChild(weekendProgressBar);
                weekendProgressWrapper.appendChild(weekendProgressContainer);
                weekendProgressWrapper.appendChild(weekendProgressLabel);
                
                weekendBody.appendChild(weekendBudgetRow);
                weekendBody.appendChild(weekendProgressWrapper);
                
                weekendCard.appendChild(weekendHeader);
                weekendCard.appendChild(weekendBody);
                
                // Add both cards to the container
                graph.appendChild(weekdayCard);
                graph.appendChild(weekendCard);
            }
            
        } else {
            // Create a more visually appealing empty state message - more compact for mobile
            const notCurrentMonthCard = createElement('div', 'card text-center');
            notCurrentMonthCard.style.borderRadius = isMobile ? '8px' : '12px';
            notCurrentMonthCard.style.border = '1px solid rgba(0,0,0,0.1)';
            notCurrentMonthCard.style.boxShadow = isMobile ? '0 2px 8px rgba(0,0,0,0.05)' : '0 4px 12px rgba(0,0,0,0.08)';
            
            const notCurrentMonthBody = createElement('div', 'card-body py-3');
            notCurrentMonthBody.innerHTML = `
                <div class="mb-2">
                    <i class="bi bi-calendar-x" style="font-size: ${isMobile ? '2rem' : '3rem'}; color: #d0d0d0;"></i>
                </div>
                <h5 style="font-size: ${isMobile ? '1rem' : '1.25rem'};">Gjeldende uke er ikke i denne måneden</h5>
                <p class="text-muted small">Vis en annen måned for å se gjeldende ukes budsjett, eller se budsjettsammendrag for hele måneden.</p>
            `;
            
            notCurrentMonthCard.appendChild(notCurrentMonthBody);
            graph.appendChild(notCurrentMonthCard);
        }
        
        // Add the budget graph to the card body
        graphBody.appendChild(graph);
        
        // Assemble the card
        graphContainer.appendChild(graphHeader);
        graphContainer.appendChild(graphBody);
        
        // Add resize handler for responsive behavior
        const resizeHandler = () => {
            const isMobileNow = window.innerWidth < 768;
            // If screen size changed between mobile/desktop, re-render the graph
            if ((isMobileNow && !graphContainer.classList.contains('compact-mobile')) || 
                (!isMobileNow && graphContainer.classList.contains('compact-mobile'))) {
                const updatedGraph = this.renderBudgetSplitGraph();
                // Replace the current graph in the DOM
                graphContainer.parentNode?.replaceChild(updatedGraph, graphContainer);
            }
        };
        
        // Add the resize event listener once
        const win = window as any; // Type assertion to allow indexed access
        if (!win.budgetGraphResizeListenerAdded) {
            window.addEventListener('resize', resizeHandler);
            win.budgetGraphResizeListenerAdded = true;
        }
        
        return graphContainer;
    }
    
    // Removed monthly summary modal since it's now part of the summary tab
    
    private getWeekDatesString(weekNumber: number, type: 'weekday' | 'weekend'): string {
        const daysInMonth = new Date(this.year, this.month, 0).getDate();
        const firstDayOfMonth = new Date(this.year, this.month - 1, 1);
        const lastDayOfMonth = new Date(this.year, this.month - 1, daysInMonth);
        
        // Get the day of week for the first day (0 = Sunday, 1-6 = Monday-Saturday)
        const firstDayOfWeek = firstDayOfMonth.getDay();
        
        // Calculate the first day of the requested calendar week 
        // (not the work period, but the calendar week starting with Monday)
        let firstMondayOffset = firstDayOfWeek === 0 ? 1 : firstDayOfWeek === 1 ? 0 : -(firstDayOfWeek - 1);
        let daysToAdd = (weekNumber - 1) * 7 + firstMondayOffset;
        
        // Calculate the Monday of the specified week
        const weekMonday = new Date(this.year, this.month - 1, 1 + daysToAdd);
        
        // Now determine period start and end dates based on the type
        let periodStart: Date, periodEnd: Date;
        
        if (type === 'weekday') {
            // Weekday period is Monday-Thursday
            periodStart = new Date(weekMonday);
            periodEnd = new Date(weekMonday);
            periodEnd.setDate(weekMonday.getDate() + 3); // Monday + 3 = Thursday
        } else {
            // Weekend period is Friday-Sunday
            periodStart = new Date(weekMonday);
            periodStart.setDate(weekMonday.getDate() + 4); // Monday + 4 = Friday
            periodEnd = new Date(weekMonday);
            periodEnd.setDate(weekMonday.getDate() + 6); // Monday + 6 = Sunday
        }
        
        // Create formatter that shows month and day
        const formatter = new Intl.DateTimeFormat('en', { month: 'short', day: 'numeric' });
        
        // Only adjust if period dates fall outside the current month
        if (periodStart.getMonth() !== firstDayOfMonth.getMonth() || 
            periodStart.getFullYear() !== firstDayOfMonth.getFullYear()) {
            return "Utenfor måneden"; 
        }
        
        if (periodEnd.getMonth() !== firstDayOfMonth.getMonth() || 
            periodEnd.getFullYear() !== firstDayOfMonth.getFullYear()) {
            // End date is in next month, use last day of current month
            periodEnd = lastDayOfMonth;
        }
        
        // Format the date range
        return `${formatter.format(periodStart)} - ${formatter.format(periodEnd)}`;
    }

    private render() {
        this.container.innerHTML = '';
        
        // Get screen width to adjust for mobile
        const isMobile = window.innerWidth < 768;
        
        // Add compact-mobile class for mobile styling
        if (isMobile) {
            this.container.classList.add('compact-mobile');
        } else {
            this.container.classList.remove('compact-mobile');
        }
        
        // Department header with mobile-friendly styling
        const departmentHeader = createElement('div', 'mb-3 d-flex align-items-center justify-content-between flex-wrap');
        
        // Create heading first - will be 100% width on mobile
        const departmentHeading = createElement('h3', 'm-0 d-flex align-items-center');
        departmentHeading.style.fontWeight = '600';
        departmentHeading.style.fontSize = isMobile ? '1.5rem' : '1.75rem';
        
        // Determine which icon to use based on department name
        let deptIcon = 'building';
        const iconMap: {[key: string]: string} = {
            'Salg': 'cash',
            'Marked': 'megaphone',
            'HR': 'people',
            'Utvikling': 'code-slash',
            'IT': 'laptop',
            'Finans': 'cash-coin',
            'Admin': 'gear'
        };
        
        for (const [keyword, iconName] of Object.entries(iconMap)) {
            if (this.department.name.toLowerCase().includes(keyword.toLowerCase())) {
                deptIcon = iconName;
                break;
            }
        }
        
        // Stylish department title with icon badge - smaller on mobile
        const iconBadge = createElement('div', 'me-3 rounded-circle d-flex align-items-center justify-content-center');
        iconBadge.style.width = isMobile ? '36px' : '48px';
        iconBadge.style.height = isMobile ? '36px' : '48px';
        iconBadge.style.background = 'linear-gradient(135deg, #6a11cb 0%, #2575fc 100%)';
        iconBadge.style.color = 'white';
        iconBadge.innerHTML = `<i class="bi bi-${deptIcon}" style="font-size: ${isMobile ? '1.25rem' : '1.5rem'};"></i>`;
        
        departmentHeading.appendChild(iconBadge);
        departmentHeading.appendChild(document.createTextNode(this.department.name));
        
        // Badges container - will be full width and have margin top on mobile
        const badgesGroup = createElement('div', `d-flex gap-2 ${isMobile ? 'w-100 mt-2 justify-content-between' : ''}`);
        
        // Add user count badge - smaller on mobile
        const userCountBadge = createElement('div', 'badge bg-light text-dark d-flex align-items-center');
        userCountBadge.style.padding = isMobile ? '5px 10px' : '8px 12px';
        userCountBadge.style.borderRadius = '20px';
        userCountBadge.style.boxShadow = '0 2px 5px rgba(0,0,0,0.1)';
        userCountBadge.style.fontSize = isMobile ? '0.8rem' : '0.9rem';
        userCountBadge.innerHTML = `<i class="bi bi-people-fill me-2"></i>${this.department.userCount} personer`;
        
        // Add month badge - smaller on mobile
        const monthBadge = createElement('div', 'badge d-flex align-items-center');
        monthBadge.style.padding = isMobile ? '5px 10px' : '8px 12px';
        monthBadge.style.borderRadius = '20px';
        monthBadge.style.background = 'linear-gradient(135deg, #11998e 0%, #38ef7d 100%)';
        monthBadge.style.color = 'white';
        monthBadge.style.boxShadow = '0 2px 5px rgba(0,0,0,0.1)';
        monthBadge.style.fontSize = isMobile ? '0.8rem' : '0.9rem';
        monthBadge.innerHTML = `<i class="bi bi-calendar-event-fill me-2"></i>${this.monthNames[this.month - 1]} ${this.year}`;
        
        badgesGroup.appendChild(userCountBadge);
        badgesGroup.appendChild(monthBadge);
        
        departmentHeader.appendChild(departmentHeading);
        departmentHeader.appendChild(badgesGroup);
        this.container.appendChild(departmentHeader);

        // Add the budget split graph with enhanced styling
        const budgetSplitGraph = this.renderBudgetSplitGraph();
        this.container.appendChild(budgetSplitGraph);

        // Receipts list section with mobile-friendly styling
        const listSection = createElement('div', 'card mt-3 shadow-sm');
        listSection.style.borderRadius = isMobile ? '8px' : '12px';
        listSection.style.border = 'none';
        listSection.style.overflow = 'hidden';
        
        // Enhanced header with gradient, with reduced padding on mobile
        const listHeader = createElement('div', 'card-header d-flex justify-content-between align-items-center');
        listHeader.style.background = 'linear-gradient(135deg, #11998e 0%, #38ef7d 100%)';
        listHeader.style.color = 'white';
        listHeader.style.padding = isMobile ? '12px 15px' : '16px 20px';
        
        // List title with icon - smaller on mobile
        const listTitle = createElement('h4', 'm-0 d-flex align-items-center');
        listTitle.style.fontSize = isMobile ? '1.2rem' : '1.5rem';
        listTitle.innerHTML = `<i class="bi bi-receipt me-2"></i>Kvitteringer`;
        
        // Add receipt button - more compact on mobile
        const addButton = createElement('button', 'btn btn-light');
        addButton.style.borderRadius = '20px';
        addButton.style.padding = isMobile ? '6px 12px' : '8px 16px';
        addButton.style.fontWeight = '500';
        addButton.style.boxShadow = '0 2px 5px rgba(0,0,0,0.1)';
        addButton.style.fontSize = isMobile ? '0.85rem' : '1rem';
        addButton.innerHTML = isMobile ? 
            '<i class="bi bi-plus-circle"></i>' : 
            '<i class="bi bi-plus-circle me-2"></i>Legg til kvittering';
        addButton.title = 'Legg til kvittering';
        addButton.addEventListener('click', this.onAddReceipt);
        
        listHeader.appendChild(listTitle);
        listHeader.appendChild(addButton);
        
        const listBody = createElement('div', 'card-body p-0'); // Remove padding for table to extend to edges
        
        if (this.receipts.length === 0) {
            // Enhanced empty state with illustration, more compact on mobile
            const emptyState = createElement('div', 'text-center py-4');
            emptyState.innerHTML = `
                <div class="mb-2">
                    <i class="bi bi-receipt-cutoff" style="font-size: ${isMobile ? '2.5rem' : '3.5rem'}; color: #e0e0e0;"></i>
                </div>
                <h5>Ingen kvitteringer funnet</h5>
                <p class="text-muted small">Legg til en kvittering ved å bruke + knappen ovenfor.</p>
                <button class="btn btn-primary btn-sm mt-2">
                    <i class="bi bi-plus-circle me-2"></i>Legg til din første kvittering
                </button>
            `;
            
            // Add event listener for the empty state button
            setTimeout(() => {
                const emptyStateBtn = emptyState.querySelector('button');
                if (emptyStateBtn) {
                    emptyStateBtn.addEventListener('click', this.onAddReceipt);
                }
            }, 0);
            
            listBody.appendChild(emptyState);
        } else {
            // Sort receipts by date (newest first)
            const sortedReceipts = [...this.receipts].sort((a, b) => 
                new Date(b.date).getTime() - new Date(a.date).getTime()
            );
            
            if (isMobile) {
                // On mobile, use cards instead of a table for better UX
                const cardsContainer = createElement('div', 'p-2');
                
                sortedReceipts.forEach((receipt, index) => {
                    const date = new Date(receipt.date);
                    const dayOfWeek = date.getDay(); // 0 = Sunday, 6 = Saturday
                    const dayOfMonth = date.getDate();
                    const isWeekend = dayOfWeek === 0 || dayOfWeek === 5 || dayOfWeek === 6; // Friday, Saturday or Sunday
                    
                    // Get ISO week number instead of week of month
                    const weekNumber = this.getISOWeek(date);
                    
                    // Format date nicely
                    const dateOptions = { weekday: 'short', day: 'numeric', month: 'short' } as Intl.DateTimeFormatOptions;
                    const formattedDate = date.toLocaleDateString('nb-NO', dateOptions);
                    
                    // Create card for receipt
                    const card = createElement('div', 'card mb-2');
                    card.style.borderRadius = '8px';
                    card.style.border = '1px solid rgba(0,0,0,0.1)';
                    card.style.overflow = 'hidden';
                    
                    // Card header with date and amount
                    const cardHeader = createElement('div', 'd-flex justify-content-between align-items-center p-2 bg-light');
                    cardHeader.style.borderBottom = '1px solid rgba(0,0,0,0.05)';
                    
                    const dateDisplay = createElement('div', 'd-flex align-items-center');
                    dateDisplay.innerHTML = `
                        <i class="bi bi-calendar3 me-2 text-muted"></i>
                        <span>${formattedDate}</span>
                    `;
                    
                    const amountDisplay = createElement('div', 'fw-bold text-primary');
                    amountDisplay.textContent = formatCurrency(receipt.amount);
                    
                    cardHeader.appendChild(dateDisplay);
                    cardHeader.appendChild(amountDisplay);
                    
                    // Card body
                    const cardBody = createElement('div', 'p-2');
                    
                    // Badge row
                    const badgeRow = createElement('div', 'd-flex gap-2 mb-2');
                    
                    // Period badge
                    const periodBadge = createElement('span', 'badge rounded-pill');
                    periodBadge.style.background = isWeekend ? 
                        'linear-gradient(135deg, #0052D4, #4364F7, #6FB1FC)' : 
                        'linear-gradient(135deg, #4b6cb7, #182848)';
                    periodBadge.style.fontSize = '10px';
                    periodBadge.style.padding = '0.35em 0.65em';
                    periodBadge.textContent = isWeekend ? '3-dagers' : '4-dagers';
                    
                    // Week badge
                    const weekBadge = createElement('span', 'badge rounded-pill');
                    weekBadge.style.background = 'linear-gradient(135deg, #232526, #414345)';
                    weekBadge.style.fontSize = '10px';
                    weekBadge.style.padding = '0.35em 0.65em';
                    weekBadge.textContent = `Uke ${weekNumber}`;
                    
                    badgeRow.appendChild(periodBadge);
                    badgeRow.appendChild(weekBadge);
                    
                    // Bilagsnummer (previously "Invoice ID")
                    const invoiceRow = createElement('div', 'mb-1');
                    invoiceRow.innerHTML = `
                        <small class="text-muted">Bilagsnummer:</small>
                        <span class="ms-1" style="font-family: monospace;">${receipt.invoiceId}</span>
                    `;
                    
                    // Description (if any)
                    const descriptionRow = createElement('div', 'mb-2');
                    descriptionRow.innerHTML = `
                        <small class="text-muted">Beskrivelse:</small>
                        <span class="ms-1">${receipt.description || '<em class="text-muted">Ingen</em>'}</span>
                    `;
                    
                    // Action buttons - only show if hideActions is false
                    const actionRow = createElement('div', 'd-flex justify-content-end gap-2 mt-2');
                    
                    if (!this.hideActions) {
                        const editBtn = createElement('button', 'btn btn-sm btn-outline-primary');
                        editBtn.innerHTML = '<i class="bi bi-pencil-fill"></i>';
                        editBtn.style.borderRadius = '50%';
                        editBtn.style.width = '32px';
                        editBtn.style.height = '32px';
                        editBtn.style.padding = '0';
                        editBtn.style.display = 'flex';
                        editBtn.style.alignItems = 'center';
                        editBtn.style.justifyContent = 'center';
                        editBtn.addEventListener('click', () => this.onReceiptSelect(receipt.id));
                        
                        const deleteBtn = createElement('button', 'btn btn-sm btn-outline-danger');
                        deleteBtn.innerHTML = '<i class="bi bi-trash-fill"></i>';
                        deleteBtn.style.borderRadius = '50%';
                        deleteBtn.style.width = '32px';
                        deleteBtn.style.height = '32px';
                        deleteBtn.style.padding = '0';
                        deleteBtn.style.display = 'flex';
                        deleteBtn.style.alignItems = 'center';
                        deleteBtn.style.justifyContent = 'center';
                        deleteBtn.addEventListener('click', () => {
                            if (confirm(`Er du sikker på at du vil slette denne kvitteringen (Bilagsnummer: ${receipt.invoiceId})?`)) {
                                this.onReceiptDelete(receipt.id);
                            }
                        });
                        
                        actionRow.appendChild(editBtn);
                        actionRow.appendChild(deleteBtn);
                    }
                    
                    // Assemble card body
                    cardBody.appendChild(badgeRow);
                    cardBody.appendChild(invoiceRow);
                    cardBody.appendChild(descriptionRow);
                    cardBody.appendChild(actionRow);
                    
                    // Assemble card
                    card.appendChild(cardHeader);
                    card.appendChild(cardBody);
                    
                    cardsContainer.appendChild(card);
                });
                
                listBody.appendChild(cardsContainer);
            } else {
                // On desktop, use a table with responsive features
                const tableWrapper = createElement('div', 'table-responsive');
                const table = createElement('table', 'table table-hover mb-0');
                table.style.borderCollapse = 'separate';
                table.style.borderSpacing = '0';
                
                // Enhanced table header
                const tableHeader = createElement('thead', '');
                tableHeader.style.background = 'rgba(0,0,0,0.02)';
                tableHeader.innerHTML = `
                    <tr>
                        <th class="px-4 py-3" style="font-weight: 600;">Dato</th>
                        <th class="py-3" style="font-weight: 600;">Periode</th>
                        <th class="py-3" style="font-weight: 600;">Uke</th>
                        <th class="py-3" style="font-weight: 600;">Bilagsnummer</th>
                        <th class="py-3" style="font-weight: 600;">Beløp</th>
                        <th class="py-3" style="font-weight: 600;">Beskrivelse</th>
                        ${!this.hideActions ? 
                          `<th class="text-end pe-4 py-3" style="font-weight: 600;">Handlinger</th>` : 
                          ''}
                    </tr>
                `;
                
                const tableBody = createElement('tbody', '');
                
                // Generate modern table rows
                sortedReceipts.forEach((receipt, index) => {
                    const date = new Date(receipt.date);
                    const dayOfWeek = date.getDay(); // 0 = Sunday, 6 = Saturday
                    const dayOfMonth = date.getDate();
                    const isWeekend = dayOfWeek === 0 || dayOfWeek === 5 || dayOfWeek === 6; // Friday, Saturday or Sunday
                    
                    // Get ISO week number instead of week of month
                    const weekNumber = this.getISOWeek(date);
                    
                    // Create the row with subtle zebra striping
                    const row = document.createElement('tr');
                    row.style.backgroundColor = index % 2 === 0 ? 'white' : 'rgba(0,0,0,0.015)';
                    row.style.transition = 'background-color 0.15s ease';
                    
                    // Apply hover style directly
                    row.addEventListener('mouseover', () => {
                        row.style.backgroundColor = 'rgba(37, 117, 252, 0.05)';
                    });
                    
                    row.addEventListener('mouseout', () => {
                        row.style.backgroundColor = index % 2 === 0 ? 'white' : 'rgba(0,0,0,0.015)';
                    });
                    
                    // Formatted date with day of week
                    const dateOptions = { weekday: 'short', day: 'numeric', month: 'short' } as Intl.DateTimeFormatOptions;
                    const formattedDate = date.toLocaleDateString('nb-NO', dateOptions);
                    
                    // Format the row cells with better visual hierarchy
                    row.innerHTML = `
                        <td class="px-4 py-3">${formattedDate}</td>
                        <td class="py-3">
                            <span class="badge rounded-pill px-3 py-2" 
                            style="background: ${isWeekend ? 'linear-gradient(135deg, #0052D4, #4364F7, #6FB1FC)' : 'linear-gradient(135deg, #4b6cb7, #182848)'};
                            font-weight: 500; font-size: 11px;">
                                ${isWeekend ? '3-dagers' : '4-dagers'}
                            </span>
                        </td>
                        <td class="py-3">
                            <span class="badge rounded-pill px-3 py-2" 
                            style="background: linear-gradient(135deg, #232526, #414345); 
                            font-weight: 500; font-size: 11px;">
                                Uke ${weekNumber}
                            </span>
                        </td>
                        <td class="py-3" style="font-family: monospace;">${receipt.invoiceId}</td>
                        <td class="py-3" style="font-weight: 600; color: #2575fc;">${formatCurrency(receipt.amount)}</td>
                        <td class="py-3">${receipt.description || '<em class="text-muted">Ingen beskrivelse</em>'}</td>
                        <td class="text-end pe-4 py-3">
                            ${!this.hideActions ? `
                            <div class="btn-group">
                                <button type="button" class="btn btn-sm edit-btn px-3" 
                                style="border-radius: 30px 0 0 30px; border: 1px solid #2575fc; background: white; color: #2575fc;">
                                    <i class="bi bi-pencil-fill me-1"></i> Rediger
                                </button>
                                <button type="button" class="btn btn-sm delete-btn px-3" 
                                style="border-radius: 0 30px 30px 0; border: 1px solid #dc3545; background: white; color: #dc3545;">
                                    <i class="bi bi-trash-fill me-1"></i> Slett
                                </button>
                            </div>
                            ` : '<em class="text-muted small">Ingen handlinger</em>'}
                        </td>
                    `;
                    
                    // Add event listeners (only when not hiding actions)
                    if (!this.hideActions) {
                        setTimeout(() => {
                            const editBtn = row.querySelector('.edit-btn');
                            if (editBtn) {
                                editBtn.addEventListener('mouseover', () => {
                                    (editBtn as HTMLElement).style.background = 'rgba(37, 117, 252, 0.1)';
                                });
                                
                                editBtn.addEventListener('mouseout', () => {
                                    (editBtn as HTMLElement).style.background = 'white';
                                });
                                
                                editBtn.addEventListener('click', () => this.onReceiptSelect(receipt.id));
                            }
                            
                            const deleteBtn = row.querySelector('.delete-btn');
                            if (deleteBtn) {
                                deleteBtn.addEventListener('mouseover', () => {
                                    (deleteBtn as HTMLElement).style.background = 'rgba(220, 53, 69, 0.1)';
                                });
                                
                                deleteBtn.addEventListener('mouseout', () => {
                                    (deleteBtn as HTMLElement).style.background = 'white';
                                });
                                
                                deleteBtn.addEventListener('click', () => {
                                    if (confirm(`Er du sikker på at du vil slette denne kvitteringen (Bilagsnummer: ${receipt.invoiceId})?`)) {
                                        this.onReceiptDelete(receipt.id);
                                    }
                                });
                            }
                        }, 0);
                    }
                    
                    tableBody.appendChild(row);
                });
                
                // Assemble the table
                table.appendChild(tableHeader);
                table.appendChild(tableBody);
                tableWrapper.appendChild(table);
                listBody.appendChild(tableWrapper);
            }
            
            // Add a subtle footer with summary info - same layout for mobile and desktop
            const listFooter = createElement('div', 'card-footer d-flex justify-content-between align-items-center py-2');
            listFooter.style.background = 'rgba(0,0,0,0.02)';
            listFooter.style.borderTop = '1px solid rgba(0,0,0,0.05)';
            
            // Total count and amount
            listFooter.innerHTML = `
                <div>
                    <span class="text-muted small">Totalt ${this.receipts.length} kvittering${this.receipts.length !== 1 ? 'er' : ''}</span>
                </div>
                <div>
                    <span class="text-muted small">Sum: <strong style="color: #2575fc;">${formatCurrency(this.totalSpent)}</strong></span>
                </div>
            `;
            
            listSection.appendChild(listFooter);
        }
        
        listSection.appendChild(listHeader);
        listSection.appendChild(listBody);
        
        this.container.appendChild(listSection);
        
        // Add resize listener to handle responsive changes
        const resizeHandler = () => {
            const isMobileNow = window.innerWidth < 768;
            // If screen size changed between mobile/desktop, re-render
            if ((isMobileNow && !this.container.classList.contains('compact-mobile')) || 
                (!isMobileNow && this.container.classList.contains('compact-mobile'))) {
                this.render();
            }
        };
        
        // Add the resize event listener once
        const win = window as any; // Type assertion to allow indexed access
        if (!win.receiptListResizeListenerAdded) {
            window.addEventListener('resize', resizeHandler);
            win.receiptListResizeListenerAdded = true;
        }
    }
}