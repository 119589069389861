import { Department } from '../types';
import { createElement } from '../utils/helpers';

export default class DepartmentForm {
    private container: HTMLElement;
    private department: Department | null;
    private onSave: (department: Department) => void;
    private onCancel: () => void;

    constructor(
        container: HTMLElement,
        department: Department | null,
        onSave: (department: Department) => void,
        onCancel: () => void
    ) {
        this.container = container;
        this.department = department;
        this.onSave = onSave;
        this.onCancel = onCancel;
        this.render();
    }

    update(department: Department | null) {
        this.department = department;
        this.render();
    }

    private calculateBudget(userCount: number, budgetPerUser: number): number {
        return userCount * budgetPerUser;
    }

    private render() {
        this.container.innerHTML = '';
        
        const isNew = !this.department?.id;
        
        // Minimalistic form container
        const formCard = createElement('div', '');
        
        // Simple header
        const cardHeader = createElement('div', 'mb-4');
        
        // Add header title 
        const headerTitle = createElement('h4', 'm-0');
        headerTitle.textContent = isNew ? 'Legg til avdeling' : 'Rediger avdeling';
        cardHeader.appendChild(headerTitle);
        
        // Simple form body
        const cardBody = createElement('div', '');
        
        // Create form element
        const formElement = createElement('form', 'needs-validation');
        (formElement as HTMLFormElement).noValidate = true;
        
        // Simple name field group
        const nameGroup = createElement('div', 'mb-3 form-group');
        
        // Simple label
        const nameLabel = createElement('label', 'form-label mb-1');
        nameLabel.setAttribute('for', 'department-name');
        nameLabel.textContent = 'Avdelingsnavn';
        
        // Simple input
        const nameInput = createElement('input', 'form-control');
        nameInput.setAttribute('type', 'text');
        nameInput.setAttribute('id', 'department-name');
        nameInput.setAttribute('placeholder', 'F.eks. Salg, IT, HR, Marked');
        nameInput.setAttribute('required', 'true');
        if (this.department) {
            (nameInput as HTMLInputElement).value = this.department.name;
        }
        
        nameGroup.appendChild(nameLabel);
        nameGroup.appendChild(nameInput);
        
        // Simple budget fields group
        const budgetFieldsGroup = createElement('div', 'mb-3');
        
        // Budget title
        const budgetTitle = createElement('h6', 'mb-3');
        budgetTitle.textContent = 'Budsjettinformasjon';
        budgetFieldsGroup.appendChild(budgetTitle);
        
        // Create a 2-column layout for user count and budget per user
        const budgetRow = createElement('div', 'row g-2 mb-3');
        
        // User count column - simplified
        const userCountCol = createElement('div', 'col-md-6');
        const userCountGroup = createElement('div', 'form-group');
        
        const userCountLabel = createElement('label', 'form-label mb-1');
        userCountLabel.setAttribute('for', 'user-count');
        userCountLabel.textContent = 'Antall brukere';
        
        const userCountInput = createElement('input', 'form-control');
        userCountInput.setAttribute('type', 'number');
        userCountInput.setAttribute('id', 'user-count');
        userCountInput.setAttribute('min', '0');
        userCountInput.setAttribute('required', 'true');
        if (this.department) {
            (userCountInput as HTMLInputElement).value = this.department.userCount.toString();
        }
        
        userCountGroup.appendChild(userCountLabel);
        userCountGroup.appendChild(userCountInput);
        userCountCol.appendChild(userCountGroup);
        
        // Budget per user column - simplified
        const budgetPerUserCol = createElement('div', 'col-md-6');
        const budgetPerUserGroup = createElement('div', 'form-group');
        
        const budgetPerUserLabel = createElement('label', 'form-label mb-1');
        budgetPerUserLabel.setAttribute('for', 'budget-per-user');
        budgetPerUserLabel.textContent = 'Budsjett per bruker (NOK)';
        
        const budgetPerUserInput = createElement('input', 'form-control');
        budgetPerUserInput.setAttribute('type', 'number');
        budgetPerUserInput.setAttribute('id', 'budget-per-user');
        budgetPerUserInput.setAttribute('min', '0');
        budgetPerUserInput.setAttribute('step', '1'); // Whole numbers for NOK
        budgetPerUserInput.setAttribute('required', 'true');
        if (this.department) {
            (budgetPerUserInput as HTMLInputElement).value = this.department.budgetPerUser.toString();
        }
        
        budgetPerUserGroup.appendChild(budgetPerUserLabel);
        budgetPerUserGroup.appendChild(budgetPerUserInput);
        budgetPerUserCol.appendChild(budgetPerUserGroup);
        
        budgetRow.appendChild(userCountCol);
        budgetRow.appendChild(budgetPerUserCol);
        budgetFieldsGroup.appendChild(budgetRow);
        
        // Total budget display - simplified
        const totalBudgetGroup = createElement('div', 'mb-4');
        
        // Simple label
        const totalBudgetLabel = createElement('label', 'form-label mb-1');
        totalBudgetLabel.setAttribute('for', 'department-budget');
        totalBudgetLabel.textContent = 'Totalt budsjett (NOK)';
        
        // Simple display field
        const totalBudgetDisplay = createElement('div', 'form-control-plaintext');
        totalBudgetDisplay.id = 'department-budget';
        totalBudgetDisplay.style.fontWeight = '500';
        if (this.department) {
            totalBudgetDisplay.textContent = this.department.budget.toString();
        }
        
        totalBudgetGroup.appendChild(totalBudgetLabel);
        totalBudgetGroup.appendChild(totalBudgetDisplay);
        
        budgetFieldsGroup.appendChild(totalBudgetGroup);
        
        // Button group - minimalistic
        const buttonGroup = createElement('div', 'd-flex gap-2 mt-4');
        
        // Save button - minimalistic
        const saveButton = createElement('button', 'btn btn-primary');
        saveButton.setAttribute('type', 'submit');
        saveButton.textContent = 'Lagre';
        
        // Cancel button - minimalistic
        const cancelButton = createElement('button', 'btn btn-light');
        cancelButton.setAttribute('type', 'button');
        cancelButton.textContent = 'Avbryt';
        
        cancelButton.addEventListener('click', () => {
            this.onCancel();
        });
        
        buttonGroup.appendChild(saveButton);
        buttonGroup.appendChild(cancelButton);
        
        // Update total budget calculation when inputs change - simplified
        const updateTotalBudget = () => {
            const userCount = parseInt((userCountInput as HTMLInputElement).value) || 0;
            const budgetPerUser = parseFloat((budgetPerUserInput as HTMLInputElement).value) || 0;
            const totalBudget = this.calculateBudget(userCount, budgetPerUser);
            
            // Format with thousand separators
            const formattedBudget = new Intl.NumberFormat('nb-NO').format(Math.round(totalBudget));
            totalBudgetDisplay.textContent = formattedBudget;
        };
        
        // Force number formatting without decimals for budget per user in NOK
        (budgetPerUserInput as HTMLElement).addEventListener('change', () => {
            // Round to whole numbers for NOK
            if ((budgetPerUserInput as HTMLInputElement).value) {
                const value = Math.round(parseFloat((budgetPerUserInput as HTMLInputElement).value));
                if (!isNaN(value)) {
                    (budgetPerUserInput as HTMLInputElement).value = value.toString();
                }
            }
            updateTotalBudget();
        });
        
        (userCountInput as HTMLElement).addEventListener('input', updateTotalBudget);
        (budgetPerUserInput as HTMLElement).addEventListener('input', updateTotalBudget);
        
        // Form submission
        formElement.addEventListener('submit', (e) => {
            e.preventDefault();
            
            // Enhanced validation with feedback
            if (!(nameInput as HTMLInputElement).value.trim()) {
                (nameInput as HTMLElement).classList.add('is-invalid');
                // Create validation message
                const feedback = createElement('div', 'invalid-feedback');
                feedback.textContent = 'Avdelingsnavn er påkrevd';
                nameGroup.appendChild(feedback);
                return;
            }
            
            const userCount = parseInt((userCountInput as HTMLInputElement).value);
            if (isNaN(userCount) || userCount < 0) {
                (userCountInput as HTMLElement).classList.add('is-invalid');
                const feedback = createElement('div', 'invalid-feedback');
                feedback.textContent = 'Antall brukere må være et gyldig ikke-negativt heltall';
                userCountGroup.appendChild(feedback);
                return;
            }
            
            const budgetPerUser = parseFloat((budgetPerUserInput as HTMLInputElement).value);
            if (isNaN(budgetPerUser) || budgetPerUser < 0) {
                (budgetPerUserInput as HTMLElement).classList.add('is-invalid');
                const feedback = createElement('div', 'invalid-feedback');
                feedback.textContent = 'Budsjett per bruker må være et gyldig ikke-negativt tall';
                budgetPerUserGroup.appendChild(feedback);
                return;
            }
            
            const totalBudget = this.calculateBudget(userCount, budgetPerUser);
            
            const updatedDepartment: Department = {
                id: this.department?.id || crypto.randomUUID(),
                name: (nameInput as HTMLInputElement).value.trim(),
                budget: totalBudget,
                userCount,
                budgetPerUser
            };
            
            this.onSave(updatedDepartment);
        });
        
        // Assemble form
        formElement.appendChild(nameGroup);
        formElement.appendChild(budgetFieldsGroup);
        formElement.appendChild(buttonGroup);
        
        // Add form to card body
        cardBody.appendChild(formElement);
        
        // Assemble card
        formCard.appendChild(cardHeader);
        formCard.appendChild(cardBody);
        
        this.container.appendChild(formCard);
        
        // Initialize the total budget calculation
        updateTotalBudget();
        
        // Focus on the name input when form is displayed
        setTimeout(() => {
            (nameInput as HTMLElement).focus();
        }, 0);
    }
}