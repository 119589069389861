import './styles.css';
import { Department, AppState, Receipt } from './types';
import DepartmentList from './components/DepartmentList';
import DepartmentForm from './components/DepartmentForm';
import BudgetView from './components/BudgetView';
import databaseService from './services/database';
import { showNotification, createElement } from './utils/helpers';
import { logEnvironment } from './utils/config';

class App {
    private state: AppState = {
        departments: [],
        selectedDepartment: null,
        receipts: [],
        selectedReceipt: null,
        activeTab: 'departments',
        selectedMonth: parseInt(localStorage.getItem('selectedMonth') || '') || new Date().getMonth() + 1, // From localStorage or current month (1-12)
        selectedYear: parseInt(localStorage.getItem('selectedYear') || '') || new Date().getFullYear()    // From localStorage or current year
    };
    private appContainer: HTMLElement;
    private headerContainer: HTMLElement;
    private navContainer!: HTMLElement; // Non-null assertion for TypeScript
    private contentContainer!: HTMLElement;
    
    // Departments tab elements
    private departmentsContainer!: HTMLElement;
    private deptListContainer!: HTMLElement;
    private deptFormContainer!: HTMLElement;
    private departmentList: DepartmentList | null = null;
    private departmentForm: DepartmentForm | null = null;
    
    // Budget tab elements
    private budgetContainer: HTMLElement;
    private budgetView: BudgetView | null = null;

    // New properties for the side menu and content sections
    private sideMenu: HTMLElement;
    private mainContent: HTMLElement;
    private menuToggleBtn: HTMLElement;
    private overlay: HTMLElement;

    // Content sections
    private summaryContainer: HTMLElement;  // Now used for budget
    private settingsContainer: HTMLElement;
    private helpContainer: HTMLElement;

    constructor() {
        // Create main structure with side menu
        this.appContainer = document.getElementById('app') as HTMLElement;
        
        // Clear the app container and add the app-container class
        this.appContainer.innerHTML = '';
        this.appContainer.className = 'app-container compact-mobile';
        
        // Create side menu
        this.sideMenu = createElement('div', 'side-menu');
        this.createSideMenu();
        
        // Create overlay for mobile
        this.overlay = createElement('div', 'overlay');
        this.overlay.addEventListener('click', () => this.toggleMenu());
        
        // Create menu toggle button
        this.menuToggleBtn = createElement('button', 'menu-toggle btn btn-dark');
        this.menuToggleBtn.innerHTML = '<i class="bi bi-list fs-5"></i>';
        this.menuToggleBtn.addEventListener('click', () => this.toggleMenu());
        
        // Create main content area
        this.mainContent = createElement('div', 'main-content container-fluid py-3');
        
        // Create header for main content
        this.headerContainer = createElement('header', 'mb-4 d-flex justify-content-between align-items-center');
        const titleContainer = createElement('div', '');
        const title = createElement('h1', 'mb-1', 'Avdelingsadministrasjon');
        const description = createElement('p', 'lead mb-0', 'Administrer avdelinger og budsjetter i norske kroner (NOK)');
        titleContainer.appendChild(title);
        titleContainer.appendChild(description);
        this.headerContainer.appendChild(titleContainer);
        
        // Content container
        this.contentContainer = createElement('div', 'content-container');
        
        // Create all content sections
        
        // Departments tab container (modified)
        this.departmentsContainer = createElement('div', 'content-section row');
        this.createDepartmentsSection();
        
        // Budget tab container - now used as Dashboard
        this.budgetContainer = createElement('div', 'content-section');
        
        // Summary section - now used as Budget
        this.summaryContainer = createElement('div', 'content-section');
        this.createSummarySection();
        
        // Settings section 
        this.settingsContainer = createElement('div', 'content-section');
        this.createSettingsSection();
        
        // Help section 
        this.helpContainer = createElement('div', 'content-section');
        this.createHelpSection();
        
        // Add content sections to content container
        this.contentContainer.appendChild(this.departmentsContainer);
        this.contentContainer.appendChild(this.budgetContainer);
        this.contentContainer.appendChild(this.summaryContainer);
        this.contentContainer.appendChild(this.settingsContainer);
        this.contentContainer.appendChild(this.helpContainer);
        
        // Add everything to main content
        this.mainContent.appendChild(this.headerContainer);
        this.mainContent.appendChild(this.contentContainer);
        
        // Add all major components to app container
        this.appContainer.appendChild(this.sideMenu);
        this.appContainer.appendChild(this.mainContent);
        this.appContainer.appendChild(this.menuToggleBtn);
        this.appContainer.appendChild(this.overlay);
        
        // For mobile devices - initialize in collapsed state
        if (window.innerWidth <= 768) {
            this.sideMenu.classList.add('side-menu-collapsed');
        }
        
        // Initialize the application
        this.init().then(() => {
            // Check if we have a saved section in localStorage after init completes
            const savedSection = localStorage.getItem('currentSection');
            
            // Show the saved section or default to budget (dashboard)
            if (savedSection && ['departments', 'budget', 'summary', 'settings', 'help'].includes(savedSection)) {
                this.showSection(savedSection).then(() => {
                    this.setActiveMenuLink(savedSection);
                });
            } else {
                // Default to dashboard if no saved section
                this.showSection('budget').then(() => {
                    this.setActiveMenuLink('budget');
                });
            }
        });
    }
    
    private toggleMenu(): void {
        if (window.innerWidth <= 768) {
            // Mobile behavior
            this.sideMenu.classList.toggle('side-menu-expanded');
            this.overlay.classList.toggle('overlay-visible');
        } else {
            // Desktop behavior
            this.sideMenu.classList.toggle('side-menu-collapsed');
            this.mainContent.classList.toggle('main-content-expanded');
        }
    }
    
    private createSideMenu(): void {
        // Menu header
        const menuHeader = createElement('div', 'side-menu-header');
        const menuTitle = createElement('h5', 'm-0', 'Basil App');
        menuHeader.appendChild(menuTitle);
        
        // Menu content
        const menuContent = createElement('div', 'side-menu-content');
        const menuNav = createElement('nav', 'side-menu-nav');
        
        // Menu links with Bootstrap Icons
        const dashboardLink = createElement('a', 'side-menu-link'); // renamed from budgetLink
        dashboardLink.href = '#';
        dashboardLink.innerHTML = '<i class="bi bi-speedometer2"></i> Dashboard'; // changed icon and name
        dashboardLink.dataset.section = 'budget'; // keeping same section ID
        
        const budgetLink = createElement('a', 'side-menu-link'); // renamed from summaryLink
        budgetLink.href = '#';
        budgetLink.innerHTML = '<i class="bi bi-wallet2"></i> Budsjett'; // changed icon and name
        budgetLink.dataset.section = 'summary'; // keeping same section ID
        
        const settingsLink = createElement('a', 'side-menu-link');
        settingsLink.href = '#';
        settingsLink.innerHTML = '<i class="bi bi-gear"></i> Innstillinger';
        settingsLink.dataset.section = 'settings';
        
        const helpLink = createElement('a', 'side-menu-link');
        helpLink.href = '#';
        helpLink.innerHTML = '<i class="bi bi-question-circle"></i> Hjelp';
        helpLink.dataset.section = 'help';
        
        // Add event listeners to all links
        [dashboardLink, budgetLink, settingsLink, helpLink].forEach(link => {
            link.addEventListener('click', async (e) => {
                e.preventDefault();
                const section = link.dataset.section;
                if (section) {
                    await this.showSection(section);
                    this.setActiveMenuLink(section);
                    
                    // Save current section to localStorage for page reload persistence
                    localStorage.setItem('currentSection', section);
                    
                    // On mobile, close the menu after selection
                    if (window.innerWidth <= 768) {
                        this.toggleMenu();
                    }
                }
            });
        });
        
        // Add links to nav - Department tab now above Settings
        menuNav.appendChild(dashboardLink);
        menuNav.appendChild(budgetLink);
        menuNav.appendChild(settingsLink);
        menuNav.appendChild(helpLink);
        
        // Menu footer
        const menuFooter = createElement('div', 'side-menu-footer');
        menuFooter.textContent = 'Versjon 2.0.0';
        
        // Assemble menu
        menuContent.appendChild(menuNav);
        menuContent.appendChild(menuFooter);
        
        this.sideMenu.appendChild(menuHeader);
        this.sideMenu.appendChild(menuContent);
    }
    
    private async showSection(sectionName: string): Promise<void> {
        // For the budget section (summary), require password
        if (sectionName === 'summary') {
            // Check if we've already verified the admin during this session
            const isAdminVerified = sessionStorage.getItem('adminVerified') === 'true';
            
            if (!isAdminVerified) {
                // Import the password prompt function
                const { showPasswordPrompt } = await import('./utils/helpers');
                
                // Show password prompt
                const isAuthenticated = await showPasswordPrompt();
                
                if (!isAuthenticated) {
                    // If not authenticated, show a notification and return to previous section
                    const { showNotification } = await import('./utils/helpers');
                    showNotification('Manglende admin-tilgang for budsjett-funksjonen', 'warning');
                    
                    // Reset active menu link to current section
                    const currentSection = localStorage.getItem('currentSection') || 'budget';
                    this.setActiveMenuLink(currentSection);
                    return;
                }
                
                // Store admin verification for the session
                sessionStorage.setItem('adminVerified', 'true');
            }
        }
        
        // Hide all sections
        const allSections = this.contentContainer.querySelectorAll('.content-section');
        allSections.forEach(section => {
            section.classList.remove('active');
        });
        
        // Toggle minimalistic mode based on section
        if (sectionName === 'departments') {
            // Add minimalistic mode for departments
            document.body.classList.add('minimalistic-mode');
        } else {
            // Remove minimalistic mode for other sections
            document.body.classList.remove('minimalistic-mode');
        }
        
        // Show the requested section
        // If 'departments' is requested, redirect to 'budget'
        if (sectionName === 'departments') {
            sectionName = 'budget';
        }
        
        switch (sectionName) {
            case 'budget': // Now used as Dashboard
                this.budgetContainer.classList.add('active');
                await this.initBudgetView(); // Initialize budget view if needed - wait for it
                break;
            case 'summary': // Now used as Budget
                this.summaryContainer.classList.add('active');
                await this.updateSummaryView(); // Update summary view with latest data
                break;
            case 'settings':
                this.settingsContainer.classList.add('active');
                break;
            case 'help':
                this.helpContainer.classList.add('active');
                break;
            default:
                this.budgetContainer.classList.add('active'); // Default to Dashboard
        }
        
        // Update the state
        if (sectionName === 'departments' || sectionName === 'budget' || sectionName === 'summary') {
            this.state.activeTab = sectionName;
            localStorage.setItem('activeTab', sectionName);
        }
        
        // Always save the current section to localStorage
        localStorage.setItem('currentSection', sectionName);
        
        // Update page title to reflect current section
        const sectionTitles = {
            departments: 'Avdelinger',
            budget: 'Dashboard', // Renamed from Budget to Dashboard
            summary: 'Budsjett', // Renamed from Budsjettsammendrag to Budsjett
            settings: 'Innstillinger',
            help: 'Hjelp'
        };
        
        // Update header text
        const titleElement = this.headerContainer.querySelector('h1');
        if (titleElement) {
            titleElement.textContent = sectionTitles[sectionName as keyof typeof sectionTitles] || 'Avdelingsadministrasjon';
        }
    }
    
    private setActiveMenuLink(sectionName: string): void {
        // Remove active class from all links
        const allLinks = this.sideMenu.querySelectorAll('.side-menu-link');
        allLinks.forEach(link => {
            link.classList.remove('active');
        });
        
        // Add active class to the selected link
        const activeLink = this.sideMenu.querySelector(`.side-menu-link[data-section="${sectionName}"]`);
        if (activeLink) {
            activeLink.classList.add('active');
        }
    }
    
    // Dashboard section has been removed
    
    private createDepartmentsSection(): void {
        // Use a more minimalistic layout
        const leftCol = createElement('div', 'col-md-7 mb-4');
        const listHeader = createElement('div', 'd-flex justify-content-between align-items-center mb-3');
        const listTitle = createElement('h2', '', 'Avdelinger');
        
        // Remove the redundant add button from the header since it's now in the DepartmentList component
        listHeader.appendChild(listTitle);
        leftCol.appendChild(listHeader);
        
        // Remove the help section for a more minimalistic look
        
        this.deptListContainer = createElement('div');
        leftCol.appendChild(this.deptListContainer);
        
        // Right column for form
        const rightCol = createElement('div', 'col-md-5 mb-4');
        this.deptFormContainer = createElement('div');
        rightCol.appendChild(this.deptFormContainer);
        
        // Add columns to departments container
        this.departmentsContainer.appendChild(leftCol);
        this.departmentsContainer.appendChild(rightCol);
    }
    
    private createSettingsSection(): void {
        const settingsTitle = createElement('h2', 'mb-4', 'Innstillinger');
        
        // Admin Settings
        const adminSettings = createElement('div', 'settings-section mb-5');
        const adminTitle = createElement('h3', 'mb-3', 'Administrator');
        
        // Admin password reset section
        const passwordSection = createElement('div', 'card mb-4');
        const passwordHeader = createElement('div', 'card-header bg-light');
        passwordHeader.innerHTML = '<h5 class="mb-0">Endre admin-passord</h5>';
        
        const passwordBody = createElement('div', 'card-body');
        
        // Create password reset form
        const passwordForm = createElement('form');
        passwordForm.id = 'admin-password-form';
        
        // Current password
        const currentPassGroup = createElement('div', 'mb-3');
        const currentPassLabel = createElement('label', 'form-label', 'Nåværende passord:');
        currentPassLabel.setAttribute('for', 'current-password');
        
        const currentPassInput = createElement('input', 'form-control') as HTMLInputElement;
        currentPassInput.type = 'password';
        currentPassInput.id = 'current-password';
        currentPassInput.placeholder = 'Skriv inn nåværende passord';
        currentPassInput.required = true;
        
        currentPassGroup.appendChild(currentPassLabel);
        currentPassGroup.appendChild(currentPassInput);
        
        // New password
        const newPassGroup = createElement('div', 'mb-3');
        const newPassLabel = createElement('label', 'form-label', 'Nytt passord:');
        newPassLabel.setAttribute('for', 'new-password');
        
        const newPassInput = createElement('input', 'form-control') as HTMLInputElement;
        newPassInput.type = 'password';
        newPassInput.id = 'new-password';
        newPassInput.placeholder = 'Skriv inn nytt passord';
        newPassInput.required = true;
        
        newPassGroup.appendChild(newPassLabel);
        newPassGroup.appendChild(newPassInput);
        
        // Confirm new password
        const confirmPassGroup = createElement('div', 'mb-3');
        const confirmPassLabel = createElement('label', 'form-label', 'Bekreft nytt passord:');
        confirmPassLabel.setAttribute('for', 'confirm-password');
        
        const confirmPassInput = createElement('input', 'form-control') as HTMLInputElement;
        confirmPassInput.type = 'password';
        confirmPassInput.id = 'confirm-password';
        confirmPassInput.placeholder = 'Skriv inn nytt passord igjen';
        confirmPassInput.required = true;
        
        confirmPassGroup.appendChild(confirmPassLabel);
        confirmPassGroup.appendChild(confirmPassInput);
        
        // Submit button
        const submitBtn = createElement('button', 'btn btn-primary', 'Oppdater passord');
        submitBtn.type = 'submit';
        
        // Form note
        const formNote = createElement('div', 'form-text text-muted mt-3');
        formNote.innerHTML = 'Standard admin-passord er konfigurert i systemet. Kontakt systemadministrator om du har glemt passordet.';
        
        // Add form elements
        passwordForm.appendChild(currentPassGroup);
        passwordForm.appendChild(newPassGroup);
        passwordForm.appendChild(confirmPassGroup);
        passwordForm.appendChild(submitBtn);
        passwordForm.appendChild(formNote);
        
        // Add form handling
        passwordForm.addEventListener('submit', async (e) => {
            e.preventDefault();
            
            // Get the password values
            const currentPass = currentPassInput.value;
            const newPass = newPassInput.value;
            const confirmPass = confirmPassInput.value;
            
            // Import the verifyAdminPassword function
            const { verifyAdminPassword, showNotification } = await import('./utils/helpers');
            
            // Verify current password
            if (!verifyAdminPassword(currentPass)) {
                showNotification('Nåværende passord er ikke riktig', 'danger');
                return;
            }
            
            // Verify new passwords match
            if (newPass !== confirmPass) {
                showNotification('De nye passordene matcher ikke', 'danger');
                return;
            }
            
            // Verify new password meets requirements
            if (newPass.length < 8) {
                showNotification('Nytt passord må være minst 8 tegn', 'danger');
                return;
            }
            
            // In a real application, we would save the new password to the server
            // For this demo version, we'll just show a notification
            showNotification('Passordendring er ikke implementert i denne demo-versjonen', 'info');
            
            // Clear the form
            passwordForm.reset();
        });
        
        passwordBody.appendChild(passwordForm);
        passwordSection.appendChild(passwordHeader);
        passwordSection.appendChild(passwordBody);
        
        adminSettings.appendChild(adminTitle);
        adminSettings.appendChild(passwordSection);
        
        // Appearance settings
        const appearanceSettings = createElement('div', 'settings-section');
        const appearanceTitle = createElement('h3', 'mb-3', 'Utseende');
        
        const sideMenuToggle = createElement('div', 'form-check form-switch mb-3');
        sideMenuToggle.innerHTML = `
            <input class="form-check-input" type="checkbox" id="sidemenu-toggle">
            <label class="form-check-label" for="sidemenu-toggle">Vis sidemeny på mobil som standard</label>
        `;
        
        appearanceSettings.appendChild(appearanceTitle);
        appearanceSettings.appendChild(sideMenuToggle);
        
        // Add settings to container
        this.settingsContainer.appendChild(settingsTitle);
        this.settingsContainer.appendChild(adminSettings);
        this.settingsContainer.appendChild(appearanceSettings);
    }
    
    /**
     * Creates the budget section (formerly budget summary)
     */
    private createSummarySection(): void {
        const title = createElement('h2', 'mb-4', 'Budsjett');
        
        // Simplified heading, no explanatory text
        
        // Department and month/year selector - now enhanced with visual UI
        const filtersContainer = createElement('div', 'card mb-4');
        const filtersHeader = createElement('div', 'card-header d-flex justify-content-between align-items-center');
        
        const headerTitle = createElement('h5', 'mb-0');
        headerTitle.innerHTML = '<i class="bi bi-sliders me-2"></i>Velg avdeling og periode';
        
        // Add a button to apply settings to budget view
        const applyToBudgetBtn = createElement('button', 'btn btn-sm btn-outline-primary d-none');
        applyToBudgetBtn.id = 'apply-to-budget-btn';
        applyToBudgetBtn.innerHTML = '<i class="bi bi-check-circle me-1"></i>Bruk i budsjett';
        applyToBudgetBtn.addEventListener('click', () => {
            this.applySettingsToBudgetView();
        });
        
        filtersHeader.appendChild(headerTitle);
        filtersHeader.appendChild(applyToBudgetBtn);
        
        const filtersBody = createElement('div', 'card-body');
        
        // Department selector with management buttons
        const deptRow = createElement('div', 'mb-4');
        const deptHeaderRow = createElement('div', 'd-flex justify-content-between align-items-center mb-2');
        
        const selectLabel = createElement('label', 'form-label mb-0', 'Velg avdeling:');
        selectLabel.htmlFor = 'summary-department-selector';
        
        // Department management buttons
        const deptButtonGroup = createElement('div', 'btn-group');
        const addDeptBtn = createElement('button', 'btn btn-sm btn-primary');
        addDeptBtn.innerHTML = '<i class="bi bi-plus me-1"></i>Legg til';
        addDeptBtn.addEventListener('click', () => this.showAddDepartmentForm());
        
        // Edit button that will be enabled when a department is selected
        const editDeptBtn = createElement('button', 'btn btn-sm btn-outline-secondary disabled');
        editDeptBtn.innerHTML = '<i class="bi bi-pencil me-1"></i>Rediger';
        editDeptBtn.id = 'edit-department-btn';
        
        // Delete button that will be enabled when a department is selected
        const deleteDeptBtn = createElement('button', 'btn btn-sm btn-outline-danger disabled');
        deleteDeptBtn.innerHTML = '<i class="bi bi-trash me-1"></i>Slett';
        deleteDeptBtn.id = 'delete-department-btn';
        
        deptButtonGroup.appendChild(addDeptBtn);
        deptButtonGroup.appendChild(editDeptBtn);
        deptButtonGroup.appendChild(deleteDeptBtn);
        
        deptHeaderRow.appendChild(selectLabel);
        deptHeaderRow.appendChild(deptButtonGroup);
        deptRow.appendChild(deptHeaderRow);
        
        const select = createElement('select', 'form-select form-select-lg mb-3');
        select.id = 'summary-department-selector';
        
        // Placeholder option
        const placeholderOption = createElement('option', '');
        placeholderOption.textContent = 'Velg avdeling...';
        placeholderOption.selected = true;
        placeholderOption.disabled = true;
        select.appendChild(placeholderOption);
        
        // Event listener for department selection
        select.addEventListener('change', () => {
            const departmentId = select.value;
            if (departmentId) {
                this.updateDepartmentSummary(departmentId);
                // Show the apply to budget button when a department is selected
                const applyBtn = document.getElementById('apply-to-budget-btn');
                if (applyBtn) applyBtn.classList.remove('d-none');
                
                // Enable edit and delete buttons
                const editBtn = document.getElementById('edit-department-btn');
                const deleteBtn = document.getElementById('delete-department-btn');
                
                if (editBtn) {
                    editBtn.classList.remove('disabled');
                    editBtn.addEventListener('click', () => this.showEditDepartmentForm(departmentId));
                }
                
                if (deleteBtn) {
                    deleteBtn.classList.remove('disabled');
                    deleteBtn.addEventListener('click', () => {
                        if (confirm('Er du sikker på at du vil slette denne avdelingen?')) {
                            this.handleDepartmentDelete(departmentId);
                        }
                    });
                }
            } else {
                // Disable edit and delete buttons
                const editBtn = document.getElementById('edit-department-btn');
                const deleteBtn = document.getElementById('delete-department-btn');
                
                if (editBtn) editBtn.classList.add('disabled');
                if (deleteBtn) deleteBtn.classList.add('disabled');
            }
        });
        
        deptRow.appendChild(select);
        
        // Month and Year Selectors with Visual Enhancement
        const dateSelectionContainer = createElement('div', 'mb-4');
        const dateSelectionLabel = createElement('h6', 'mb-3', 'Velg periode:');
        dateSelectionContainer.appendChild(dateSelectionLabel);
        
        // Year selection as pill buttons
        const yearRow = createElement('div', 'mb-3');
        const yearLabel = createElement('div', 'mb-2 fw-bold', 'År:');
        const yearButtonGroup = createElement('div', 'd-flex flex-wrap gap-2');
        yearButtonGroup.id = 'summary-year-buttons';
        
        // Add options for current year and next year
        const currentYear = new Date().getFullYear();
        for (let year = Math.max(2025, currentYear); year <= currentYear + 1; year++) {
            const yearBtn = createElement('button', 
                `btn ${year === this.state.selectedYear ? 'btn-primary' : 'btn-outline-secondary'}`);
                
            yearBtn.dataset.year = year.toString();
            yearBtn.innerHTML = year.toString();
                
            yearBtn.addEventListener('click', () => {
                // Update active state
                const allYearBtns = yearButtonGroup.querySelectorAll('button');
                allYearBtns.forEach(btn => {
                    btn.classList.remove('btn-primary');
                    btn.classList.add('btn-outline-secondary');
                });
                
                yearBtn.classList.remove('btn-outline-secondary');
                yearBtn.classList.add('btn-primary');
                
                // Update the selected year in the state
                this.state.selectedYear = year;
                
                // Save selected year to localStorage
                localStorage.setItem('selectedYear', year.toString());
                
                // Update summary if department is selected
                // Bruker state i stedet for dropdown
                if (this.state.selectedDepartment) {
                    this.updateDepartmentSummary(this.state.selectedDepartment.id, undefined, year);
                }
            });
            
            yearButtonGroup.appendChild(yearBtn);
        }
        
        yearRow.appendChild(yearLabel);
        yearRow.appendChild(yearButtonGroup);
        dateSelectionContainer.appendChild(yearRow);
        
        // Month selection as visual calendar buttons
        const monthRow = createElement('div', 'mt-4');
        const monthLabel = createElement('div', 'mb-2 fw-bold', 'Måned:');
        const monthButtonGroup = createElement('div', 'row g-2');
        monthButtonGroup.id = 'summary-month-buttons';
        
        // Month names
        const monthNames = [
            'Januar', 'Februar', 'Mars', 'April', 'Mai', 'Juni',
            'Juli', 'August', 'September', 'Oktober', 'November', 'Desember'
        ];
        
        // Add options for each month
        monthNames.forEach((monthName, index) => {
            const monthNum = index + 1; // 1-based month index
            
            const monthCol = createElement('div', 'col-3');
            const monthBtn = createElement('button', 
                `btn w-100 ${monthNum === this.state.selectedMonth ? 'btn-primary' : 'btn-outline-secondary'}`);
            
            monthBtn.dataset.month = monthNum.toString();
            monthBtn.innerHTML = `<div>${monthName.substring(0, 3)}</div>`;
            
            monthBtn.addEventListener('click', () => {
                // Update active state
                const allMonthBtns = monthButtonGroup.querySelectorAll('button');
                allMonthBtns.forEach(btn => {
                    btn.classList.remove('btn-primary');
                    btn.classList.add('btn-outline-secondary');
                });
                
                monthBtn.classList.remove('btn-outline-secondary');
                monthBtn.classList.add('btn-primary');
                
                this.state.selectedMonth = monthNum;
                
                // Save selected month to localStorage
                localStorage.setItem('selectedMonth', monthNum.toString());
                
                // Update summary if department is selected - bruker state i stedet for dropdown
                if (this.state.selectedDepartment) {
                    this.updateDepartmentSummary(this.state.selectedDepartment.id, monthNum);
                }
            });
            
            monthCol.appendChild(monthBtn);
            monthButtonGroup.appendChild(monthCol);
        });
        
        monthRow.appendChild(monthLabel);
        monthRow.appendChild(monthButtonGroup);
        dateSelectionContainer.appendChild(monthRow);
        
        // Current selection info
        const currentSelectionInfo = createElement('div', 'alert alert-info mt-3 mb-0');
        currentSelectionInfo.innerHTML = `
            <div class="d-flex justify-content-between align-items-center">
                <div>
                    <strong>Nåværende valg:</strong> <span id="summary-current-period">${monthNames[this.state.selectedMonth - 1]} ${this.state.selectedYear}</span>
                </div>
            </div>
        `;
        dateSelectionContainer.appendChild(currentSelectionInfo);
        
        // User count adjustment section - moved from dashboard
        const userCountSettings = createElement('div', 'mt-4 pt-3 border-top');
        const userCountTitle = createElement('h6', 'mb-3', 'Justere brukerantall');
        
        const userCountDescription = createElement('p', 'mb-3', 
            'Juster brukerantall for den valgte måneden. Dette vil påvirke budsjettberegninger for denne perioden.'
        );
        
        // Container for user count controls - will be populated when a department is selected
        const userCountControlContainer = createElement('div', 'mb-0');
        userCountControlContainer.id = 'summary-user-count-container'; // Changed ID to avoid confusion with BudgetView
        userCountControlContainer.innerHTML = '<div class="alert alert-info">Velg en avdeling ovenfor for å justere brukerantall</div>';
        
        userCountSettings.appendChild(userCountTitle);
        userCountSettings.appendChild(userCountDescription);
        userCountSettings.appendChild(userCountControlContainer);
        
        dateSelectionContainer.appendChild(userCountSettings);
        
        // Department form container - initially hidden
        const deptFormContainer = createElement('div', 'mt-4 pt-3 border-top');
        deptFormContainer.id = 'summary-department-form-container';
        deptFormContainer.style.display = 'none';
        
        // Minimalistisk avdelingsoversikt - nå som hovedinnhold øverst
        const departmentOverviewSection = createElement('div', 'mb-4');
        const overviewTitle = createElement('div', 'd-flex justify-content-between align-items-center mb-3');
        
        const titleText = createElement('h5', 'mb-0');
        titleText.textContent = 'Avdelinger';
        
        // Legge til knapperad med refresh og add
        const buttonGroup = createElement('div', 'btn-group');
        
        // Add button for new department
        const addBtn = createElement('button', 'btn btn-sm btn-primary');
        addBtn.innerHTML = '<i class="bi bi-plus"></i> Legg til';
        addBtn.title = 'Legg til ny avdeling';
        addBtn.addEventListener('click', () => {
            this.showAddDepartmentForm();
        });
        
        // Refresh button for the overview
        const refreshBtn = createElement('button', 'btn btn-sm btn-outline-secondary');
        refreshBtn.innerHTML = '<i class="bi bi-arrow-clockwise"></i>';
        refreshBtn.title = 'Oppdater oversikt';
        refreshBtn.addEventListener('click', async () => {
            await this.loadDepartments();
            this.renderDepartmentOverview();
        });
        
        buttonGroup.appendChild(addBtn);
        buttonGroup.appendChild(refreshBtn);
        
        overviewTitle.appendChild(titleText);
        overviewTitle.appendChild(buttonGroup);
        
        // Container for the department list
        const departmentListContainer = createElement('div', 'department-overview-list');
        departmentListContainer.id = 'department-overview-list';
        
        departmentOverviewSection.appendChild(overviewTitle);
        departmentOverviewSection.appendChild(departmentListContainer);
        
        // Add everything to filters body - avdelingsoversikten først
        filtersBody.appendChild(departmentOverviewSection);
        filtersBody.appendChild(dateSelectionContainer);
        filtersBody.appendChild(deptFormContainer);
        
        // Fjerne deptRow (dropdown) siden vi bruker avdelingsoversikten i stedet
        // filtersBody.appendChild(deptRow);
        
        filtersContainer.appendChild(filtersHeader);
        filtersContainer.appendChild(filtersBody);
        
        // Main content area that will be populated dynamically
        const summaryContent = createElement('div', '');
        summaryContent.id = 'summary-content';
        
        // Add empty state initially
        const emptyState = createElement('div', 'alert alert-info');
        emptyState.textContent = 'Velg en avdeling for å se budsjettsammendrag.';
        summaryContent.appendChild(emptyState);
        
        // Add everything to the summary container
        this.summaryContainer.appendChild(title);
        this.summaryContainer.appendChild(filtersContainer);
        this.summaryContainer.appendChild(summaryContent);
    }
    
    /**
     * Renders the minimalistic department overview in the budget tab
     */
    private renderDepartmentOverview(): void {
        const container = document.getElementById('department-overview-list');
        if (!container) return;
        
        container.innerHTML = '';
        
        if (this.state.departments.length === 0) {
            const emptyState = createElement('div', 'text-center text-muted py-2');
            emptyState.innerHTML = 'Ingen avdelinger opprettet ennå.';
            container.appendChild(emptyState);
            return;
        }
        
        // Create a compact table for departments
        const table = createElement('table', 'table table-sm table-hover');
        table.style.fontSize = '0.875rem';
        
        // Simple header row
        const thead = createElement('thead');
        thead.innerHTML = `
            <tr>
                <th>Navn</th>
                <th class="text-center">Personer</th>
                <th class="text-end">Budsjett</th>
                <th class="text-end">Handling</th>
            </tr>
        `;
        
        // Sorted departments
        const sortedDepartments = [...this.state.departments].sort((a, b) => 
            a.name.localeCompare(b.name, 'nb', { sensitivity: 'base' }));
        
        // Create body with rows for each department
        const tbody = createElement('tbody');
        
        sortedDepartments.forEach(dept => {
            const row = createElement('tr');
            
            // Tydelig markering av valgt avdeling - bruker bare state
            const currentSelectedDeptId = this.state.selectedDepartment?.id;
            
            if (dept.id === currentSelectedDeptId) {
                row.classList.add('table-primary');
                row.style.fontWeight = 'bold';
            }
            
            // Make rows clickable to select the department
            row.style.cursor = 'pointer';
            row.addEventListener('click', () => {
                // Oppdater direkte uten å gå via dropdown
                this.state.selectedDepartment = dept;
                
                // Oppdater selectedDepartmentId i localStorage for kontinuitet
                localStorage.setItem('selectedDepartmentId', dept.id);
                
                // Oppdater UI - re-render oversikten for å markere valgt avdeling
                this.renderDepartmentOverview();
                
                // Oppdater budsjettsammendrag for valgt avdeling
                this.updateDepartmentSummary(dept.id);
                
                // Vis apply to budget-knappen
                const applyBtn = document.getElementById('apply-to-budget-btn');
                if (applyBtn) applyBtn.classList.remove('d-none');
            });
            
            // Department name
            const nameCell = createElement('td');
            nameCell.textContent = dept.name;
            
            // User count
            const countCell = createElement('td', 'text-center');
            countCell.textContent = dept.userCount.toString();
            
            // Budget amount (formatted)
            const budgetCell = createElement('td', 'text-end');
            budgetCell.textContent = new Intl.NumberFormat('nb-NO', { 
                style: 'currency', 
                currency: 'NOK',
                maximumFractionDigits: 0
            }).format(dept.budget);
            
            // Action buttons - minimal and compact
            const actionCell = createElement('td', 'text-end');
            
            const actionGroup = createElement('div', 'd-flex justify-content-end gap-1');
            
            // Edit button - just an icon
            const editBtn = createElement('button', 'btn btn-sm btn-link p-0 px-1');
            editBtn.innerHTML = '<i class="bi bi-pencil"></i>';
            editBtn.title = 'Rediger';
            editBtn.addEventListener('click', (e) => {
                e.stopPropagation(); // Don't trigger row click
                this.showEditDepartmentForm(dept.id);
            });
            
            // Delete button - just an icon
            const deleteBtn = createElement('button', 'btn btn-sm btn-link text-danger p-0 px-1');
            deleteBtn.innerHTML = '<i class="bi bi-trash"></i>';
            deleteBtn.title = 'Slett';
            deleteBtn.addEventListener('click', (e) => {
                e.stopPropagation(); // Don't trigger row click
                if (confirm(`Er du sikker på at du vil slette avdelingen ${dept.name}?`)) {
                    this.handleDepartmentDelete(dept.id);
                }
            });
            
            actionGroup.appendChild(editBtn);
            actionGroup.appendChild(deleteBtn);
            actionCell.appendChild(actionGroup);
            
            // Add cells to row
            row.appendChild(nameCell);
            row.appendChild(countCell);
            row.appendChild(budgetCell);
            row.appendChild(actionCell);
            
            // Add row to table
            tbody.appendChild(row);
        });
        
        table.appendChild(thead);
        table.appendChild(tbody);
        container.appendChild(table);
    }

    // Handle receipt selection/edit for summary view
    private async handleReceiptSelect(receiptId: string) {
        try {
            // Security check: Only allow editing when in the budget view (not dashboard)
            // This prevents someone from creating and triggering buttons via DOM manipulation
            if (this.state.activeTab !== 'summary') {
                console.warn('Attempt to edit receipt outside of budget view detected!');
                showNotification('Redigering av kvitteringer er bare tillatt i budsjett-visningen', 'warning');
                return false;
            }
            
            // Fetch the receipt
            const receipt = await databaseService.getReceipt(receiptId);
            if (!receipt) {
                throw new Error('Receipt not found');
            }
            
            // Find the department
            const department = this.state.departments.find(d => d.id === receipt.departmentId);
            if (!department) {
                throw new Error('Department not found');
            }

            // Update state
            this.state.selectedReceipt = receipt;
            this.state.selectedDepartment = department;
            
            // Switch to budget view to edit the receipt
            await this.showSection('budget');
            this.setActiveMenuLink('budget');
            
            // Initialize budget view with the receipt ID for editing
            await this.initBudgetView(receiptId);
            
            showNotification('Kvittering lastet for redigering', 'info');
            return true;
        } catch (error) {
            console.error('Error selecting receipt:', error);
            showNotification('Feil ved valg av kvittering', 'danger');
            return false;
        }
    }
    
    // Handle receipt deletion for summary view
    private async handleReceiptDelete(receiptId: string) {
        try {
            // Security check: Only allow deletion when in the budget view (not dashboard)
            // This prevents someone from creating and triggering buttons via DOM manipulation
            if (this.state.activeTab !== 'summary') {
                console.warn('Attempt to delete receipt outside of budget view detected!');
                showNotification('Sletting av kvitteringer er bare tillatt i budsjett-visningen', 'warning');
                return false;
            }
            
            // Delete the receipt
            await databaseService.deleteReceipt(receiptId);
            
            // Remove from state
            this.state.receipts = this.state.receipts.filter(r => r.id !== receiptId);
            
            // Update the view
            await this.updateSummaryView();
            
            showNotification('Kvittering slettet', 'success');
            return true;
        } catch (error) {
            console.error('Error deleting receipt:', error);
            showNotification('Feil ved sletting av kvittering', 'danger');
            return false;
        }
    }

    private async updateSummaryView(): Promise<void> {
        // Make sure departments are loaded
        if (this.state.departments.length === 0) {
            try {
                this.state.departments = await databaseService.getAllDepartments();
            } catch (error) {
                console.error('Error loading departments for summary view:', error);
            }
        }
        
        // Render the department overview
        this.renderDepartmentOverview();
        
        // Vi fjernet dropdown-en, så vi må håndtere valg annerledes
        // Vi beholder denne koden i tilfelle det finnes en departmentSelect på siden
        // men det er ikke lengre nødvendig å oppdatere den
        const departmentSelect = document.getElementById('summary-department-selector') as HTMLSelectElement;
        if (departmentSelect) {
            // Clear existing options (except the first placeholder)
            while (departmentSelect.options.length > 1) {
                departmentSelect.remove(1);
            }
        }
        
        // Legg til options i dropdown hvis den fortsatt finnes
        if (departmentSelect) {
            this.state.departments.forEach(dept => {
                const option = document.createElement('option');
                option.value = dept.id;
                option.textContent = dept.name;
                departmentSelect.appendChild(option);
            });
        }
        
        // Update month and year buttons to reflect current state
        this.updateDateSelectors();
        
        // Check if we have a saved department
        const savedDeptId = localStorage.getItem('selectedDepartmentId');
        if (savedDeptId && this.state.departments.some(d => d.id === savedDeptId)) {
            // Finn avdelingen i state
            const department = this.state.departments.find(d => d.id === savedDeptId);
            if (department) {
                this.state.selectedDepartment = department;
                
                // Oppdatere dropdown hvis den fortsatt finnes
                if (departmentSelect) {
                    departmentSelect.value = savedDeptId;
                    // Trigger the change event programmatically
                    const changeEvent = new Event('change');
                    departmentSelect.dispatchEvent(changeEvent);
                } else {
                    // Kall updateDepartmentSummary direkte siden vi ikke har dropdown
                    this.updateDepartmentSummary(savedDeptId);
                }
            }
        } else if (this.state.departments.length > 0) {
            // Hvis ingen lagret avdeling, velg den første
            const firstDept = this.state.departments[0];
            this.state.selectedDepartment = firstDept;
            
            // Kall updateDepartmentSummary direkte
            this.updateDepartmentSummary(firstDept.id);
        }
    }
    
    /**
     * Updates the date selectors (month and year buttons) based on the current state
     */
    private updateDateSelectors(): void {
        // Get the month and year buttons
        const monthButtons = document.querySelectorAll('#summary-month-buttons button');
        const yearButtons = document.querySelectorAll('#summary-year-buttons button');
        
        // Update month buttons
        monthButtons.forEach(btn => {
            const monthNum = parseInt((btn as HTMLElement).dataset.month || '0');
            if (monthNum === this.state.selectedMonth) {
                btn.classList.remove('btn-outline-secondary');
                btn.classList.add('btn-primary');
            } else {
                btn.classList.remove('btn-primary');
                btn.classList.add('btn-outline-secondary');
            }
        });
        
        // Update year buttons
        yearButtons.forEach(btn => {
            const year = parseInt((btn as HTMLElement).dataset.year || '0');
            if (year === this.state.selectedYear) {
                btn.classList.remove('btn-outline-secondary');
                btn.classList.add('btn-primary');
            } else {
                btn.classList.remove('btn-primary');
                btn.classList.add('btn-outline-secondary');
            }
        });
        
        // Update current period display
        const currentPeriodElement = document.getElementById('summary-current-period');
        if (currentPeriodElement) {
            const monthNames = [
                'Januar', 'Februar', 'Mars', 'April', 'Mai', 'Juni',
                'Juli', 'August', 'September', 'Oktober', 'November', 'Desember'
            ];
            currentPeriodElement.textContent = `${monthNames[this.state.selectedMonth - 1]} ${this.state.selectedYear}`;
        }
    }
    
    /**
     * Applies the current month and year selection to the budget view
     */
    private applySettingsToBudgetView(): void {
        // Save selected month and year to localStorage
        localStorage.setItem('selectedMonth', this.state.selectedMonth.toString());
        localStorage.setItem('selectedYear', this.state.selectedYear.toString());
        
        // Get selected department from state
        const departmentId = this.state.selectedDepartment?.id;
        
        // Save selected department to localStorage if one is selected
        if (departmentId) {
            localStorage.setItem('selectedDepartmentId', departmentId);
        }
        
        // Show success message
        showNotification('Periode oppdatert for budsjettvisning', 'success');
        
        // Navigate to budget view
        this.showSection('budget');
        this.setActiveMenuLink('budget');
        
        // Update the budget view with new settings
        if (this.budgetView) {
            // BudgetView reads the localStorage values on initialization/update
            this.budgetView.update(this.state.departments);
        }
    }
    
    /**
     * Updates the department summary section with data for the specified department
     */
    private async updateDepartmentSummary(departmentId: string, month?: number, year?: number): Promise<void> {
        // Get department data
        const department = await databaseService.getDepartment(departmentId);
        if (!department) {
            console.error(`Department not found: ${departmentId}`);
            
            // Get department summary container
            const summaryContent = document.getElementById('summary-content');
            if (summaryContent) {
                // Clear existing content
                summaryContent.innerHTML = '';
                
                // Show not found message
                const errorMessage = createElement('div', 'alert alert-warning');
                errorMessage.innerHTML = `
                    <i class="bi bi-exclamation-triangle-fill me-2"></i>
                    <strong>Avdeling ikke funnet</strong><br>
                    Den valgte avdelingen eksisterer ikke eller er slettet.
                `;
                summaryContent.appendChild(errorMessage);
            }
            
            return;
        }
        
        // Update the selected department in state
        this.state.selectedDepartment = department;
        
        // Use provided month/year, or use current state values
        const selectedMonth = month || this.state.selectedMonth;
        const selectedYear = year || this.state.selectedYear;
        
        // Update the state with the selected month/year
        this.state.selectedMonth = selectedMonth;
        this.state.selectedYear = selectedYear;
        
        // Update the date selectors to reflect the current selection
        this.updateDateSelectors();
        
        // Render the user count adjustment controls
        this.renderMonthlyUserCount(department, selectedMonth, selectedYear);
        
        // Get receipts for this department
        const allReceipts = await databaseService.getReceiptsByDepartment(departmentId);
        
        // Filter receipts by selected month and year
        const receipts = allReceipts.filter(receipt => {
            return receipt.month === selectedMonth && receipt.year === selectedYear;
        });
        
        // Get effective user count for the selected month
        const effectiveUserCount = this.getDepartmentUserCountForMonth(department, selectedMonth, selectedYear);
        
        // Calculate month-specific budget with the effective user count
        const monthlyBudget = effectiveUserCount * department.budgetPerUser;
        
        // Calculate totals for the month
        const totalSpent = receipts.reduce((sum, receipt) => sum + receipt.amount, 0);
        const remaining = monthlyBudget - totalSpent;
        const isOverBudget = remaining < 0;
        
        // Calculate totals for all time (for context)
        const totalAllTimeSpent = allReceipts.reduce((sum, receipt) => sum + receipt.amount, 0);
        
        // Get container
        const summaryContent = document.getElementById('summary-content');
        if (!summaryContent) return;
        
        // Clear existing content
        summaryContent.innerHTML = '';
        
        // Month names for display
        const monthNames = [
            'Januar', 'Februar', 'Mars', 'April', 'Mai', 'Juni',
            'Juli', 'August', 'September', 'Oktober', 'November', 'Desember'
        ];
        
        // Create main summary card
        const summaryCard = createElement('div', 'card mb-4');
        const cardHeader = createElement('div', 'card-header');
        cardHeader.innerHTML = `<h4 class="mb-0">Budsjettsammendrag: ${department.name} - ${monthNames[selectedMonth - 1]} ${selectedYear}</h4>`;
        
        const cardBody = createElement('div', 'card-body');
        
        // Top level budget overview
        const budgetOverview = createElement('div', 'row g-3 mb-4');
        budgetOverview.innerHTML = `
            <div class="col-md-4">
                <div class="card bg-light">
                    <div class="card-body text-center">
                        <h5 class="card-title">Månedsbudsjett</h5>
                        <p class="card-text display-5">${Intl.NumberFormat('nb-NO', { style: 'currency', currency: 'NOK' }).format(monthlyBudget)}</p>
                        <small>${effectiveUserCount} brukere × ${Intl.NumberFormat('nb-NO', { style: 'currency', currency: 'NOK' }).format(department.budgetPerUser)}</small>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card bg-light">
                    <div class="card-body text-center">
                        <h5 class="card-title">Totalt brukt</h5>
                        <p class="card-text display-5">${Intl.NumberFormat('nb-NO', { style: 'currency', currency: 'NOK' }).format(totalSpent)}</p>
                        <small>${receipts.length} kvitteringer denne måneden</small>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="card ${isOverBudget ? 'bg-danger text-white' : 'bg-light'}">
                    <div class="card-body text-center">
                        <h5 class="card-title">Gjenstående</h5>
                        <p class="card-text display-5">${Intl.NumberFormat('nb-NO', { style: 'currency', currency: 'NOK' }).format(remaining)}</p>
                        <small>${isOverBudget ? 'Budsjett overskredet' : 'Tilgjengelig budsjett'}</small>
                    </div>
                </div>
            </div>
        `;
        
        // Budget progress
        const progressSection = createElement('div', 'mb-4');
        const percentSpent = Math.min((totalSpent / monthlyBudget) * 100, 100);
        const progressClass = isOverBudget ? 'danger' : (percentSpent > 80 ? 'warning' : 'success');
        
        progressSection.innerHTML = `
            <h5 class="mb-3">Budsjettbruk for ${monthNames[selectedMonth - 1]} ${selectedYear}</h5>
            <div class="progress" style="height: 25px;">
                <div class="progress-bar bg-${progressClass}" role="progressbar" 
                    style="width: ${percentSpent}%" 
                    aria-valuenow="${percentSpent}" aria-valuemin="0" aria-valuemax="100">
                    ${Math.round(percentSpent)}%
                </div>
            </div>
        `;
        
        // Show warning if over budget
        if (isOverBudget) {
            const overBudgetWarning = createElement('div', 'alert alert-danger mt-3');
            overBudgetWarning.innerHTML = `
                <strong>Advarsel:</strong> Denne avdelingen har overskredet månedsbudsjettet med 
                ${Intl.NumberFormat('nb-NO', { style: 'currency', currency: 'NOK' }).format(Math.abs(remaining))}.
            `;
            progressSection.appendChild(overBudgetWarning);
        }
        
        // Budget details section
        const budgetDetails = createElement('div', 'card mb-4');
        const budgetDetailsHeader = createElement('div', 'card-header bg-light');
        budgetDetailsHeader.innerHTML = '<h5 class="mb-0">Månedlig budsjettsammendrag</h5>';
        
        const budgetDetailsBody = createElement('div', 'card-body');
        
        // Calculate number of days in selected month
        const daysInMonth = new Date(selectedYear, selectedMonth, 0).getDate();
        
        // Calculate budget per day and per period
        const dailyBudget = monthlyBudget / daysInMonth;
        
        // Budget for each work interval (4-day and 3-day periods)
        const weekdayBudget = dailyBudget * 4; // Budget for one 4-day period
        const weekendBudget = dailyBudget * 3; // Budget for one 3-day period
        
        // Calculate number of weeks in the month
        const firstDayOfMonth = new Date(selectedYear, selectedMonth - 1, 1);
        const firstDayOfWeek = firstDayOfMonth.getDay(); // 0 = Sunday, 1-6 = Monday-Saturday
        const weeksInMonth = Math.ceil((daysInMonth + (firstDayOfWeek === 0 ? 6 : firstDayOfWeek - 1)) / 7);
        
        // Calculate weekday and weekend spending
        const weekdaySpent = receipts.reduce((sum, receipt) => {
            const date = new Date(receipt.date);
            const dayOfWeek = date.getDay(); // 0 = Sunday, 1-5 = Monday-Friday, 6 = Saturday
            if (dayOfWeek >= 1 && dayOfWeek <= 4) { // Monday through Thursday
                return sum + receipt.amount;
            }
            return sum;
        }, 0);
        
        const weekendSpent = receipts.reduce((sum, receipt) => {
            const date = new Date(receipt.date);
            const dayOfWeek = date.getDay(); // 0 = Sunday, 1-5 = Monday-Friday, 6 = Saturday
            if (dayOfWeek >= 5 || dayOfWeek === 0) { // Friday, Saturday, or Sunday
                return sum + receipt.amount;
            }
            return sum;
        }, 0);
        
        // Calculate total budget for weekday and weekend periods
        const weekdayTotalBudget = weekdayBudget * weeksInMonth;
        const weekendTotalBudget = weekendBudget * weeksInMonth;
        
        // Group receipts by week for weekly overview
        const weeklyReceipts = this.groupReceiptsByWeek(receipts, selectedMonth, selectedYear);
        
        budgetDetailsBody.innerHTML = `
            <div class="alert alert-primary mb-4">
                <div>
                    <h5>Totalt månedsbudsjett: ${Intl.NumberFormat('nb-NO', { style: 'currency', currency: 'NOK' }).format(monthlyBudget)}</h5>
                </div>
                <div class="mt-2 row">
                    <div class="col-md-4">Budsjett per bruker: ${Intl.NumberFormat('nb-NO', { style: 'currency', currency: 'NOK' }).format(department.budgetPerUser)}</div>
                    <div class="col-md-4">Daglig per bruker: ~${Intl.NumberFormat('nb-NO', { style: 'currency', currency: 'NOK' }).format(dailyBudget / effectiveUserCount)}</div>
                    <div class="col-md-4">Totalt antall brukere: ${effectiveUserCount}</div>
                </div>
            </div>
            
            <div class="row mb-4">
                <div class="col-md-6">
                    <div class="alert alert-secondary">
                        <h6>4-dagers perioder (Man-Tor)</h6>
                        <p class="mb-1">Budsjett per periode: ${Intl.NumberFormat('nb-NO', { style: 'currency', currency: 'NOK' }).format(weekdayBudget)}</p>
                        <p class="mb-0">Per bruker: ${Intl.NumberFormat('nb-NO', { style: 'currency', currency: 'NOK' }).format(weekdayBudget / effectiveUserCount)}</p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="alert alert-secondary">
                        <h6>3-dagers perioder (Fre-Søn)</h6>
                        <p class="mb-1">Budsjett per periode: ${Intl.NumberFormat('nb-NO', { style: 'currency', currency: 'NOK' }).format(weekendBudget)}</p>
                        <p class="mb-0">Per bruker: ${Intl.NumberFormat('nb-NO', { style: 'currency', currency: 'NOK' }).format(weekendBudget / effectiveUserCount)}</p>
                    </div>
                </div>
            </div>
            
            <div class="card mb-4">
                <div class="card-header bg-light">
                    <h5 class="mb-0">Ukesoversikt for ${monthNames[selectedMonth - 1]} ${selectedYear}</h5>
                </div>
                <div class="card-body p-0">
                    <div class="table-responsive">
                        <table class="table table-sm table-hover mb-0">
                            <thead class="table-light">
                                <tr>
                                    <th>Uke</th>
                                    <th>Periode</th>
                                    <th>4-dagers (Man-Tor)</th>
                                    <th>3-dagers (Fre-Søn)</th>
                                    <th>Total</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                ${this.generateWeeklyBudgetRows(weeklyReceipts, weekdayBudget, weekendBudget)}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            
            <div class="card mb-4">
                <div class="card-header bg-light">
                    <h5 class="mb-0">Oppsummering periodetyper</h5>
                </div>
                <div class="card-body p-0">
                    <div class="table-responsive">
                        <table class="table table-sm table-bordered mb-0">
                            <thead class="table-light">
                                <tr>
                                    <th>Periodetype</th>
                                    <th>Totalt budsjett</th>
                                    <th>Totalt brukt</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>4-dagers perioder</td>
                                    <td>${Intl.NumberFormat('nb-NO', { style: 'currency', currency: 'NOK' }).format(weekdayTotalBudget)}</td>
                                    <td>${Intl.NumberFormat('nb-NO', { style: 'currency', currency: 'NOK' }).format(weekdaySpent)}</td>
                                    <td><span class="badge bg-${weekdaySpent > weekdayTotalBudget ? 'danger' : 'success'}">${weekdaySpent > weekdayTotalBudget ? 'Over budsjett' : 'Innenfor budsjett'}</span></td>
                                </tr>
                                <tr>
                                    <td>3-dagers perioder</td>
                                    <td>${Intl.NumberFormat('nb-NO', { style: 'currency', currency: 'NOK' }).format(weekendTotalBudget)}</td>
                                    <td>${Intl.NumberFormat('nb-NO', { style: 'currency', currency: 'NOK' }).format(weekendSpent)}</td>
                                    <td><span class="badge bg-${weekendSpent > weekendTotalBudget ? 'danger' : 'success'}">${weekendSpent > weekendTotalBudget ? 'Over budsjett' : 'Innenfor budsjett'}</span></td>
                                </tr>
                                <tr class="table-active">
                                    <td><strong>Totalt</strong></td>
                                    <td><strong>${Intl.NumberFormat('nb-NO', { style: 'currency', currency: 'NOK' }).format(monthlyBudget)}</strong></td>
                                    <td><strong>${Intl.NumberFormat('nb-NO', { style: 'currency', currency: 'NOK' }).format(totalSpent)}</strong></td>
                                    <td><span class="badge bg-${isOverBudget ? 'danger' : 'success'}">${isOverBudget ? 'Over budsjett' : 'Innenfor budsjett'}</span></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        `;
        
        budgetDetails.appendChild(budgetDetailsHeader);
        budgetDetails.appendChild(budgetDetailsBody);
        
        // Receipts section
        const receiptsSection = createElement('div', 'card mb-4');
        const receiptsHeader = createElement('div', 'card-header bg-light');
        receiptsHeader.innerHTML = `<h5 class="mb-0">Kvitteringer for ${monthNames[selectedMonth - 1]} ${selectedYear}</h5>`;
        
        const receiptsBody = createElement('div', 'card-body');
        
        if (receipts.length === 0) {
            const noReceiptsInfo = createElement('div', 'alert alert-info mb-0');
            noReceiptsInfo.textContent = 'Ingen kvitteringer registrert for denne måneden.';
            receiptsBody.appendChild(noReceiptsInfo);
        } else {
            // Sort receipts by date (newest first)
            const sortedReceipts = [...receipts].sort((a, b) => 
                new Date(b.date).getTime() - new Date(a.date).getTime()
            );
            
            // Create table
            const table = createElement('table', 'table table-striped table-hover');
            table.innerHTML = `
                <thead>
                    <tr>
                        <th>Dato</th>
                        <th>Bilagsnummer</th>
                        <th>Beløp</th>
                        <th>Beskrivelse</th>
                        <th class="text-end">Handlinger</th>
                    </tr>
                </thead>
                <tbody>
                </tbody>
            `;
            
            const tableBody = table.querySelector('tbody')!;
            
            // Add rows
            sortedReceipts.forEach(receipt => {
                const date = new Date(receipt.date);
                const row = document.createElement('tr');
                row.innerHTML = `
                    <td>${date.toLocaleDateString('nb-NO')}</td>
                    <td>${receipt.invoiceId}</td>
                    <td>${Intl.NumberFormat('nb-NO', { style: 'currency', currency: 'NOK' }).format(receipt.amount)}</td>
                    <td>${receipt.description || '<em>Ingen beskrivelse</em>'}</td>
                    <td class="text-end">
                        <div class="btn-group">
                            <button type="button" class="btn btn-sm btn-outline-primary edit-btn">
                                <i class="bi bi-pencil-fill"></i> Rediger
                            </button>
                            <button type="button" class="btn btn-sm btn-outline-danger delete-btn">
                                <i class="bi bi-trash-fill"></i> Slett
                            </button>
                        </div>
                    </td>
                `;
                
                // Add event listeners for edit and delete buttons
                setTimeout(() => {
                    const editBtn = row.querySelector('.edit-btn');
                    if (editBtn) {
                        editBtn.addEventListener('click', () => {
                            // Call the same function as the BudgetView
                            this.handleReceiptSelect(receipt.id);
                        });
                    }
                    
                    const deleteBtn = row.querySelector('.delete-btn');
                    if (deleteBtn) {
                        deleteBtn.addEventListener('click', () => {
                            if (confirm(`Er du sikker på at du vil slette denne kvitteringen (Bilagsnummer: ${receipt.invoiceId})?`)) {
                                // Call the same function as the BudgetView
                                this.handleReceiptDelete(receipt.id);
                            }
                        });
                    }
                }, 0);
                
                tableBody.appendChild(row);
            });
            
            receiptsBody.appendChild(table);
        }
        
        receiptsSection.appendChild(receiptsHeader);
        receiptsSection.appendChild(receiptsBody);
        
        // Action buttons
        const actionsSection = createElement('div', 'mb-0 d-flex justify-content-end');
        
        // View/edit budget button
        const viewBudgetBtn = createElement('button', 'btn btn-primary me-2');
        viewBudgetBtn.innerHTML = '<i class="bi bi-wallet2 me-1"></i> Gå til budsjett';
        viewBudgetBtn.addEventListener('click', () => {
            localStorage.setItem('selectedDepartmentId', departmentId);
            this.showSection('budget');
            this.setActiveMenuLink('budget');
        });
        
        // Edit department button
        const editDeptBtn = createElement('button', 'btn btn-secondary');
        editDeptBtn.innerHTML = '<i class="bi bi-pencil me-1"></i> Rediger avdeling';
        editDeptBtn.addEventListener('click', () => {
            this.showSection('departments');
            this.setActiveMenuLink('departments');
            this.handleDepartmentSelect(departmentId);
        });
        
        actionsSection.appendChild(viewBudgetBtn);
        actionsSection.appendChild(editDeptBtn);
        
        // Assemble all components
        cardBody.appendChild(budgetOverview);
        cardBody.appendChild(progressSection);
        cardBody.appendChild(budgetDetails);
        cardBody.appendChild(receiptsSection);
        cardBody.appendChild(actionsSection);
        
        summaryCard.appendChild(cardHeader);
        summaryCard.appendChild(cardBody);
        
        // Add to container
        summaryContent.appendChild(summaryCard);
    }
    
    /**
     * Gets the user count for a specific month and year
     * Uses the month-specific count if available, otherwise falls back to the department's default
     */
    private getDepartmentUserCountForMonth(department: Department, month: number, year: number): number {
        if (department.monthlyUserCounts && department.monthlyUserCounts.length > 0) {
            // Look for a month-specific count
            const monthlyCount = department.monthlyUserCounts.find(
                mc => mc.month === month && mc.year === year
            );
            
            if (monthlyCount) {
                return monthlyCount.userCount;
            }
        }
        
        // Fall back to default user count
        return department.userCount;
    }
    
    /**
     * Groups receipts by week for weekly budget overview
     */
    private groupReceiptsByWeek(receipts: Receipt[], month: number, year: number): { weekNumber: number, startDate: Date, endDate: Date, weekdayReceipts: Receipt[], weekendReceipts: Receipt[] }[] {
        // Define the first day of the month
        const firstDayOfMonth = new Date(year, month - 1, 1);
        
        // Get days in month
        const daysInMonth = new Date(year, month, 0).getDate();
        
        // Calculate the week number for the first day
        let firstWeekNumber = this.getISOWeek(firstDayOfMonth);
        
        // Create an array to hold week data
        const weeks: { 
            weekNumber: number, 
            startDate: Date, 
            endDate: Date,
            weekdayReceipts: Receipt[],
            weekendReceipts: Receipt[]
        }[] = [];
        
        // Initialize first week
        let currentDate = new Date(firstDayOfMonth);
        let currentWeekNumber = firstWeekNumber;
        let weekStartDate = new Date(currentDate);
        
        let currentWeek = {
            weekNumber: currentWeekNumber,
            startDate: new Date(weekStartDate),
            endDate: new Date(weekStartDate),
            weekdayReceipts: [] as Receipt[],
            weekendReceipts: [] as Receipt[]
        };
        
        // Loop through all days in the month
        for (let day = 1; day <= daysInMonth; day++) {
            currentDate = new Date(year, month - 1, day);
            const currentWeekDay = currentDate.getDay(); // 0 = Sunday, 1-6 = Monday-Saturday
            
            // Check if we're starting a new week (Monday or first day of month)
            if (day > 1 && currentWeekDay === 1) {
                // Save the previous week
                currentWeek.endDate = new Date(new Date(currentDate).setDate(currentDate.getDate() - 1));
                weeks.push(currentWeek);
                
                // Start a new week
                weekStartDate = new Date(currentDate);
                currentWeekNumber = this.getISOWeek(currentDate);
                
                currentWeek = {
                    weekNumber: currentWeekNumber,
                    startDate: new Date(weekStartDate),
                    endDate: new Date(weekStartDate), // Will be updated as we progress
                    weekdayReceipts: [] as Receipt[],
                    weekendReceipts: [] as Receipt[]
                };
            }
            
            // If it's the last day of the month, save the current week
            if (day === daysInMonth) {
                currentWeek.endDate = new Date(currentDate);
                weeks.push(currentWeek);
            }
        }
        
        // Now place each receipt in its corresponding week
        for (const receipt of receipts) {
            const receiptDate = new Date(receipt.date);
            const receiptDay = receiptDate.getDate();
            const receiptWeekDay = receiptDate.getDay(); // 0 = Sunday, 1-6 = Monday-Saturday
            
            // Find which week this receipt belongs to
            for (const week of weeks) {
                if (receiptDate >= week.startDate && receiptDate <= week.endDate) {
                    // Separate weekday (Mon-Thu) from weekend (Fri-Sun) receipts
                    if (receiptWeekDay >= 1 && receiptWeekDay <= 4) {
                        week.weekdayReceipts.push(receipt);
                    } else {
                        week.weekendReceipts.push(receipt);
                    }
                    break;
                }
            }
        }
        
        return weeks;
    }
    
    /**
     * Gets the ISO week number for a given date
     */
    private getISOWeek(date: Date): number {
        const d = new Date(date);
        d.setHours(0, 0, 0, 0);
        d.setDate(d.getDate() + 4 - (d.getDay() || 7));
        const yearStart = new Date(d.getFullYear(), 0, 1);
        return Math.ceil((((d.getTime() - yearStart.getTime()) / 86400000) + 1) / 7);
    }
    
    /**
     * Generates HTML table rows for the weekly budget overview
     */
    private generateWeeklyBudgetRows(
        weeklyReceipts: { weekNumber: number, startDate: Date, endDate: Date, weekdayReceipts: Receipt[], weekendReceipts: Receipt[] }[],
        weekdayBudget: number,
        weekendBudget: number
    ): string {
        if (weeklyReceipts.length === 0) {
            return `<tr><td colspan="6" class="text-center">Ingen data tilgjengelig for denne måneden</td></tr>`;
        }
        
        let rows = '';
        
        for (const week of weeklyReceipts) {
            // Calculate total spent for each period in this week
            const weekdayTotal = week.weekdayReceipts.reduce((sum, receipt) => sum + receipt.amount, 0);
            const weekendTotal = week.weekendReceipts.reduce((sum, receipt) => sum + receipt.amount, 0);
            const weekTotal = weekdayTotal + weekendTotal;
            
            // Format the date range
            const startDateFormatted = week.startDate.toLocaleDateString('nb-NO', { day: 'numeric', month: 'short' });
            const endDateFormatted = week.endDate.toLocaleDateString('nb-NO', { day: 'numeric', month: 'short' });
            
            // Determine status
            const weekdayClass = weekdayTotal > weekdayBudget ? 'danger' : 'success';
            const weekendClass = weekendTotal > weekendBudget ? 'danger' : 'success';
            const totalClass = weekTotal > (weekdayBudget + weekendBudget) ? 'danger' : 'success';
            
            // Calculate percentages
            const weekdayPercent = Math.round((weekdayTotal / weekdayBudget) * 100);
            const weekendPercent = Math.round((weekendTotal / weekendBudget) * 100);
            const totalPercent = Math.round((weekTotal / (weekdayBudget + weekendBudget)) * 100);
            
            // Create the table row
            rows += `
                <tr>
                    <td>Uke ${week.weekNumber}</td>
                    <td>${startDateFormatted} - ${endDateFormatted}</td>
                    <td>
                        <div class="d-flex justify-content-between align-items-center">
                            <span>${Intl.NumberFormat('nb-NO', { style: 'currency', currency: 'NOK' }).format(weekdayTotal)}</span>
                            <span class="badge bg-${weekdayClass} ms-2">${weekdayPercent}%</span>
                        </div>
                        <div class="progress mt-1" style="height: 4px;">
                            <div class="progress-bar bg-${weekdayClass}" role="progressbar" style="width: ${Math.min(weekdayPercent, 100)}%"></div>
                        </div>
                    </td>
                    <td>
                        <div class="d-flex justify-content-between align-items-center">
                            <span>${Intl.NumberFormat('nb-NO', { style: 'currency', currency: 'NOK' }).format(weekendTotal)}</span>
                            <span class="badge bg-${weekendClass} ms-2">${weekendPercent}%</span>
                        </div>
                        <div class="progress mt-1" style="height: 4px;">
                            <div class="progress-bar bg-${weekendClass}" role="progressbar" style="width: ${Math.min(weekendPercent, 100)}%"></div>
                        </div>
                    </td>
                    <td>
                        <div class="d-flex justify-content-between align-items-center">
                            <span>${Intl.NumberFormat('nb-NO', { style: 'currency', currency: 'NOK' }).format(weekTotal)}</span>
                            <span class="badge bg-${totalClass} ms-2">${totalPercent}%</span>
                        </div>
                        <div class="progress mt-1" style="height: 4px;">
                            <div class="progress-bar bg-${totalClass}" role="progressbar" style="width: ${Math.min(totalPercent, 100)}%"></div>
                        </div>
                    </td>
                    <td class="text-center">
                        <span class="badge bg-${totalClass}">
                            ${weekTotal > (weekdayBudget + weekendBudget) ? 'Over budsjett' : 'Innenfor budsjett'}
                        </span>
                    </td>
                </tr>
            `;
        }
        
        return rows;
    }
    
    /**
     * Updates the monthly user count for a department
     */
    private async updateMonthlyUserCount(
        department: Department, 
        month: number, 
        year: number, 
        userCount: number
    ): Promise<Department> {
        // Create a deep copy of the department
        const updatedDepartment = {...department};
        
        // Initialize monthlyUserCounts array if it doesn't exist
        if (!updatedDepartment.monthlyUserCounts) {
            updatedDepartment.monthlyUserCounts = [];
        } else {
            // Make a copy of the array
            updatedDepartment.monthlyUserCounts = [...updatedDepartment.monthlyUserCounts];
        }
        
        // Check if we already have an entry for this month
        const existingIndex = updatedDepartment.monthlyUserCounts.findIndex(
            mc => mc.month === month && mc.year === year
        );
        
        if (existingIndex >= 0) {
            // Update existing entry
            updatedDepartment.monthlyUserCounts[existingIndex] = {
                ...updatedDepartment.monthlyUserCounts[existingIndex],
                userCount
            };
        } else {
            // Add new entry
            updatedDepartment.monthlyUserCounts.push({
                month,
                year,
                userCount
            });
        }
        
        // Save updated department
        await databaseService.updateDepartment(updatedDepartment);
        
        // Update our local department data
        const departmentIndex = this.state.departments.findIndex(d => d.id === department.id);
        if (departmentIndex >= 0) {
            this.state.departments[departmentIndex] = updatedDepartment;
        }
        
        return updatedDepartment;
    }
    
    /**
     * Renders the user count adjustment control for the current month
     */
    private renderMonthlyUserCount(department: Department, selectedMonth: number, selectedYear: number) {
        // This container should be in the budget section itself
        const container = document.getElementById('summary-user-count-container');
        if (!container) {
            console.error('User count container not found in the DOM');
            return;
        }
        
        // Get current user count for this month
        const currentUserCount = this.getDepartmentUserCountForMonth(
            department, 
            selectedMonth, 
            selectedYear
        );
        
        // Check if this is a custom count or default
        const isCustomCount = currentUserCount !== department.userCount;
        
        // Create a standalone card for user count adjustment
        const userCountCard = createElement('div', 'card border-light mb-0');
        
        // Card header
        const cardHeader = createElement('div', 'card-header bg-light d-flex justify-content-between align-items-center');
        
        // Header title
        const headerTitle = createElement('h6', 'mb-0');
        headerTitle.innerHTML = '<i class="bi bi-people-fill me-2"></i>Antall personer';
        
        // Status badge
        const statusBadge = createElement('span', isCustomCount ? 'badge bg-info rounded-pill' : 'badge bg-secondary rounded-pill');
        statusBadge.textContent = isCustomCount ? 'Tilpasset' : 'Standard';
        
        cardHeader.appendChild(headerTitle);
        cardHeader.appendChild(statusBadge);
        
        // Card body with controls
        const cardBody = createElement('div', 'card-body py-2');
        
        // User count control with number spinner
        const controlRow = createElement('div', 'd-flex align-items-center justify-content-between');
        
        // Number display with +/- buttons
        const counterGroup = createElement('div', 'd-flex align-items-center');
        
        // Decrease button
        const decBtn = createElement('button', 'btn btn-sm btn-outline-primary');
        decBtn.innerHTML = '<i class="bi bi-dash-lg"></i>';
        decBtn.type = 'button';
        decBtn.title = 'Reduser antall';
        decBtn.disabled = currentUserCount <= 1;
        decBtn.addEventListener('click', async () => {
            if (currentUserCount > 1) {
                const newCount = currentUserCount - 1;
                const updatedDept = await this.updateMonthlyUserCount(
                    department,
                    selectedMonth,
                    selectedYear,
                    newCount
                );
                
                // Re-render after update
                this.updateDepartmentSummary(updatedDept.id, selectedMonth, selectedYear);
                
                // Show feedback
                showNotification('Brukerantall oppdatert', 'success');
            }
        });
        
        // Current count display
        const countDisplay = createElement('div', 'px-3 py-1 border rounded mx-2 fw-bold text-center');
        countDisplay.style.minWidth = '40px';
        countDisplay.textContent = currentUserCount.toString();
        
        // Increase button
        const incBtn = createElement('button', 'btn btn-sm btn-outline-primary');
        incBtn.innerHTML = '<i class="bi bi-plus-lg"></i>';
        incBtn.type = 'button';
        incBtn.title = 'Øk antall';
        incBtn.addEventListener('click', async () => {
            const newCount = currentUserCount + 1;
            const updatedDept = await this.updateMonthlyUserCount(
                department,
                selectedMonth,
                selectedYear,
                newCount
            );
            
            // Re-render after update
            this.updateDepartmentSummary(updatedDept.id, selectedMonth, selectedYear);
            
            // Show feedback
            showNotification('Brukerantall oppdatert', 'success');
        });
        
        // Manual input option
        const manualInput = createElement('input', 'd-none') as HTMLInputElement;
        manualInput.type = 'number';
        manualInput.min = '1';
        manualInput.value = currentUserCount.toString();
        manualInput.addEventListener('change', async () => {
            const newCount = Math.max(1, parseInt(manualInput.value) || 1);
            manualInput.value = newCount.toString();
            
            const updatedDept = await this.updateMonthlyUserCount(
                department,
                selectedMonth,
                selectedYear,
                newCount
            );
            
            // Re-render after update
            this.updateDepartmentSummary(updatedDept.id, selectedMonth, selectedYear);
            
            // Show feedback
            showNotification('Brukerantall oppdatert', 'success');
        });
        
        // Make the count display clickable to show manual input
        countDisplay.addEventListener('click', () => {
            countDisplay.classList.add('d-none');
            manualInput.classList.remove('d-none');
            manualInput.focus();
        });
        
        // Hide manual input when focus is lost
        manualInput.addEventListener('blur', () => {
            countDisplay.classList.remove('d-none');
            manualInput.classList.add('d-none');
            countDisplay.textContent = manualInput.value;
        });
        
        // Assemble counter group
        counterGroup.appendChild(decBtn);
        counterGroup.appendChild(countDisplay);
        counterGroup.appendChild(manualInput);
        counterGroup.appendChild(incBtn);
        
        // Info text about user count
        const infoText = createElement('div', 'small text-muted');
        if (isCustomCount) {
            infoText.innerHTML = `
                Standardantall: <strong>${department.userCount}</strong>
                <button class="btn btn-sm btn-link p-0 ms-2" title="Tilbakestill til standard">
                    <i class="bi bi-arrow-counterclockwise"></i>
                </button>
            `;
            
            // Add reset functionality
            const resetBtn = infoText.querySelector('button');
            if (resetBtn) {
                resetBtn.addEventListener('click', async () => {
                    const updatedDept = await this.updateMonthlyUserCount(
                        department,
                        selectedMonth,
                        selectedYear,
                        department.userCount
                    );
                    
                    // Re-render after update
                    this.updateDepartmentSummary(updatedDept.id, selectedMonth, selectedYear);
                    
                    // Show feedback
                    showNotification('Brukerantall tilbakestilt til standard', 'success');
                });
            }
        } else {
            infoText.innerHTML = 'Bruker standardantall';
        }
        
        // Add everything to the control row
        controlRow.appendChild(counterGroup);
        controlRow.appendChild(infoText);
        
        // Add control row to card body
        cardBody.appendChild(controlRow);
        
        // Assemble the card
        userCountCard.appendChild(cardHeader);
        userCountCard.appendChild(cardBody);
        
        // Clear any existing content and add the card
        container.innerHTML = '';
        container.appendChild(userCountCard);
    }

    private createHelpSection(): void {
        const helpTitle = createElement('h2', 'mb-4', 'Hjelp og dokumentasjon');
        
        // About section
        const aboutApp = createElement('div', 'mb-4');
        aboutApp.innerHTML = `
            <h3>Om Basil App</h3>
            <p>
                Basil App er et verktøy for å administrere avdelingsbudsjetter og kvitteringer.
                Applikasjonen lar deg opprette avdelinger, registrere kvitteringer, og overvåke
                budsjettbruk på en oversiktlig måte.
            </p>
        `;
        
        // Help topics
        const helpTopics = createElement('div', 'accordion mb-4');
        helpTopics.id = 'helpAccordion';
        
        // Topic 1: Avdelinger
        const topic1 = createElement('div', 'accordion-item');
        topic1.innerHTML = `
            <h2 class="accordion-header" id="heading1">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapse1" aria-expanded="true" aria-controls="collapse1">
                    Administrere avdelinger
                </button>
            </h2>
            <div id="collapse1" class="accordion-collapse collapse show" aria-labelledby="heading1" data-bs-parent="#helpAccordion">
                <div class="accordion-body">
                    <h5>Opprette en avdeling</h5>
                    <p>For å opprette en ny avdeling, gå til Avdelinger-seksjonen og klikk "Legg til avdeling". Fyll ut skjemaet med følgende informasjon:</p>
                    <ul>
                        <li>Avdelingsnavn: Et beskrivende navn for avdelingen</li>
                        <li>Antall brukere: Hvor mange personer som tilhører avdelingen</li>
                        <li>Budsjett per bruker: Hvor stort budsjett hver person skal ha</li>
                    </ul>
                    
                    <h5>Redigere en avdeling</h5>
                    <p>Klikk på en avdeling i listen for å redigere navn, antall brukere eller budsjett per bruker.</p>
                    
                    <h5>Slette en avdeling</h5>
                    <p>I avdelingslisten, finn avdelingen du vil slette og klikk på "Slett"-knappen. 
                    Merk at dette også sletter alle kvitteringer tilknyttet denne avdelingen.</p>
                </div>
            </div>
        `;
        
        // Topic 2: Budget tracking
        const topic2 = createElement('div', 'accordion-item');
        topic2.innerHTML = `
            <h2 class="accordion-header" id="heading2">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapse2">
                    Budsjettsporing
                </button>
            </h2>
            <div id="collapse2" class="accordion-collapse collapse" aria-labelledby="heading2" data-bs-parent="#helpAccordion">
                <div class="accordion-body">
                    <h5>Budsjett per periode</h5>
                    <p>Budsjettet er delt inn i to typer perioder:</p>
                    <ul>
                        <li>4-dagers perioder (mandag-torsdag)</li>
                        <li>3-dagers perioder (fredag-søndag)</li>
                    </ul>
                    <p>Dette lar deg bedre spore utgifter i ulike deler av uken.</p>
                    
                    <h5>Månedlig brukerantall</h5>
                    <p>Du kan justere antall brukere for en spesifikk måned hvis det er endringer i personell. Dette gjøres på budsjettsiden
                    ved å velge avdeling, måned og år, og deretter justere antallet med kontrollene som vises.</p>
                    
                    <h5>Overvåke budsjettbruk</h5>
                    <p>På budsjettsiden kan du se detaljerte grafer og oversikter over brukte midler, inkludert:</p>
                    <ul>
                        <li>Total budsjettbruk vs. totalbudsjett</li>
                        <li>Budsjettbruk per periode</li>
                        <li>Ukentlig oversikt</li>
                    </ul>
                </div>
            </div>
        `;
        
        // Topic 3: Receipts
        const topic3 = createElement('div', 'accordion-item');
        topic3.innerHTML = `
            <h2 class="accordion-header" id="heading3">
                <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapse3">
                    Håndtere kvitteringer
                </button>
            </h2>
            <div id="collapse3" class="accordion-collapse collapse" aria-labelledby="heading3" data-bs-parent="#helpAccordion">
                <div class="accordion-body">
                    <h5>Registrere en kvittering</h5>
                    <p>Gå til Budsjett-seksjonen, velg en avdeling, og klikk "Legg til kvittering". Fyll ut skjemaet med:</p>
                    <ul>
                        <li>Dato: Datoen for kjøpet</li>
                        <li>Faktura-ID: Et unikt identifikasjonsnummer for kvitteringen</li>
                        <li>Beløp: Totalbeløp for kvitteringen</li>
                        <li>Beskrivelse: Valgfri beskrivelse av kjøpet</li>
                    </ul>
                    
                    <h5>Se kvitteringer</h5>
                    <p>På budsjettsiden vises alle kvitteringer for den valgte avdelingen og måneden. Du kan filtrere etter måned og år.</p>
                    
                    <h5>Redigere eller slette kvitteringer</h5>
                    <p>På kvitteringslisten kan du klikke "Rediger" for å endre en kvittering, eller "Slett" for å fjerne den.</p>
                </div>
            </div>
        `;
        
        helpTopics.appendChild(topic1);
        helpTopics.appendChild(topic2);
        helpTopics.appendChild(topic3);
        
        // Contact section
        const contactSection = createElement('div', 'mb-4');
        contactSection.innerHTML = `
            <h3>Kontakt</h3>
            <p>
                Hvis du har spørsmål eller trenger assistanse, vennligst kontakt systemadministrator.
            </p>
        `;
        
        // Add all to help container
        this.helpContainer.appendChild(helpTitle);
        this.helpContainer.appendChild(aboutApp);
        this.helpContainer.appendChild(helpTopics);
        this.helpContainer.appendChild(contactSection);
    }
    
    private async initBudgetView(receiptId?: string): Promise<void> {
        // Make sure departments are loaded
        if (this.state.departments.length === 0) {
            try {
                this.state.departments = await databaseService.getAllDepartments();
            } catch (error) {
                console.error('Error loading departments for budget view:', error);
            }
        }
        
        // Initialize budget view if it doesn't exist or if departments have changed
        if (!this.budgetView) {
            console.log('Creating new budget view');
            this.budgetContainer.innerHTML = ''; // Clear container first
            
            // Add a button with the class for programmatic access
            const hiddenButton = createElement('button', 'budget-view-add-receipt-btn d-none');
            hiddenButton.style.display = 'none';
            this.budgetContainer.appendChild(hiddenButton);
            
            this.budgetView = new BudgetView(
                this.budgetContainer, 
                this.state.departments,
                this.state.selectedMonth,
                this.state.selectedYear
            );
            
            // Find and modify the actual add receipt button to have the class we need
            setTimeout(() => {
                const addReceiptBtn = this.budgetContainer.querySelector('button');
                if (addReceiptBtn && addReceiptBtn.textContent?.includes('Legg til kvittering')) {
                    addReceiptBtn.classList.add('budget-view-add-receipt-btn');
                    hiddenButton.remove(); // Remove our hidden button
                }
            }, 200);
            
            // If a receiptId was passed, select that receipt after initialization
            if (receiptId) {
                // Wait a brief moment for the BudgetView to fully initialize
                setTimeout(async () => {
                    try {
                        // Fetch the receipt to get its department ID
                        const receipt = await databaseService.getReceipt(receiptId);
                        if (receipt && this.budgetView) {
                            // Call the BudgetView's internal handleReceiptSelect method directly
                            // We use bracket notation to access a private method
                            if (typeof this.budgetView['handleReceiptSelect'] === 'function') {
                                this.budgetView['handleReceiptSelect'](receiptId);
                            }
                        }
                    } catch (error) {
                        console.error('Error selecting receipt in budget view:', error);
                        showNotification('Kunne ikke finne kvitteringen', 'warning');
                    }
                }, 500);
            }
            
            // If there are departments, ensure one is selected
            if (this.state.departments.length > 0) {
                setTimeout(() => {
                    const selector = document.getElementById('department-selector') as HTMLSelectElement;
                    if (selector && (!selector.value || selector.value === '')) {
                        console.log('Forcing selection of first department in new view');
                        
                        // Get saved department ID from localStorage
                        const savedDepartmentId = localStorage.getItem('selectedDepartmentId');
                        
                        // If we have a saved department and it exists in our list, select it
                        if (savedDepartmentId && this.state.departments.some(d => d.id === savedDepartmentId)) {
                            selector.value = savedDepartmentId;
                        } else {
                            // Otherwise select the first department
                            selector.value = this.state.departments[0].id;
                        }
                        
                        // Trigger the change event
                        const event = new Event('change');
                        selector.dispatchEvent(event);
                    }
                }, 300); // Longer delay to ensure DOM is fully updated
            }
        } else {
            console.log('Updating budget view');
            // Update is now asynchronous but we don't need to await it here
            this.budgetView.update(this.state.departments);
            
            // If there are departments, ensure one is selected
            if (this.state.departments.length > 0) {
                setTimeout(() => {
                    const selector = document.getElementById('department-selector') as HTMLSelectElement;
                    if (selector && (!selector.value || selector.value === '')) {
                        console.log('Forcing selection of first department in updated view');
                        
                        // Get saved department ID from localStorage
                        const savedDepartmentId = localStorage.getItem('selectedDepartmentId');
                        
                        // If we have a saved department and it exists in our list, select it
                        if (savedDepartmentId && this.state.departments.some(d => d.id === savedDepartmentId)) {
                            selector.value = savedDepartmentId;
                        } else {
                            // Otherwise select the first department
                            selector.value = this.state.departments[0].id;
                        }
                        
                        // Trigger the change event
                        const event = new Event('change');
                        selector.dispatchEvent(event);
                    }
                }, 300); // Longer delay to ensure DOM is fully updated
            }
        }
    }
    
    private createNavigation() {
        const nav = createElement('ul', 'nav nav-tabs nav-fill');
        
        const departmentsTab = createElement('li', 'nav-item');
        const departmentsLink = createElement('a', 'nav-link active', 'Avdelinger');
        departmentsLink.setAttribute('href', '#');
        departmentsLink.addEventListener('click', (e) => {
            e.preventDefault();
            this.switchTab('departments');
        });
        departmentsTab.appendChild(departmentsLink);
        
        const budgetTab = createElement('li', 'nav-item');
        const budgetLink = createElement('a', 'nav-link', 'Budsjett');
        budgetLink.setAttribute('href', '#');
        budgetLink.addEventListener('click', (e) => {
            e.preventDefault();
            this.switchTab('budget');
        });
        budgetTab.appendChild(budgetLink);
        
        nav.appendChild(departmentsTab);
        nav.appendChild(budgetTab);
        
        this.navContainer.appendChild(nav);
    }
    
    private switchTab(tabName: 'departments' | 'budget') {
        if (this.state.activeTab === tabName) return;
        
        // Update state
        this.state.activeTab = tabName;
        
        // Save current tab to localStorage
        localStorage.setItem('activeTab', tabName);
        
        // Update nav links
        const navLinks = this.navContainer.querySelectorAll('.nav-link');
        navLinks.forEach(link => link.classList.remove('active'));
        
        if (tabName === 'departments') {
            navLinks[0].classList.add('active');
            this.departmentsContainer.style.display = '';
            this.budgetContainer.style.display = 'none';
        } else {
            navLinks[1].classList.add('active');
            this.departmentsContainer.style.display = 'none';
            this.budgetContainer.style.display = '';
            
            // Initialize budget view if first time or refresh it
            if (!this.budgetView) {
                console.log('Creating new budget view');
                this.budgetContainer.innerHTML = ''; // Clear container first
                this.budgetView = new BudgetView(
                    this.budgetContainer, 
                    this.state.departments,
                    this.state.selectedMonth,
                    this.state.selectedYear
                );
            } else {
                console.log('Updating budget view');
                // Update is now asynchronous but we don't need to await it here
                this.budgetView.update(this.state.departments);
                
                // If there are departments, ensure one is selected
                if (this.state.departments.length > 0) {
                    setTimeout(() => {
                        const selector = document.getElementById('department-selector') as HTMLSelectElement;
                        if (selector && !selector.value) {
                            console.log('Forcing selection of first department');
                            selector.value = this.state.departments[0].id;
                            
                            // Trigger the change event
                            const event = new Event('change');
                            selector.dispatchEvent(event);
                        }
                    }, 100); // Small delay to ensure DOM is updated
                }
            }
        }
    }

    private async init() {
        try {
            // Log environment configuration at startup
            console.log('Initializing application...');
            logEnvironment();
            
            // Initialize the database
            const dbInitialized = await databaseService.initDatabase();
            
            // Continue even if database fails, loadDepartments will handle this case
            if (!dbInitialized) {
                console.warn('Database connection failed during initialization');
                // Don't show alert here as it blocks UI - we'll handle this in loadDepartments
            }
            
            // Load departments (this will handle DB connection failures)
            await this.loadDepartments();
            
            // Initialize the department list component
            this.departmentList = new DepartmentList(
                this.deptListContainer,
                this.state.departments,
                this.handleDepartmentSelect.bind(this),
                this.handleDepartmentDelete.bind(this)
            );
            
            // This event is now handled directly in BudgetView
            // We keep this for backward compatibility but redirect to budget tab
            document.addEventListener('add-department', () => {
                this.showSection('budget'); // Switch to budget tab
            });
            
            // Initialize all views to ensure they have the latest data
            await this.updateSummaryView(); // Make sure budget view has departments
            
            // Also ensure BudgetView is initialized with departments
            if (this.state.activeTab === 'budget') {
                await this.initBudgetView();
            }
            
            // Restore active tab from localStorage if available
            const savedTab = localStorage.getItem('activeTab') as 'departments' | 'budget' | null;
            if (savedTab) {
                this.switchTab(savedTab);
            }
            
        } catch (error) {
            console.error('Failed to initialize app:', error);
            
            // Continue with empty state rather than showing error
            console.log('Continuing with empty state after initialization error');
            
            // Ensure departments list is initialized even after error
            if (!this.departmentList && this.deptListContainer) {
                this.departmentList = new DepartmentList(
                    this.deptListContainer,
                    [],  // Empty departments array
                    this.handleDepartmentSelect.bind(this),
                    this.handleDepartmentDelete.bind(this),
                );
                // Initialize the list without showing DB connection failure in the UI
                this.departmentList.update([], false); // false = don't show connection error in list
                
                // No notification needed
            }
        }
    }

    private async loadDepartments() {
        let dbConnectionFailed = false;
        
        try {
            try {
                // Try to load from API first
                const departments = await databaseService.getAllDepartments();
                
                // If we received departments from the API, use them
                if (departments && departments.length > 0) {
                    this.state.departments = departments;
                    console.log('Loaded departments from API:', departments.length);
                } else {
                    console.warn('No departments returned from API, checking local storage');
                    throw new Error('No departments from API');
                }
            } catch (apiError) {
                console.error('Error fetching from API, falling back to localStorage:', apiError);
                dbConnectionFailed = true;
                
                // Try to load from localStorage as fallback
                const storedDepts = localStorage.getItem('departments');
                if (storedDepts) {
                    try {
                        this.state.departments = JSON.parse(storedDepts);
                        console.log('Loaded departments from localStorage:', this.state.departments.length);
                    } catch (parseError) {
                        console.error('Error parsing localStorage departments:', parseError);
                        throw parseError; // Re-throw to create sample department
                    }
                } else {
                    throw new Error('No departments in localStorage');
                }
            }
            
            // Update department list if it exists
            // Don't pass dbConnectionFailed to avoid showing error in list
            if (this.departmentList) {
                this.departmentList.update(this.state.departments, false);
            }
            
            // Update budget view if it exists and is active
            if (this.budgetView && this.state.activeTab === 'budget') {
                this.budgetView.update(this.state.departments);
            }
            
            // No warning notification needed
        } catch (error) {
            console.error('Failed to load departments:', error);
            dbConnectionFailed = true;
            
            // Set departments to empty array
            this.state.departments = [];
            
            // Clear localStorage to ensure no test data persists
            try {
                localStorage.removeItem('departments');
            } catch (storageError) {
                console.error('Could not access localStorage:', storageError);
            }
            
            // Update UI components with empty list but don't show database connection error in the UI
            if (this.departmentList) {
                this.departmentList.update(this.state.departments, false); // false = don't show connection error in list
            }
            
            if (this.budgetView && this.state.activeTab === 'budget') {
                this.budgetView.update(this.state.departments);
            }
            
            // No notification needed
        }
    }

    private async handleDepartmentSelect(id: string) {
        try {
            const department = await databaseService.getDepartment(id);
            if (department) {
                this.state.selectedDepartment = department;
                this.showEditDepartmentForm(department.id);
            } else {
                showNotification(`Avdeling ikke funnet`, 'warning');
            }
        } catch (error) {
            console.error('Error selecting department:', error);
            showNotification('Feil ved valg av avdeling', 'danger');
        }
    }

    private async handleDepartmentDelete(id: string) {
        try {
            // Check if the department has receipts
            const receipts = await databaseService.getReceiptsByDepartment(id);
            
            if (receipts.length > 0) {
                const confirmed = confirm(`Denne avdelingen har ${receipts.length} kvitteringer. Sletting vil også fjerne alle tilknyttede kvitteringer. Vil du fortsette?`);
                if (!confirmed) return;
            }
            
            // Delete the department
            await databaseService.deleteDepartment(id);
            showNotification('Avdeling slettet', 'success');
            
            // If the deleted department is currently selected, clear the selection
            if (this.state.selectedDepartment?.id === id) {
                this.state.selectedDepartment = null;
                this.deptFormContainer.innerHTML = '';
            }
            
            // Clear and hide the summary form container if it exists
            const summaryFormContainer = document.getElementById('summary-department-form-container');
            if (summaryFormContainer) {
                summaryFormContainer.innerHTML = '';
                summaryFormContainer.style.display = 'none';
            }
            
            // Reload the departments
            await this.loadDepartments();
            
            // Refresh the summary view if we're in that tab
            if (this.summaryContainer.classList.contains('active')) {
                await this.updateSummaryView();
                
                // Update the department overview specifically
                this.renderDepartmentOverview();
                
                // Disable the action buttons in the summary view
                const editBtn = document.getElementById('edit-department-btn');
                const deleteBtn = document.getElementById('delete-department-btn');
                
                if (editBtn) editBtn.classList.add('disabled');
                if (deleteBtn) deleteBtn.classList.add('disabled');
            }
        } catch (error) {
            console.error('Error deleting department:', error);
            showNotification('Feil ved sletting av avdeling', 'danger');
        }
    }

    private async handleDepartmentSave(department: Department) {
        try {
            const isNewDepartment = !this.state.departments.find(d => d.id === department.id);
            
            if (isNewDepartment) {
                await databaseService.addDepartment(department);
                showNotification(`Avdeling "${department.name}" lagt til`, 'success');
            } else {
                await databaseService.updateDepartment(department);
                showNotification(`Avdeling "${department.name}" oppdatert`, 'success');
            }
            
            // Clear the form containers
            this.state.selectedDepartment = null;
            this.deptFormContainer.innerHTML = '';
            
            // Clear and hide the summary form container if it exists
            const summaryFormContainer = document.getElementById('summary-department-form-container');
            if (summaryFormContainer) {
                summaryFormContainer.innerHTML = '';
                summaryFormContainer.style.display = 'none';
            }
            
            // Reload the departments
            await this.loadDepartments();
            
            // Refresh the summary view with updated departments
            await this.updateSummaryView();
            
            // Update the department overview specifically
            this.renderDepartmentOverview();
            
            // Hvis vi er i budsjetttabben, velg den lagrede avdelingen
            if (this.summaryContainer.classList.contains('active')) {
                // Oppdater direkte uten å gå via dropdown siden vi fjernet den
                this.state.selectedDepartment = department;
                this.updateDepartmentSummary(department.id);
            }
        } catch (error) {
            console.error('Error saving department:', error);
            showNotification('Feil ved lagring av avdeling', 'danger');
        }
    }

    private handleFormCancel() {
        this.state.selectedDepartment = null;
        this.deptFormContainer.innerHTML = '';
    }

    private showAddDepartmentForm() {
        // Show the department section if we're not already there
        this.showSection('summary');
        
        // Clear selection
        this.state.selectedDepartment = null;
        
        // Initialize empty department object with default values
        const newDepartment: Department = {
            id: '',
            name: '',
            budget: 0,
            userCount: 1,
            budgetPerUser: 4000  // Default 4,000 NOK per user
        };
        
        // Get the form container in the summary section
        const formContainer = document.getElementById('summary-department-form-container');
        if (!formContainer) {
            console.error('Department form container not found');
            return;
        }
        
        // Clear and show the form container
        formContainer.innerHTML = '';
        formContainer.style.display = 'block';
        
        // Create form
        this.departmentForm = new DepartmentForm(
            formContainer,
            newDepartment,
            this.handleDepartmentSave.bind(this),
            () => {
                // Custom cancel handler for the summary view
                formContainer.innerHTML = '';
                formContainer.style.display = 'none';
            }
        );
        
        // Scroll to the form
        formContainer.scrollIntoView({ behavior: 'smooth' });
    }

    private showEditDepartmentForm(departmentId: string) {
        // Show the department section if we're not already there
        this.showSection('summary');
        
        // Find the department
        const department = this.state.departments.find(d => d.id === departmentId);
        if (!department) {
            console.error(`Department not found with ID: ${departmentId}`);
            return;
        }
        
        // Save the selected department
        this.state.selectedDepartment = department;
        
        // Get the form container in the summary section
        const formContainer = document.getElementById('summary-department-form-container');
        if (!formContainer) {
            console.error('Department form container not found');
            return;
        }
        
        // Clear and show the form container
        formContainer.innerHTML = '';
        formContainer.style.display = 'block';
        
        // Create form
        this.departmentForm = new DepartmentForm(
            formContainer,
            department,
            this.handleDepartmentSave.bind(this),
            () => {
                // Custom cancel handler for the summary view
                formContainer.innerHTML = '';
                formContainer.style.display = 'none';
            }
        );
        
        // Scroll to the form
        formContainer.scrollIntoView({ behavior: 'smooth' });
    }
}

// Add some CSS for the app
const stylesheet = document.createElement('style');
stylesheet.textContent = `
    .toast {
        z-index: 1050;
    }
    
    .nav-tabs {
        margin-bottom: 25px;
    }
    
    .nav-tabs .nav-link {
        font-weight: 500;
    }
    
    .nav-tabs .nav-link.active {
        font-weight: bold;
    }
`;
document.head.appendChild(stylesheet);

// Initialize app when DOM is ready
document.addEventListener('DOMContentLoaded', () => {
    new App();
});