/**
 * Creates and returns an HTML element with classes
 */
export function createElement<K extends keyof HTMLElementTagNameMap>(
    tag: K, 
    className: string = '', 
    textContent: string = ''
): HTMLElementTagNameMap[K] {
    const element = document.createElement(tag);
    if (className) element.className = className;
    if (textContent) element.textContent = textContent;
    return element;
}

/**
 * Formats a number as Norwegian Krone (NOK)
 */
export function formatCurrency(amount: number): string {
    return amount.toLocaleString('nb-NO', {
        style: 'currency',
        currency: 'NOK',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    });
}

/**
 * Shows a toast notification
 */
export function showNotification(
    message: string, 
    type: 'success' | 'danger' | 'warning' | 'info' = 'info', 
    duration: number = 3000
): void {
    const toast = createElement('div', `toast show bg-${type} text-white position-fixed bottom-0 end-0 m-3`);
    toast.setAttribute('role', 'alert');
    toast.setAttribute('aria-live', 'assertive');
    toast.setAttribute('aria-atomic', 'true');
    toast.style.zIndex = '9999';
    
    const toastBody = createElement('div', 'toast-body d-flex align-items-center');
    toastBody.textContent = message;
    
    const closeButton = createElement('button', 'btn-close btn-close-white ms-auto');
    closeButton.setAttribute('type', 'button');
    closeButton.setAttribute('aria-label', 'Close');
    closeButton.addEventListener('click', () => {
        document.body.removeChild(toast);
    });
    
    toastBody.appendChild(closeButton);
    toast.appendChild(toastBody);
    document.body.appendChild(toast);
    
    setTimeout(() => {
        if (document.body.contains(toast)) {
            document.body.removeChild(toast);
        }
    }, duration);
}

/**
 * Verifies the admin password
 * Password is configured in config.ts and can be set via environment variable ADMIN_PASSWORD
 */
export function verifyAdminPassword(password: string): boolean {
    // In a real application, this would use a secure hashing mechanism
    // For demo purposes, we're using a simple password check with config
    const { appSettings } = require('./config');
    const correctPassword = appSettings.adminPassword;
    return password === correctPassword;
}

/**
 * Shows a password prompt modal and returns promise that resolves with true if correct password
 */
export function showPasswordPrompt(): Promise<boolean> {
    return new Promise((resolve) => {
        // Create modal backdrop with high z-index
        const backdrop = createElement('div', 'modal-backdrop fade show');
        backdrop.style.zIndex = '2000'; // Ensure it's higher than other elements
        document.body.appendChild(backdrop);
        
        // Create modal container with higher z-index
        const modalContainer = createElement('div', 'modal fade show d-block');
        modalContainer.style.backgroundColor = 'rgba(0,0,0,0.5)';
        modalContainer.style.zIndex = '2001'; // Higher than backdrop
        modalContainer.style.touchAction = 'pan-x pan-y'; // Improve touch support
        modalContainer.tabIndex = -1;
        
        // Create modal dialog that's optimized for mobile
        const modalDialog = createElement('div', 'modal-dialog modal-dialog-centered modal-sm');
        modalDialog.style.margin = 'auto'; // Ensure it's centered
        modalDialog.style.maxWidth = '95%'; // Allow it to be responsive
        modalDialog.style.width = '320px'; // Optimal width for mobile
        
        // Create modal content
        const modalContent = createElement('div', 'modal-content');
        
        // Create modal header
        const modalHeader = createElement('div', 'modal-header');
        const modalTitle = createElement('h5', 'modal-title', 'Admin-tilgang');
        const closeButton = createElement('button', 'btn-close');
        closeButton.type = 'button';
        closeButton.setAttribute('aria-label', 'Close');
        closeButton.addEventListener('click', () => {
            closeModal(false);
        });
        
        modalHeader.appendChild(modalTitle);
        modalHeader.appendChild(closeButton);
        
        // Create modal body
        const modalBody = createElement('div', 'modal-body');
        const form = createElement('form');
        
        const formGroup = createElement('div', 'mb-3');
        const passwordLabel = createElement('label', 'form-label', 'Passord:');
        passwordLabel.setAttribute('for', 'admin-password');
        
        const passwordInput = createElement('input', 'form-control') as HTMLInputElement;
        passwordInput.type = 'password';
        passwordInput.id = 'admin-password';
        passwordInput.placeholder = 'Skriv inn admin-passord';
        passwordInput.required = true;
        passwordInput.autocomplete = 'current-password';
        
        formGroup.appendChild(passwordLabel);
        formGroup.appendChild(passwordInput);
        form.appendChild(formGroup);
        
        modalBody.appendChild(form);
        
        // Create modal footer
        const modalFooter = createElement('div', 'modal-footer');
        const cancelButton = createElement('button', 'btn btn-secondary btn-lg', 'Avbryt');
        cancelButton.type = 'button';
        cancelButton.style.minWidth = '100px'; // Make button larger for touch
        cancelButton.style.padding = '8px 16px'; // Larger padding for easier touch
        cancelButton.addEventListener('click', () => {
            closeModal(false);
        });
        
        const submitButton = createElement('button', 'btn btn-primary btn-lg', 'Logg inn');
        submitButton.type = 'button'; // Changed from 'submit' to 'button' for better mobile compatibility
        submitButton.style.minWidth = '100px'; // Make button larger for touch
        submitButton.style.padding = '8px 16px'; // Larger padding for easier touch
        
        // Add click handler to submit button that manually submits the form
        submitButton.addEventListener('click', () => {
            // Create and dispatch a submit event on the form
            const submitEvent = new Event('submit', {
                bubbles: true,
                cancelable: true
            });
            form.dispatchEvent(submitEvent);
        });
        
        modalFooter.appendChild(cancelButton);
        modalFooter.appendChild(submitButton);
        
        // Assemble modal
        modalContent.appendChild(modalHeader);
        modalContent.appendChild(modalBody);
        modalContent.appendChild(modalFooter);
        modalDialog.appendChild(modalContent);
        modalContainer.appendChild(modalDialog);
        
        // Add modal to body
        document.body.appendChild(modalContainer);
        
        // Focus password input
        setTimeout(() => {
            passwordInput.focus();
        }, 100);
        
        // Add keyboard event handling for Enter key (mobile keyboards)
        passwordInput.addEventListener('keyup', (e) => {
            if (e.key === 'Enter') {
                // Create and dispatch a submit event on the form
                const submitEvent = new Event('submit', {
                    bubbles: true, 
                    cancelable: true
                });
                form.dispatchEvent(submitEvent);
            }
        });
        
        // Handle form submission
        form.addEventListener('submit', (e) => {
            e.preventDefault();
            const password = passwordInput.value;
            const isValid = verifyAdminPassword(password);
            
            if (isValid) {
                closeModal(true);
            } else {
                // Show error
                if (document.getElementById('password-error')) {
                    return; // Don't add multiple error messages
                }
                
                const errorMessage = createElement('div', 'alert alert-danger mt-2', 'Feil passord. Prøv igjen.');
                errorMessage.id = 'password-error';
                formGroup.appendChild(errorMessage);
                
                // Clear password input
                passwordInput.value = '';
                passwordInput.focus();
            }
        });
        
        // Close modal and resolve promise
        function closeModal(success: boolean) {
            document.body.removeChild(modalContainer);
            document.body.removeChild(backdrop);
            resolve(success);
        }
    });
}